import axios from "axios";

export class InchargeApis {
  static authenticateUser = async (params) => {
    try {
      const response = await axios.post(
        "https://staging.incharge.indrones.com/api/signin",
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const status = response.status;
      const token = {
        access: response.data.token_data.access,
        refresh: response.data.token_data.refresh,
      };
      const userDetails = response.data.user_detail;
      return { token, userDetails, status };
    } catch (error) {
      const status = error.response.status;

      return { status };
    }
  };
}
