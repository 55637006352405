import { useEffect } from "react";
import {
  Color,
  CallbackProperty,
  HeightReference,
  PolygonHierarchy,
  defined,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Cartographic,
  Matrix4,
  ColorMaterialProperty,
  Math as MathOfCesium,
} from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";
import toast from "react-hot-toast";

export function usePolygonAnnotationTool(
  viewerRef,
  setShapeCords,
  setShapeData,
  setOpenPolygonAnnotationModal,
  setShapeCordLatLan
) {
  const { polygonAnnotation } = useSelector((state) => state.gisTools);
  const dispatch = useDispatch();

  useEffect(() => {
    const viewer = viewerRef.current;
    const handler = new ScreenSpaceEventHandler(viewer.canvas);
    let drawingMode = "polygon";
    let activeShapePoints = [];
    let activeShape;
    let floatingPoint;
    let lineDistance = 0;
    let handlerAdded = false;
    let point;
    function createPoint(worldPosition) {
      point = viewer.entities.add({
        position: worldPosition,
        point: {
          color: Color.GOLD,
          pixelSize: 10,
          heightReference: HeightReference.RELATIVE_TO_GROUND,
        },
      });
      return point;
    }
    let edges;
    function drawShape(positionData) {
      let shape;

      if (drawingMode === "polygon") {
        shape = viewer.entities.add({
          polygon: {
            hierarchy: positionData,
            material: new ColorMaterialProperty(Color.KHAKI.withAlpha(0.4)),
          },
        });
        // Adding edges to polygon
        if (positionData.length > 0) {
          edges = viewer.entities.add({
            polyline: {
              positions: [...positionData, positionData[0]],
              clampToGround: true,
              width: 3,
              material: Color.KHAKI,
            },
          });
        }
      }

      setShapeCords(positionData);

      const shapeLatLon =
        positionData?.length > 0
          ? positionData?.map((item) => {
              // console.log(item.x, "item.x");
              let cartographic = Cartographic.fromCartesian(item);
              let lat = MathOfCesium.toDegrees(cartographic.latitude);
              let lon = MathOfCesium.toDegrees(cartographic.longitude);

              return { lat: lat, lon: lon };
            })
          : null;

      setShapeCordLatLan(shapeLatLon);

      return { shape, edges };
    }
    function calculateShapes(lats, lons, vertices, type) {
      let postObj = {
        lats: lats,
        lons: lons,
        vertices: vertices,
        type: type,
      };

      let postData = JSON.stringify(postObj);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        "https://8yt7pbjfn4.execute-api.ap-south-1.amazonaws.com/PlanarMeasurement"
      );
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      const body = postData;
      xhr.onload = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          if (drawingMode === "polygon") {
            setShapeData({
              shape_type: JSON.parse(xhr.responseText).body.type,
              area: JSON.parse(xhr.responseText).body.area,
              perimeter: JSON.parse(xhr.responseText).body.perimeter,
              centroid: JSON.stringify(
                JSON.parse(xhr.responseText).body.centroid
              ),
            });
          }
        } else {
        }
      };
      xhr.send(body);
    }

    function showTooltip(e, lat, lon, distance) {
      if (drawingMode === "polygon") {
        const x = e.endPosition.x;
        const y = e.endPosition.y;

        const tooltip = document.getElementById("tooltip");
        tooltip.innerHTML = `<strong>Latitude:</strong> ${lat}<br><strong>Longitude:</strong> ${lon}<br><strong>Distance:</strong> ${distance} meters`;
        tooltip.style.left = x + 10 + "px";
        tooltip.style.top = y - 10 + "px";
      }
    }

    function calcDist(lat1, lon1, lat2, lon2) {
      var R = 6371; // km
      var dLat = toRad(lat2 - lat1);
      var dLon = toRad(lon2 - lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    }

    function toRad(Value) {
      return (Value * Math.PI) / 180;
    }

    function terminateShape() {
      activeShapePoints.pop();
      drawShape(activeShapePoints);
      viewer.entities.remove(floatingPoint);
      viewer.entities.remove(activeShape);
      floatingPoint = undefined;
      activeShape = undefined;
      activeShapePoints = [];
      dispatch(gisToolActions.turnOffTools());
      dispatch(gisToolActions.setselectedTool(null));
    }

    if (polygonAnnotation && !handlerAdded) {
      handler.setInputAction(function (event) {
        const ray = viewer.camera.getPickRay(event.position);
        const earthPosition = viewer.scene.globe.pick(ray, viewer.scene);

        if (defined(earthPosition)) {
          if (activeShapePoints.length === 0) {
            floatingPoint = createPoint(earthPosition);
            activeShapePoints.push(earthPosition);
            const dynamicPositions = new CallbackProperty(function () {
              if (drawingMode === "polygon") {
                return new PolygonHierarchy(activeShapePoints);
              }
              return activeShapePoints;
            }, false);
            activeShape = drawShape(dynamicPositions);
          }
          activeShapePoints.push(earthPosition);
          createPoint(earthPosition);

          document.getElementById("tooltip").style.display = "block";
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      handler.setInputAction(function (event) {
        if (defined(floatingPoint)) {
          const ray = viewer.camera.getPickRay(event.endPosition);
          const newPosition = viewer.scene.globe.pick(ray, viewer.scene);
          if (defined(newPosition)) {
            floatingPoint.position.setValue(newPosition);
            activeShapePoints.pop();
            activeShapePoints.push(newPosition);

            if (drawingMode === "polygon" && activeShapePoints.length > 2) {
              let cartographic1 = Cartographic.fromCartesian(
                activeShapePoints[activeShapePoints.length - 1]
              );
              let cartographic2 = Cartographic.fromCartesian(
                activeShapePoints[activeShapePoints.length - 2]
              );

              let lat1 = MathOfCesium.toDegrees(cartographic1.latitude);
              let lon1 = MathOfCesium.toDegrees(cartographic1.longitude);
              let lat2 = MathOfCesium.toDegrees(cartographic2.latitude);
              let lon2 = MathOfCesium.toDegrees(cartographic2.longitude);

              lineDistance = (
                calcDist(lat1, lon1, lat2, lon2) * 1000
              ).toPrecision(6);

              showTooltip(
                event,
                lat2.toPrecision(6),
                lon2.toPrecision(6),
                lineDistance
              );
            }
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);

      handler.setInputAction(function (event) {
        let i;
        let lats = [];
        let lons = [];
        let vertices = [];

        for (i = 0; i < activeShapePoints.length; i++) {
          let startPoint = activeShapePoints[i];
          let endPoint = activeShapePoints[i + 1];

          let cartographic = Cartographic.fromCartesian(activeShapePoints[i]);

          let lat = MathOfCesium.toDegrees(cartographic.latitude);
          let lon = MathOfCesium.toDegrees(cartographic.longitude);

          lats.push(lat);
          lons.push(lon);

          let vertex = [activeShapePoints[i]["x"], activeShapePoints[i]["y"]];
          vertices.push(vertex);
        }

        if (activeShapePoints.length <= 3) {
          toast.error("For polygon annotation you must have 3 points! ");
          terminateShape();
          viewer.entities.remove(point);
          viewer.entities.remove(edges);
        } else {
          calculateShapes(lats, lons, vertices, drawingMode);
          setOpenPolygonAnnotationModal();
          terminateShape();
        }

        document.getElementById("tooltip").style.display = "none";
      }, ScreenSpaceEventType.RIGHT_CLICK);

      handlerAdded = true;
    } else if (polygonAnnotation === false && handlerAdded) {
      handler.destroy();
      handlerAdded = false;
    }

    viewer.camera.lookAtTransform(Matrix4.IDENTITY);

    return () => {
      if (handler && !handler.isDestroyed()) {
        handler.destroy();
      }
    };
  }, [polygonAnnotation]);
}
