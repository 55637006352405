import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";
import "../../Assets/styles/GISStyle.css";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Popper, TextField, Tooltip } from "@mui/material";
import { dataJson as datajson } from "../../Data/Grp_table_data";
import GMPV1 from "../../Assets/images/GIS/GMPV1.png";
import GMPV2 from "../../Assets/images/GIS/GMPV2.png";
import GMPV3 from "../../Assets/images/GIS/GMPV3.png";
import DownloadFileIco from "../../Assets/images/GIS/downloadFile.png";
import SendEmailIco from "../../Assets/images/GIS/sendEmail.png";
import overflowTrash from "../../Assets/images/GIS/overflown_trashbin.jpg";
import floatingGarbage from "../../Assets/images/GIS/floating-garbage.jpeg";
import pdfData from "../../Data/sample_report.pdf";
import DocViewerRenderers from "@cyntler/react-doc-viewer";
import DocViewer from "@cyntler/react-doc-viewer";

const ReportModal = ({ annotation, onClose, dataJson, elementWidth, type }) => {
  var reportvalue = parseInt(elementWidth) + 92;

  const styles = {
    calfordrgg: {
      width: `calc(100% - ${reportvalue}px)`, // Using template literals to insert the dynamic value
      marginLeft: `${reportvalue}px`,
    },
  };
  const dates = dataJson.Data.map((item) => item.Date);
  const [tableView, setTableView] = React.useState(true);
  const [grpView, setGrpView] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const data = dataJson.Data || [];
  useEffect(() => {
    const generateSeries = () => {
      if (data.length === 0) return;
      const fieldNames = Object.keys(data[0]);
      const fieldNamesWithoutDate = fieldNames.filter(
        (field) => field !== "Date"
      );
      const generatedSeries = fieldNamesWithoutDate.map((field) => ({
        name: field,
        data: data.map((entry) => entry[field]),
      }));

      return generatedSeries;
    };

    const generatedSeries = generateSeries();
    setDataSeries({
      series: [...generatedSeries],
      options: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: dates,
        },
        tooltip: {
          x: {
            format: "dd/MM/yy",
          },
        },
      },
    });
  }, [data]);

  const [dataSeries, setDataSeries] = React.useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: dates,
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
    },
  });

  const handleTableView = () => {
    setTableView(true);
    setGrpView(false);
  };
  const handleGrpView = () => {
    setGrpView(true);
    setTableView(false);
  };

  const handlePdfDownload = async () => {
    try {
      const response = await axios.post(
        "https://delivery.inspect.indrones.com/getPdf/generatePdf",
        datajson,
        {
          responseType: "blob", // Tell Axios to expect a binary response
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a download link and trigger a click to initiate download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "output.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendEmail = async (event) => {
    event.preventDefault();
    const recipients = event.target.to.value;
    const sender = event.target.from.value;
    const subject = event.target.subject.value;

    const dataTosend = {
      sender,
      subject,
      recipients: [recipients],
      datajson,
    };

    try {
      const response = await axios.post(
        "https://delivery.inspect.indrones.com/email/sendEmail",
        dataTosend
      );
      // console.log(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {type === "Annotation" ? (
        <div
          style={styles.calfordrgg}
          className="fixed inset-0 flex justify-center items-center  bg-opacity-60 z-50"
        >
          <Rnd
            default={{
              x: 100,
              y: 30,
              width: 700,
              height: 800,
            }}
            minWidth={700}
            minHeight={500}
            bounds="parent"
            className="resizable-box"
          >
            <div
              className="bg-white shadow-lg modal-content rounded-[12px] h-[100%] z-50"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="header  w-full rounded-tl-[12px] rounded-tr-[12px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
                <span className="grow flex items-center justify-center">
                  Garbage Management Annotation Report
                </span>
                <button
                  className="absolute top-2 right-2 p-2 rounded-full  hover:bg-[#0000000a] text-[#b5b4b2]  focus:outline-none"
                  onClick={onClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <Tooltip
                title="Send Email"
                arrow
                placement="right"
                className="absolute bottom-1 right-0"
              >
                <button
                  className="bg-blue-300 hover:bg-blue-400 text-white font-semibold py-2 px-2 rounded-full flex items-center justify-center mr-2"
                  onClick={handleClick}
                >
                  <img className="h-6" src={SendEmailIco} alt="" srcSet="" />
                </button>
              </Tooltip>

              <Tooltip
                title="Downlaod File"
                arrow
                placement="left"
                className="absolute bottom-1 right-14"
              >
                <button
                  className="bg-green-300 hover:bg-green-400 text-white font-semibold py-2 px-2 rounded-full flex items-center justify-center ml-2"
                  onClick={handlePdfDownload}
                >
                  <img className="h-6" src={DownloadFileIco} alt="" srcSet="" />
                </button>
              </Tooltip>
              <div className="bg-gray-100 p-4 h-[calc(100%-90px)] overflow-y-auto custom-scrollbar">
                <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow-lg">
                  <h1 className="text-3xl font-bold text-center">
                    Garbage Management Annotation Report
                  </h1>
                  <h2 className="text-xl text-center">
                    Kolkata Municipal Corporation
                  </h2>
                  <p className="text-center">
                    Reporting Period: January 1, 2023
                  </p>
                  <h2 className="text-2xl font-bold mb-4">Table of Contents</h2>
                  <ol className="list-decimal list-inside pl-4">
                    <li>
                      <a href="#executive-summary" className="text-blue-500">
                        Executive Summary
                      </a>
                    </li>
                    <li>
                      <a href="#annotated-spots" className="text-blue-500">
                        Annotated Spots
                      </a>
                    </li>
                    <li>
                      <a href="#recommended-actions" className="text-blue-500">
                        Recommended Actions
                      </a>
                    </li>
                  </ol>

                  <section id="executive-summary" className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">
                      1. Executive Summary
                    </h2>
                    <p>
                      This report provides a comprehensive overview of annotated
                      spots within the garbage management area of the GreenCity
                      Municipal Corporation, specifically for the reporting
                      period of January 1, 2023. The annotations were made by
                      concerned citizens and municipal workers using a digital
                      mapping tool. The report includes details of annotated
                      spots, their descriptions, and recommended actions.
                    </p>
                  </section>

                  <section id="annotated-spots" className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">
                      2. Annotated Spots
                    </h2>

                    <div className="border border-gray-300 p-4 rounded mt-4">
                      <h3 className="text-lg font-semibold">
                        2.1. Floating Garbage in Canal
                      </h3>
                      <p>
                        <strong>Location:</strong> 22.651934, 88.378559
                      </p>
                      <p>
                        <strong>Date and Time of Annotation:</strong> January 1,
                        2023, 10:30 AM
                      </p>
                      <p>
                        <strong>Description:</strong> A floating mass of garbage
                        has been annotated in the canal near [specific
                        location]. The garbage appears to consist of plastic
                        bags, bottles, and other waste materials. It is
                        obstructing the flow of water and could pose an
                        environmental hazard.
                      </p>
                      <div className="mt-4 w-full">
                        <img
                          className="mx-auto w-[40%]"
                          src={floatingGarbage}
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </div>

                    <div className="border border-gray-300 p-4 rounded mt-4">
                      <h3 className="text-lg font-semibold">
                        2.2. Overflowing Trash Bin at Park
                      </h3>
                      <p>
                        <strong>Location:</strong> 22.651934, 88.378559
                      </p>
                      <p>
                        <strong>Date and Time of Annotation:</strong> January 1,
                        2023, 12:15 PM
                      </p>
                      <p>
                        <strong>Description:</strong> An overflowing trash bin
                        has been annotated at the park located at [specific
                        location]. The bin is filled with household waste, and
                        trash is scattered around the vicinity. This creates an
                        unsightly and unsanitary condition in a public area.
                      </p>
                      <div className="mt-4 w-full">
                        <img
                          className="mx-auto w-[40%]"
                          src={overflowTrash}
                          alt=""
                          srcSet=""
                        />
                      </div>
                    </div>
                  </section>

                  <section id="recommended-actions" className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">
                      3. Recommended Actions
                    </h2>

                    <div className="border border-gray-300 p-4 rounded mt-4">
                      <h3 className="text-lg font-semibold">
                        3.1. Floating Garbage in Canal
                      </h3>
                      <ul className="list-disc list-inside pl-4">
                        <li>
                          Immediate Cleanup: Schedule an immediate cleanup
                          operation to remove the floating garbage in the canal.
                        </li>
                        <li>
                          Monitor Water Quality: Conduct water quality testing
                          to assess any potential environmental impact.
                        </li>
                        <li>
                          Community Awareness: Launch a public awareness
                          campaign to discourage littering in water bodies.
                        </li>
                      </ul>
                    </div>

                    <div className="border border-gray-300 p-4 rounded mt-4">
                      <h3 className="text-lg font-semibold">
                        3.2. Overflowing Trash Bin at Park
                      </h3>
                      <ul className="list-disc list-inside pl-4">
                        <li>
                          Empty Trash Bin: Dispatch a waste collection team to
                          empty the overflowing trash bin at the park.
                        </li>
                        <li>
                          Increased Collection Frequency: Consider increasing
                          the frequency of trash collection in public areas.
                        </li>
                        <li>
                          Anti-Littering Signage: Install anti-littering signs
                          in the park to encourage responsible waste disposal.
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </Rnd>
        </div>
      ) : type === "Progress" ? (
        <div
          style={styles.calfordrgg}
          className="fixed inset-0 flex justify-center items-center  bg-opacity-60 z-50"
        >
          <Rnd
            default={{
              x: 100,
              y: 30,
              width: 700,
              height: 800,
            }}
            minWidth={500}
            minHeight={500}
            bounds="parent"
            className="resizable-box"
          >
            <div
              className="bg-white shadow-lg modal-content rounded-[12px] h-[100%] z-50"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="header  w-full rounded-tl-[12px] rounded-tr-[12px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
                <span className="grow flex items-center justify-center">
                  Garbage Management Progress Report
                </span>
                <button
                  className="absolute top-2 right-2 p-2 rounded-full  hover:bg-[#0000000a] text-[#b5b4b2]  focus:outline-none"
                  onClick={onClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <Tooltip
                title="Send Email"
                arrow
                placement="right"
                className="absolute bottom-1 right-0"
              >
                <button
                  className="bg-blue-300 hover:bg-blue-400 text-white font-semibold py-2 px-2 rounded-full flex items-center justify-center mr-2"
                  onClick={handleClick}
                >
                  <img className="h-6" src={SendEmailIco} alt="" srcSet="" />
                </button>
              </Tooltip>

              <Tooltip
                title="Downlaod File"
                arrow
                placement="left"
                className="absolute bottom-1 right-14"
              >
                <button
                  className="bg-green-300 hover:bg-green-400 text-white font-semibold py-2 px-2 rounded-full flex items-center justify-center ml-2"
                  onClick={handlePdfDownload}
                >
                  <img className="h-6" src={DownloadFileIco} alt="" srcSet="" />
                </button>
              </Tooltip>
              <div className="bg-gray-100 p-4  h-[calc(100%-90px)] overflow-y-auto custom-scrollbar">
                <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow-lg">
                  <h1 className="text-3xl font-bold text-center mb-2">
                    Garbage Management Progress Report
                  </h1>
                  <h2 className="text-xl text-center">
                    Kolkata Municipal Corporation
                  </h2>
                  <p className="text-center">
                    Reporting Period: January 1, 2023
                  </p>
                  <h2 className="text-2xl font-bold mb-4">Table of Contents</h2>
                  <ol className="list-decimal list-inside pl-4">
                    <li>
                      <a href="#executive-summary" className="text-blue-500">
                        Executive Summary
                      </a>
                    </li>
                    <li>
                      <a href="#contractInformation" className="text-blue-500">
                        Contract Information
                      </a>
                    </li>
                    <li>
                      <a href="#workCompletionStatus" className="text-blue-500">
                        Work Completion Status
                      </a>
                    </li>
                    <li>
                      <a href="#keyObs" className="text-blue-500">
                        Key Observations
                      </a>
                    </li>
                  </ol>{" "}
                  <div>
                    <h2
                      id="executive-summary"
                      className="text-xl font-bold mt-4 mb-2"
                    >
                      1. Executive Summary
                    </h2>
                    <p>
                      This report provides an in-depth overview of garbage
                      management progress for the Kolkata Municipal Corporation,
                      specifically for the reporting period of January 1, 2023.
                      The operations have been conducted by GreenWaste Solutions
                      Pvt. Ltd. The report includes contract details, work
                      completion status, and key observations.
                    </p>

                    <h2
                      id="contractInformation"
                      className="text-xl font-bold mt-4 mb-2"
                    >
                      2. Contract Information
                    </h2>
                    <table className="w-full border-collapse mt-4">
                      <thead>
                        <tr>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Contractor Name
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Contract Start Date
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Contract End Date
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Contract Value
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Contract Scope
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            GreenWaste Solutions Pvt. Ltd.
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            June 30, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            â‚¹5,000,000
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Collection and disposal of municipal waste in
                            Kolkata.
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <h2
                      id="workCompletionStatus"
                      className="text-xl font-bold mt-4 mb-2"
                    >
                      3. Work Completion Status
                    </h2>
                    <table className="w-full border-collapse mt-4">
                      <thead>
                        <tr>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Week Ending Date
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Task Description
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Status
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Collection of household waste
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Completed
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Minor delays due to holidays
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Waste segregation at collection points
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Completed
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            On schedule
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Waste disposal at landfill
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Completed
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Equipment maintenance
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Recycling operations
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Completed
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Efficient sorting process
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Collection of household waste
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Completed
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Weather-related delays
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Waste segregation at collection points
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Completed
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Improved efficiency
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January 1, 2023
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Waste disposal at landfill
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Completed
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Landfill capacity concerns
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <h2 id="keyObs" className="text-xl font-bold mt-4 mb-2">
                      4. Key Observations
                    </h2>
                    <ul className="list-disc pl-6">
                      <li className="mb-2">
                        Garbage collection and disposal tasks for January 1,
                        2023, were completed without significant issues.
                      </li>
                      <li className="mb-2">
                        Recycling operations were particularly successful with
                        no reported problems.
                      </li>
                      <li>
                        Weather conditions on this date were favorable for
                        garbage collection.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Rnd>
        </div>
      ) : type === "GarbageManagement" ? (
        <div
          style={styles.calfordrgg}
          className="fixed inset-0 flex justify-center items-center  bg-opacity-60 z-50"
        >
          <Rnd
            default={{
              x: 500,
              y: 20,
              width: 700,
              height: 800,
            }}
            minWidth={700}
            minHeight={500}
            bounds="parent"
            className="resizable-box"
          >
            <div
              className="bg-white shadow-lg modal-content rounded-[12px] h-[100%] z-50"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="header  w-full rounded-tl-[12px] rounded-tr-[12px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
                <span className="grow flex items-center justify-center">
                  Garbage Management Progress Report
                </span>
                <button
                  className="absolute top-2 right-2 p-2 rounded-full  hover:bg-[#0000000a] text-[#b5b4b2]  focus:outline-none"
                  onClick={onClose}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <Tooltip
                title="Send Email"
                arrow
                placement="right"
                className="absolute bottom-1 right-0"
              >
                <button
                  className="bg-blue-300 hover:bg-blue-400 text-white font-semibold py-2 px-2 rounded-full flex items-center justify-center mr-2"
                  onClick={handleClick}
                >
                  <img className="h-6" src={SendEmailIco} alt="" srcSet="" />
                </button>
              </Tooltip>

              <Tooltip
                title="Downlaod File"
                arrow
                placement="left"
                className="absolute bottom-1 right-14"
              >
                <button
                  className="bg-green-300 hover:bg-green-400 text-white font-semibold py-2 px-2 rounded-full flex items-center justify-center ml-2"
                  onClick={handlePdfDownload}
                >
                  <img className="h-6" src={DownloadFileIco} alt="" srcSet="" />
                </button>
              </Tooltip>
              <div className="bg-gray-100 p-4  h-[calc(100%-90px)] overflow-y-auto custom-scrollbar">
                <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow-lg">
                  <h1 className="text-3xl font-bold text-center mb-2">
                    Garbage Management Progress Report
                  </h1>
                  <h2 className="text-xl text-center">
                    Kolkata Municipal Corporation
                  </h2>
                  <p className="text-center">
                    Reporting Period: January 1, 2023, to June 30, 2023
                  </p>

                  <h1 className="text-2xl font-bold mt-4 mb-2">
                    Table of Contents
                  </h1>
                  <ol className="list-decimal list-inside pl-4">
                    <li>
                      <a href="#executive-summary" className="text-blue-500">
                        Executive Summary
                      </a>
                    </li>
                    <li>
                      <a href="#contract-information" className="text-blue-500">
                        Contract Information
                      </a>
                    </li>
                    <li>
                      <a
                        href="#work-completion-status"
                        className="text-blue-500"
                      >
                        Work Completion Status
                      </a>
                    </li>
                    <li>
                      <a
                        href="#predictions-and-recommendations"
                        className="text-blue-500"
                      >
                        Predictions and Recommendations
                      </a>
                    </li>
                    <li>
                      <a href="#appendices" className="text-blue-500">
                        Appendices
                      </a>
                    </li>
                  </ol>

                  <section id="executive-summary" className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">
                      1. Executive Summary
                    </h2>
                    <p>
                      This report provides an in-depth overview of garbage
                      management progress over the past six months, assessed at
                      weekly intervals. The operations have been carried out by
                      GreenWaste Solutions Pvt. Ltd. for the Kolkata Municipal
                      Corporation. The report includes contract particulars,
                      work completion status, and future predictions.
                    </p>
                  </section>

                  <section id="contract-information" className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">
                      2. Contract Information
                    </h2>
                    <ul>
                      <li>
                        <strong>Contractor Name:</strong> GreenWaste Solutions
                        Pvt. Ltd.
                      </li>
                      <li>
                        <strong>Contract Start Date:</strong> January 1, 2023
                      </li>
                      <li>
                        <strong>Contract End Date:</strong> June 30, 2023
                      </li>
                      <li>
                        <strong>Contract Value:</strong> â‚¹5,000,000
                      </li>
                      <li>
                        <strong>Contract Scope:</strong> Collection and disposal
                        of municipal waste in Kolkata.
                      </li>
                    </ul>
                  </section>

                  <section id="work-completion-status" className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">
                      3. Work Completion Status
                    </h2>

                    <div className="mt-4">
                      <p className="text-lg font-semibold">
                        3.1. Weekly Progress
                      </p>
                      <table className="w-full border-collapse mt-4">
                        <thead>
                          <tr>
                            <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                              Week Ending Date
                            </th>
                            <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                              Task Description
                            </th>
                            <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                              % Completion
                            </th>
                            <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                              Remarks
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border-b border-gray-400 py-2 px-4">
                              January 7, 2023
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Collection of household waste
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              95%
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Minor delays due to holidays
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-gray-400 py-2 px-4">
                              January 14, 2023
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Waste segregation at collection points
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              100%
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              On schedule
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-gray-400 py-2 px-4">
                              January 21, 2023
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Waste disposal at landfill
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              97%
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Equipment maintenance
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-gray-400 py-2 px-4">
                              January 28, 2023
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Recycling operations
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              98%
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Efficient sorting process
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-gray-400 py-2 px-4">
                              February 4, 2023
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Collection of household waste
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              94%
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Weather-related delays
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-gray-400 py-2 px-4">
                              February 11, 2023
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Waste segregation at collection points
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              99%
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Improved efficiency
                            </td>
                          </tr>
                          <tr>
                            <td className="border-b border-gray-400 py-2 px-4">
                              February 18, 2023
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Waste disposal at landfill
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              96%
                            </td>
                            <td className="border-b border-gray-400 py-2 px-4">
                              Landfill capacity concerns
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <h2 className="text-xl font-semibold mt-4 mb-2">
                      3.2. Monthly Progress
                    </h2>
                    <table className="w-full border-collapse mt-4">
                      <thead>
                        <tr>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Month
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Total Garbage Collected (in tons)
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Compliance Rate (%)
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Additional Comments
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            January
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            3500
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            97%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Minor delays due to fog
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            February
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            3400
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            98%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Operations running smoothly
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            March
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            3600
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            95%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Increased waste volume
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            April
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            3700
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            96%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Recycling efforts successful
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            May
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            3800
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            97%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Kolkata residents' cooperation
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            June
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            3900
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            98%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Summer waste reduction campaign
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            July
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            3800
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            97%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Maintenance schedule adjustment
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </section>

                  <section
                    id="predictions-and-recommendations"
                    className="mt-6"
                  >
                    <h2 className="text-xl font-semibold mb-2">
                      4. Predictions and Recommendations
                    </h2>

                    <h2 className="text-xl font-semibold mt-4 mb-2">
                      4.1. Future Work Projections
                    </h2>
                    <table className="w-full border-collapse mt-4">
                      <thead>
                        <tr>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Month
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Predicted Garbage Collection (in tons)
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Predicted Compliance Rate (%)
                          </th>
                          <th className="bg-gray-200 border-b border-gray-400 py-2 px-4 text-left">
                            Recommendations
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            August
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            4000
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            96%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Promote waste reduction campaigns
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            September
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            4100
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            97%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Outreach to non-compliant areas
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            October
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            4200
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            98%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Review equipment maintenance
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            November
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            4100
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            96%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Prepare for autumn challenges
                          </td>
                        </tr>
                        <tr>
                          <td className="border-b border-gray-400 py-2 px-4">
                            December
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            4000
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            97%
                          </td>
                          <td className="border-b border-gray-400 py-2 px-4">
                            Winter waste collection planning
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="mt-4">
                      <p className="text-lg font-semibold">
                        4.2. Recommendations
                      </p>
                      <ul className="list-disc list-inside pl-4">
                        <li>
                          Enhance the maintenance schedule to minimize delays.
                        </li>
                        <li>
                          Implement and promote waste reduction campaigns to
                          alleviate pressure on landfill sites.
                        </li>
                        <li>
                          Consider winter waste collection planning to address
                          seasonal challenges.
                        </li>
                      </ul>
                    </div>
                  </section>

                  <section id="appendices" className="mt-6">
                    <h2 className="text-xl font-semibold mb-2">
                      5. Appendices
                    </h2>

                    <div className="mt-4">
                      <p className="text-lg font-semibold">
                        5.1. Weekly Progress Charts
                      </p>
                      <p>
                        Weekly Progress Chart: This chart visually represents
                        the weekly progress in garbage collection and disposal
                        for the reporting period.
                      </p>
                      <img
                        src={GMPV1}
                        alt="Weekly Progress Chart"
                        className="mt-2 max-w-full"
                      />
                    </div>

                    <div className="mt-4">
                      <p className="text-lg font-semibold">
                        5.2. Monthly Collection Data
                      </p>
                      <p>
                        Monthly Collection Data: Detailed data for each month's
                        garbage collection, including tons collected, compliance
                        rates, and any additional comments.
                      </p>
                      <img
                        src={GMPV2}
                        alt="Weekly Progress Chart"
                        className="mt-2 max-w-full"
                      />
                    </div>

                    <div className="mt-4">
                      <p className="text-lg font-semibold">
                        5.3. Future Work Projections
                      </p>
                      <p>
                        Future Work Projections Chart: A graphical
                        representation of the predicted garbage collection and
                        compliance rates for the upcoming months.
                      </p>
                      <img
                        src={GMPV3}
                        alt="Weekly Progress Chart"
                        className="mt-2 max-w-full"
                      />
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </Rnd>
        </div>
      ) : type === "IOCL-NRPL" ? (
        <>
          <div
            style={styles.calfordrgg}
            className="fixed inset-0 flex justify-center items-center  bg-opacity-60 z-50"
          >
            <Rnd
              default={{
                x: 500,
                y: 20,
                width: 700,
                height: 800,
              }}
              minWidth={700}
              minHeight={500}
              bounds="parent"
              className="resizable-box"
            >
              <div
                className="bg-white shadow-lg modal-content rounded-[12px] h-[100%] z-50"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="header  w-full rounded-tl-[12px] rounded-tr-[12px] flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
                  <span className="grow flex items-center justify-center">
                    Report
                  </span>
                  <button
                    className="absolute top-2 right-2 p-2 rounded-full  hover:bg-[#0000000a] text-[#b5b4b2]  focus:outline-none"
                    onClick={onClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="bg-gray-100 p-4  h-[calc(100%-90px)] overflow-y-auto custom-scrollbar">
                  <DocViewer
                    pluginRenderers={DocViewerRenderers}
                    documents={[{ uri: pdfData, type: "application/pdf" }]}
                    sandbox="allow-same-origin allow-scripts"
                  />
                </div>
              </div>
            </Rnd>
          </div>
        </>
      ) : null}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        style={{ zIndex: 9999, position: "relative" }}
      >
        <div className="bg-white rounded-[12px] border flex flex-col h-max w-[300px]">
          <div className="w-full rounded-tl-[12px] bg-[#ffca0094] pt-[10px] pb-[10px] font-[500] rounded-tr-[12px] flex items-center justify-between">
            <h3
              className="grow flex items-center justify-center px-5"
              onClick={handleClick}
            >
              Send Mail
            </h3>
            <div className="mr-[10px]" onClick={handleClose}>
              <IconButton aria-label="delete" size="small">
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
          <form
            className="grow mt-5 flex flex-col justify-between m-2"
            onSubmit={sendEmail}
          >
            <div className="flex flex-col items-center w-[100%] h-full">
              <div className="w-[90%]">
                <TextField
                  id="standard-basic"
                  size="small"
                  label="From (Name)"
                  name="from"
                  variant="standard"
                  required
                  fullWidth
                />
              </div>
              <div className="w-[90%] mt-2">
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="To (Email)"
                  name="to"
                  variant="standard"
                  required
                  fullWidth
                />
              </div>
              <div className="w-[90%] mt-2">
                <TextField
                  id="outlined-basic"
                  size="small"
                  label="Subject"
                  name="subject"
                  variant="standard"
                  required
                  fullWidth
                />
              </div>
            </div>
            <div className="m-auto my-5">
              <button
                aria-label="add"
                className=" bg-[#4a4844] text-white !rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                type="submit"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </Popper>
    </>
  );
};

export default ReportModal;
