import testData_Iocl_Nrpl from "../../src/Data/Jsons/IOCL_NRPL_REPROCESSING_consolidated.json";
import testData_Iocl_Nrpl_Object from "../../src/Data/Jsons/IOCL_NRPL_REPROCESSING_consolidated_object_detection.json";

function convertTimeToSeconds(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);
  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalTimeInSeconds;
}

export var latLngs_iocl_nrpl = [];
for (let i = 0; i < testData_Iocl_Nrpl.length; i++) {
  var obj = {
    lat: testData_Iocl_Nrpl[i].lat,
    lon: testData_Iocl_Nrpl[i].lon,
    time: convertTimeToSeconds(testData_Iocl_Nrpl[i].video_time),
    chainage: testData_Iocl_Nrpl[i].chainage,
  };
  latLngs_iocl_nrpl.push(obj);
}

export var latLngs_iocl_nrpl_object = [];
for (let i = 0; i < testData_Iocl_Nrpl_Object.length; i++) {
  var object = {
    lat: testData_Iocl_Nrpl_Object[i].lat,
    lon: testData_Iocl_Nrpl_Object[i].lon,
    time: convertTimeToSeconds(testData_Iocl_Nrpl_Object[i].video_time),
    chainage: testData_Iocl_Nrpl_Object[i].chainage,
  };
  latLngs_iocl_nrpl_object.push(object);
}

const filterlabel = (label) => {
  if (label < 0) {
    return;
  } else {
    return parseInt(label);
  }
};

export var chainages_iocl_nrpl = [];
for (let i = 0; i < testData_Iocl_Nrpl.length; i++) {
  var Chainobj = {
    value: convertTimeToSeconds(testData_Iocl_Nrpl[i].video_time),
    label: filterlabel(testData_Iocl_Nrpl[i].chainage),
  };
  chainages_iocl_nrpl.push(Chainobj);
  var Firstobj = {
    value: convertTimeToSeconds(testData_Iocl_Nrpl[0].video_time),
    label: testData_Iocl_Nrpl[0].chainage.toFixed(1),
  };
  chainages_iocl_nrpl.push(Firstobj);
}

export var chainages_iocl_nrpl_object = [];
for (let i = 0; i < testData_Iocl_Nrpl_Object.length; i++) {
  var Chainobject = {
    value: convertTimeToSeconds(testData_Iocl_Nrpl_Object[i].video_time),
    label: filterlabel(testData_Iocl_Nrpl_Object[i].chainage),
  };
  chainages_iocl_nrpl_object.push(Chainobject);
}
var Firstobject = {
  value: convertTimeToSeconds(testData_Iocl_Nrpl_Object[0].video_time),
  label: testData_Iocl_Nrpl_Object[0].chainage.toFixed(1),
};
chainages_iocl_nrpl_object.push(Firstobject);

export var annotations_iocl_nrpl = [];
for (let i = 0; i < testData_Iocl_Nrpl.length; i++) {
  var annoObj = {};
  if (testData_Iocl_Nrpl[i].ann_on_vid_time[0]) {
    annoObj.timeInSec = convertTimeToSeconds(testData_Iocl_Nrpl[i].video_time);
    annoObj.time = testData_Iocl_Nrpl[i].video_time;
    annoObj.annotations = testData_Iocl_Nrpl[i].ann_on_vid_time;
    annoObj.chainage = testData_Iocl_Nrpl[i].chainage.toFixed(1);
    annoObj.endTime = convertTimeToSeconds(testData_Iocl_Nrpl[i].end_ann_time);
    annoObj.severity = "low";
    annotations_iocl_nrpl.push(annoObj);
  }
}

export var annotations_iocl_nrpls_object = [];
for (let i = 0; i < testData_Iocl_Nrpl_Object.length; i++) {
  var annoObject = {};
  if (testData_Iocl_Nrpl_Object[i].ann_on_vid_time[0]) {
    annoObject.timeInSec = convertTimeToSeconds(
      testData_Iocl_Nrpl_Object[i].video_time
    );
    annoObject.time = testData_Iocl_Nrpl_Object[i].video_time;
    annoObject.annotations = testData_Iocl_Nrpl_Object[i].ann_on_vid_time;
    annoObject.chainage = testData_Iocl_Nrpl_Object[i].chainage.toFixed(1);
    annoObject.endTime = convertTimeToSeconds(
      testData_Iocl_Nrpl_Object[i].end_ann_time
    );
    annotations_iocl_nrpls_object.push(annoObject);
  }
}
