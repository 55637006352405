import axios from "axios";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/material";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNzhkZjc0ZS02YzQzLTRjMzAtODg3ZC0zOTk4MzFlMWRmNzgiLCJpZCI6MTU5MTU1LCJpYXQiOjE2OTIyODIwODV9._OhEfJPn8nLJ6LwuOSd7-Z3MB8MpQT_FwyxIOnWL2cI";

function CircularProgressWithLabel(props) {
  return (
    <div className="relative inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <div className="absolute top-[30%] left-0 right-0 flex items-center justify-center bold">
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          className="!font-bold"
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </div>
    </div>
  );
}

const TilingStatus = (props) => {
  const [percent, setPercent] = React.useState(0);
  const [message, setMessage] = React.useState("Loading...");
  React.useEffect(() => {
    const uploadStatus = async () => {
      if (props.id) {
        try {
          const response = await axios.get(
            `https://api.cesium.com/v1/assets/${props.id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const status = response.data.status;
          if (status === "COMPLETE") {
            setPercent(100);
            setMessage("Completed");
          } else if (status === "DATA_ERROR") {
            setMessage("There was a problem with the uploaded data.");
          } else if (status === "ERROR") {
            setMessage(
              "An unknown tiling error occurred, please contact Administrator."
            );
          } else {
            if (status === "NOT_STARTED") {
              setMessage("Tiling pipeline initializing.");
            } else {
              setMessage("Tiling in Process");
              setPercent(response.data.percentComplete);
            }
            setTimeout(await uploadStatus(props.id), 100000);
          }
        } catch (error) {
          console.error("Error");
        }
      }
    };
    uploadStatus(props.id);
  }, [props.id]);

  return (
    <div className="bg-white flex gap-2 w-max m-2 p-2 rounded-lg items-center justify-center">
      <div>{message}</div>
      <CircularProgressWithLabel value={percent} />
    </div>
  );
};

export default TilingStatus;
