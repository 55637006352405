import React from "react";
import MapIcon from "@mui/icons-material/Map";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Popper } from "@mui/material";
import AddAnnotations from "../../Features/Videography/hooks/AddAnnotations";
import Tooltip from "@mui/material/Tooltip";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import { mapToggleActions } from "../../redux/slices/Videography/map-toggle-slice";
import FilterAnnotations from "../../Features/Videography/hooks/FilterAnnotations";

function convertTimeToSeconds(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);
  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalTimeInSeconds;
}
const SidePanel = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const [isAddPostion, setAddPostion] = React.useState(false);
  const [matches, setMatches] = React.useState(
    window.matchMedia("(max-width: 767px)").matches
  );
  const dispatch = useDispatch();
  const showMap = useSelector((state) => state.mapToggle.showMap);
  const annotations = useSelector((state) => state.project.annotations);

  React.useEffect(() => {
    window
      .matchMedia("(max-width: 767px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  React.useEffect(() => {
    if (matches) {
      setAddPostion(true);
    } else {
      setAddPostion(false);
    }
  }, [matches]);

  const openPopper = (event) => {
    props.pauseVideos();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const openFilterPopper = (event) => {
    setFilterAnchorEl(filterAnchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const filteropen = Boolean(filterAnchorEl);
  const filterid = filteropen ? "simple-popper" : undefined;

  const addAnnotation = (event) => {
    event.preventDefault();
    const annObject = {
      timeInSec: convertTimeToSeconds(event.target.video_time.value),
      time: event.target.video_time.value,
      chainage: event.target.chainage.value,
      annotations: [
        {
          annotation: event.target.label.value,
          category: event.target.type.value,
        },
      ],
      endTime: convertTimeToSeconds(event.target.endtime.value),
      severity: event.target.severity.value,
    };

    const startTime = convertTimeToSeconds(event.target.video_time.value);
    const endTime = convertTimeToSeconds(event.target.endtime.value);

    if (endTime > startTime) {
      annotations.push(annObject);
      annotations.sort((a, b) => a.timeInSec - b.timeInSec);
      props.handleAnnoRefresh();
      setAnchorEl(null);
    } else {
      toast.error("Video Time is greater than End Time");
    }
  };

  const handleMap = () => {
    dispatch(mapToggleActions.toggle());
  };

  return (
    <div className="w-[4%] max-[700px]:w-[100%] min-[700px]:h-full max-[700px]:h-[7%] sidepanelmview">
      <div className="h-full border-r max-[500px]:gap-1 p-2 flex flex-col gap-5 sidepanel">
        <Tooltip title="Add Annotations" placement="right" arrow>
          <div
            className="flex flex-col items-center  side-btn cursor-pointer"
            onClick={openPopper}
          >
            <AddCircleRoundedIcon
              className={`text-[#B5B7C2] rounded-full side-btn-icon ${
                open === true ? "side-btn-icon-onclick" : ""
              }`}
            />
            <span className="mt-[3px] text-[#5d647b] leading-[0.75rem] text-[0.71875rem] tracking-[0.01em] font-[600]">
              Annotations
            </span>
          </div>
        </Tooltip>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={isAddPostion === true ? "bottom" : "right-start"}
          sx={{
            zIndex: 999,
          }}
        >
          <AddAnnotations
            currentTime={props.currentTime}
            currentTime_R4={props.currentTime_R4}
            openPopper={openPopper}
            handleAnnoRefresh={props.handleAnnoRefresh}
            addAnnotation={addAnnotation}
            isCompare={props.isCompare}
          />
        </Popper>
        <Tooltip title="Filter Annotations" placement="right" arrow>
          <div
            className="flex flex-col items-center  cursor-pointer side-btn"
            onClick={openFilterPopper}
          >
            <FilterListIcon
              className={`side-btn-icon rounded-full text-[#B5B7C2] hover:bg-[#F3F4F6] ${
                filterAnchorEl ? "side-btn-icon-onclick" : ""
              }`}
            />
            <span
              className={`text-[#5d647b] mt-[3px] text-[#5d647b] leading-[0.75rem] text-[0.71875rem] tracking-[0.01em] font-[600]`}
            >
              Filter
            </span>
          </div>
        </Tooltip>
        <Popper
          id={filterid}
          open={filteropen}
          anchorEl={filterAnchorEl}
          placement={isAddPostion === true ? "bottom" : "right-start"}
          sx={{
            zIndex: 999,
          }}
        >
          <FilterAnnotations openFilterPopper={openFilterPopper} />
        </Popper>
        <Tooltip title="Show Map" placement="right" arrow>
          <div
            className="flex flex-col items-center  cursor-pointer side-btn"
            onClick={handleMap}
          >
            <MapIcon
              className={`side-btn-icon rounded-full text-[#B5B7C2] hover:bg-[#F3F4F6] ${
                showMap ? "side-btn-icon-onclick" : ""
              }`}
            />
            <span
              className={`text-[#5d647b] mt-[3px] text-[#5d647b] leading-[0.75rem] text-[0.71875rem] tracking-[0.01em] font-[600]`}
            >
              Map
            </span>
          </div>
        </Tooltip>
      </div>
      {/* <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 2000,
          style: {
            background: "#4a4844",
            color: "#fff",
            border: "1px solid #4a4844",
          },
        }}
      /> */}
    </div>
  );
};

export default SidePanel;
