import { useEffect } from "react";
import {
  Color,
  CallbackProperty,
  HeightReference,
  PolygonHierarchy,
  defined,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  VerticalOrigin,
  HorizontalOrigin,
  Cartographic,
  Matrix4,
  ColorMaterialProperty,
  Math as MathOfCesium,
  NearFarScalar,
} from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { gisToolActions } from "../../../redux/slices/GIS/gis-tools-slice";

export function usePolygonMeasureTool(viewerRef, setShapeCords) {
  const { polygonMeasureTool } = useSelector((state) => state.gisTools);
  const dispatch = useDispatch();

  useEffect(() => {
    const viewer = viewerRef.current;
    let drawingMode = "polygon";
    let point;

    function createPoint(worldPosition) {
      point = viewer.entities.add({
        position: worldPosition,
        point: {
          color: Color.GOLD,
          pixelSize: 10,
          heightReference: HeightReference.RELATIVE_TO_GROUND,
        },
      });
      if (viewer.entities.values.length === 1) {
        viewer.entities.remove(point);
      }
      return point;
    }

    function calculatePolygonArea(points) {
      let area = 0;
      const numPoints = points.length;

      for (let i = 0; i < numPoints; i++) {
        const p1 = Cartographic?.fromCartesian(points[i]);
        const p2 = Cartographic?.fromCartesian(points[(i + 1) % numPoints]);
        area += (p2.longitude - p1.longitude) * (p2.latitude + p1.latitude);
      }

      area = (Math.abs(area) * 6371000 * 6371000) / 2; // Convert to square meters

      return area;
    }

    function drawShape(positionData) {
      let shape;
      let edges;

      if (drawingMode === "polygon") {
        const area = calculatePolygonArea(activeShapePoints);
        const lastIndex = activeShapePoints.length - 1;
        const lastPoint = activeShapePoints[lastIndex];
        // console.log(activeShapePoints, "activeShapePoints");

        // Display the area label
        lastIndex !== 0 &&
          activeShapePoints.length > 2 &&
          viewer.entities.add({
            position: lastPoint,
            label: {
              text: `Area: ${area.toFixed(2)} m²`,
              font: "16px Helvetica",
              fillColor: Color.GREEN,
              outlineColor: Color.BLACK,
              verticalOrigin: VerticalOrigin.CENTER,
              horizontalOrigin: HorizontalOrigin.RIGHT,
              showBackground: true,
              backgroundColor: Color.WHITE,
              translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
            },
          });
        let totalPolylineDistance = 0;
        if (lastIndex !== 0 && activeShapePoints.length <= 2) {
          for (let i = 1; i < activeShapePoints.length; i++) {
            const cartographic1 = Cartographic.fromCartesian(
              activeShapePoints[i - 1]
            );
            const cartographic2 = Cartographic.fromCartesian(
              activeShapePoints[i]
            );

            const lat1 = MathOfCesium.toDegrees(cartographic1.latitude);
            const lon1 = MathOfCesium.toDegrees(cartographic1.longitude);
            const lat2 = MathOfCesium.toDegrees(cartographic2.latitude);
            const lon2 = MathOfCesium.toDegrees(cartographic2.longitude);

            const segmentDistance = (
              calcDist(lat1, lon1, lat2, lon2) * 1000
            ).toPrecision(6);

            totalPolylineDistance += parseFloat(segmentDistance);
          }
          viewer.entities.add({
            position: lastPoint,
            label: {
              text: totalPolylineDistance.toFixed(4) + " meters",
              font: "16px Helvetica",
              fillColor: Color.GREEN,
              outlineColor: Color.BLACK,

              verticalOrigin: VerticalOrigin.CENTER,
              horizontalOrigin: HorizontalOrigin.RIGHT,
              showBackground: true, // Add a white background to the label
              backgroundColor: Color.WHITE,
              translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
            },
          });
        }

        if (activeShapePoints?.length == 2) {
          shape = viewer.entities.add({
            polyline: {
              positions: positionData,
              clampToGround: true,
              width: 3,
            },
          });
        } else {
          shape = viewer.entities.add({
            polygon: {
              hierarchy: positionData,
              material: new ColorMaterialProperty(Color.KHAKI.withAlpha(0.4)),
            },
          });
        }

        if (positionData.length > 0) {
          edges = viewer.entities.add({
            polyline: {
              positions: [...positionData, positionData[0]], // Close the loop
              clampToGround: true,
              width: 3,
              material: Color.KHAKI,
            },
          });
        }
      }
      setShapeCords(positionData);

      return { shape, edges };
    }

    function showTooltip(e, lat, lon, distance) {
      if (drawingMode === "polygon" && activeShapePoints.length > 1) {
        var x = e.endPosition.x;
        var y = e.endPosition.y;

        document.getElementById("tooltip").innerHTML =
          "<strong>Latitude:</strong> " +
          lat +
          "<br><strong>Longitude:</strong> " +
          lon +
          "<br><strong>Distance:</strong> " +
          distance +
          " meters";

        document.getElementById("tooltip").style.left = x + 10 + "px";
        document.getElementById("tooltip").style.top = y - 10 + "px";
      }
    }

    function calcDist(lat1, lon1, lat2, lon2) {
      var R = 6371; // km
      var dLat = toRad(lat2 - lat1);
      var dLon = toRad(lon2 - lon1);
      var lat1 = toRad(lat1);
      var lat2 = toRad(lat2);

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) *
          Math.sin(dLon / 2) *
          Math.cos(lat1) *
          Math.cos(lat2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;
      return d;
    }

    function toRad(Value) {
      return (Value * Math.PI) / 180;
    }

    let activeShapePoints = [];
    let activeShape;
    let floatingPoint;
    let lineDistance = 0;
    const handler = new ScreenSpaceEventHandler(viewer.canvas);

    let handlerAdded = false;

    function terminateShape() {
      activeShapePoints.pop();
      drawShape(activeShapePoints);
      viewer.entities.remove(floatingPoint);
      viewer.entities.remove(activeShape);
      floatingPoint = undefined;
      activeShape = undefined;
      activeShapePoints = [];
      dispatch(gisToolActions.turnOffTools());
      dispatch(gisToolActions.setselectedTool(null));
    }

    if (polygonMeasureTool && !handlerAdded) {
      handler.setInputAction(function (event) {
        const ray = viewer.camera.getPickRay(event.position);
        const earthPosition = viewer.scene.globe.pick(ray, viewer.scene);

        if (defined(earthPosition)) {
          if (activeShapePoints.length === 0) {
            floatingPoint = createPoint(earthPosition);
            activeShapePoints.push(earthPosition);
            const dynamicPositions = new CallbackProperty(function () {
              if (drawingMode === "polygon") {
                return new PolygonHierarchy(activeShapePoints);
              }
              return activeShapePoints;
            }, false);
            activeShape = drawShape(dynamicPositions);
          }
          activeShapePoints.push(earthPosition);
          createPoint(earthPosition);
          if (activeShapePoints.length > 1) {
            document.getElementById("tooltip").style.display = "block";
          }
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      handler.setInputAction(function (event) {
        if (defined(floatingPoint)) {
          const ray = viewer.camera.getPickRay(event.endPosition);
          const newPosition = viewer.scene.globe.pick(ray, viewer.scene);
          if (defined(newPosition)) {
            floatingPoint.position.setValue(newPosition);
            activeShapePoints.pop();
            activeShapePoints.push(newPosition);

            if (drawingMode === "polygon" && activeShapePoints.length > 2) {
              let cartographic1 = Cartographic.fromCartesian(
                activeShapePoints[activeShapePoints.length - 1]
              );
              let cartographic2 = Cartographic.fromCartesian(
                activeShapePoints[activeShapePoints.length - 2]
              );

              let lat1 = MathOfCesium.toDegrees(cartographic1.latitude);
              let lon1 = MathOfCesium.toDegrees(cartographic1.longitude);
              let lat2 = MathOfCesium.toDegrees(cartographic2.latitude);
              let lon2 = MathOfCesium.toDegrees(cartographic2.longitude);

              lineDistance = (
                calcDist(lat1, lon1, lat2, lon2) * 1000
              ).toPrecision(6);

              showTooltip(
                event,
                lat2.toPrecision(6),
                lon2.toPrecision(6),
                lineDistance
              );
            }
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);

      handler.setInputAction(function (event) {
        let i;
        let lats = [];
        let lons = [];
        let vertices = [];

        for (i = 0; i < activeShapePoints.length; i++) {
          let cartographic = Cartographic.fromCartesian(activeShapePoints[i]);

          let lat = MathOfCesium.toDegrees(cartographic.latitude);
          let lon = MathOfCesium.toDegrees(cartographic.longitude);

          lats.push(lat);
          lons.push(lon);

          let vertex = [activeShapePoints[i]["x"], activeShapePoints[i]["y"]];
          vertices.push(vertex);
        }

        if (activeShapePoints.length <= 2) {
          viewer.entities.remove(point);
          terminateShape();

          // viewer.entities.remove(edges);
        } else {
          // calculateShapes(lats, lons, vertices, drawingMode);
          terminateShape();
        }

        // setOpenPolygonAnnotationModal();

        document.getElementById("tooltip").style.display = "none";
      }, ScreenSpaceEventType.RIGHT_CLICK);

      handlerAdded = true;
    } else if (polygonMeasureTool === false && handlerAdded) {
      handler.destroy();
      handlerAdded = false;
    }

    viewer.camera.lookAtTransform(Matrix4.IDENTITY);

    return () => {
      if (handler && !handler.isDestroyed()) {
        handler.destroy();
      }
    };
  }, [polygonMeasureTool]);
}
