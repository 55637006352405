import { Cartesian3, Math as MathOfCesium } from "cesium";
import Cookies from "js-cookie";

/**
 * Handle the home button click event to reset the view in the  viewer.
 *
 * @param {React.RefObject} viewerRef - A React ref object pointing to the Cesium viewer component.
 * @param {function} setGoHome - A function to set the "goHome" state.
 * @param {boolean} goHome - A boolean state indicating whether to go to the home view.
 * @returns {void}
 * @version 1.0.0
 */
export const handleHomeButtonClick = (
  viewerRef,
  setGoHome,
  goHome,
  imageryLayer
) => {
  setGoHome(!goHome);

  const viewer = viewerRef.current;

  const clientNameFromCookie = Cookies.get("clientName");
  if (clientNameFromCookie === "skyhigh") {
    var latitude = 15.63578;
    var longitude = 73.87924;
    var height = 2000;

    // Fly to the location
    viewer.camera.flyTo({
      destination: Cartesian3.fromDegrees(longitude, latitude, height),
      orientation: {
        heading: MathOfCesium.toRadians(0),
        pitch: MathOfCesium.toRadians(-90),
        roll: 0.0,
      },
      duration: 3, // Optional: Duration of the flight animation in seconds
    });
  } else {
    if (imageryLayer) {
      viewer.flyTo(imageryLayer);
    }
  }
};
