import { createSlice } from "@reduxjs/toolkit";

const mapToggleSlice = createSlice({
  name: "map-toggle",
  initialState: { showMap: false },
  reducers: {
    toggle(state) {
      state.showMap = !state.showMap;
    },
  },
});

export const mapToggleActions = mapToggleSlice.actions;

export default mapToggleSlice.reducer;
