import { useEffect } from "react";
import {
  OpenStreetMapImageryProvider,
  IonImageryProvider,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  SplitDirection,
  ImageryLayer,
  WebMapServiceImageryProvider,
  Cartesian3,
  Math as MathOfCesium,
} from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { useParams } from "react-router-dom";

/**
 * Custom React hook for facilitating the comparison of assets in a CesiumJS viewer.
 *
 * @param {boolean} showCompare - Indicates whether to enable the asset comparison mode.
 * @param {string[]} compareAssets - An array containing asset IDs or references to assets to be compared.
 * @param {object} viewerRef - A React ref object pointing to the Cesium viewer component.
 * @returns {void}
 * @version 1.0.0
 */

export function useCompareAssets(viewerRef) {
  const { compareAssets, asset } = useSelector((state) => state.gisHome);
  const showCompare = useSelector((state) => state.gisTools.showComparer);
  const dispatch = useDispatch();
  const { grp } = useParams();

  useEffect(() => {
    const viewer = viewerRef.current;

    // Remove all data sources and imagery layers
    viewer.dataSources.removeAll();
    viewer.entities.removeAll();

    const compareLayers = viewer.imageryLayers;
    const imageryLayer1Asset = compareAssets?.[0];
    const imageryLayer2Asset = compareAssets?.[1];

    const handleZoomOnChange = () => {
      const viewer = viewerRef.current;
      const camera = viewer.camera;
      viewer.camera.defaultZoomAmount = 0.01;

      camera.zoomOut();
    };

    if (showCompare) {
      viewer.imageryLayers.removeAll();

      const openStreetMapLayer = new OpenStreetMapImageryProvider();

      compareLayers.addImageryProvider(openStreetMapLayer);
    }

    if (!imageryLayer1Asset) {
      return;
    }

    // Create imagery layer for the first asset
    var imageryLayer1 = null;
    if (grp === "PIL") {
      const imageryLayer = new WebMapServiceImageryProvider({
        url: "http://geoserver.indrones.com/geoserver/ows",
        layers: imageryLayer1Asset,
        // Replace with your actual workspace and layer names
        parameters: {
          transparent: "true",
          format: "image/png",
        },
      });
      imageryLayer1 = ImageryLayer.fromProviderAsync(imageryLayer);
    } else if (grp === "skyhigh") {
      const imageryLayer = new WebMapServiceImageryProvider({
        url: "https://gis.indrones.com/geoserver/skyhigh/wms",
        layers: imageryLayer1Asset,
        // Replace with your actual workspace and layer names
        parameters: {
          transparent: "true",
          format: "image/png",
        },
      });

      var latitude = 15.63578;
      var longitude = 73.87924;
      var height = 2000;

      viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(longitude, latitude, height),
        orientation: {
          heading: MathOfCesium.toRadians(0),
          pitch: MathOfCesium.toRadians(-90),
          roll: 0.0,
        },
        duration: 3, // Optional: Duration of the flight animation in seconds
      });
      imageryLayer1 = ImageryLayer.fromProviderAsync(imageryLayer);
    } else {
      imageryLayer1 = ImageryLayer.fromProviderAsync(
        IonImageryProvider.fromAssetId(imageryLayer1Asset)
      );
    }

    // Ensure that imageryLayer1 is defined
    if (!imageryLayer1) {
      return;
    }

    // Set up the ScreenSpaceEventHandler for the slider
    const slider = document.getElementById("slider");
    const handler = new ScreenSpaceEventHandler(slider);

    if (showCompare && compareAssets.length > 0) {
      imageryLayer1.splitDirection = SplitDirection.LEFT;

      // console.log("imageryLayer1", imageryLayer1);

      compareLayers.add(imageryLayer1);

      var imageryLayer2 = null;
      if (imageryLayer2Asset) {
        if (grp === "PIL") {
          // imageryLayer2 = ImageryLayer.fromProviderAsync(
          //   IonImageryProvider.fromAssetId(imageryLayer2Asset)
          // );
          const imageryLayer = new WebMapServiceImageryProvider({
            url: "http://geoserver.indrones.com/geoserver/ows",
            layers: imageryLayer1Asset,
            // Replace with your actual workspace and layer names
            parameters: {
              transparent: "true",
              format: "image/png",
            },
          });
          imageryLayer2 = ImageryLayer.fromProviderAsync(imageryLayer);
        } else if (grp === "skyhigh") {
          const imageryLayer = new WebMapServiceImageryProvider({
            url: "https://gis.indrones.com/geoserver/skyhigh/wms",
            layers: imageryLayer2Asset,
            // Replace with your actual workspace and layer names
            parameters: {
              transparent: "true",
              format: "image/png",
            },
          });
          imageryLayer2 = ImageryLayer.fromProviderAsync(imageryLayer);
        } else {
          imageryLayer1 = ImageryLayer.fromProviderAsync(
            IonImageryProvider.fromAssetId(imageryLayer1Asset)
          );
        }
        if (imageryLayer2) {
          imageryLayer2.splitDirection = SplitDirection.RIGHT;
          compareLayers.add(imageryLayer2);
        }
      }

      grp === "PIL" &&
        viewer.camera.flyTo({
          destination: Cartesian3.fromDegrees(
            74.92142098937722,
            18.498149869146314,
            1000
          ),
          // duration: 5.0, // Set the flight duration in seconds
        });
      // : viewer.flyTo(imageryLayer1);

      const scene = viewer.scene;
      scene.splitPosition =
        slider?.offsetLeft / slider?.parentElement?.offsetWidth;

      let moveActive = false;

      function move(movement) {
        if (!moveActive) {
          return;
        }
        const relativeOffset = movement.endPosition.x;
        const splitPosition =
          (slider?.offsetLeft + relativeOffset) /
          slider.parentElement?.offsetWidth;
        slider.style.left = `${100.0 * splitPosition}%`;
        scene.splitPosition = splitPosition;
      }

      handler.setInputAction(function () {
        moveActive = true;
      }, ScreenSpaceEventType.LEFT_DOWN);

      handler.setInputAction(function () {
        moveActive = true;
      }, ScreenSpaceEventType.PINCH_START);

      handler.setInputAction(function (m) {
        move(m);
        handleZoomOnChange();
      }, ScreenSpaceEventType.MOUSE_MOVE);
      handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);

      handler.setInputAction(function () {
        moveActive = false;
      }, ScreenSpaceEventType.LEFT_UP);

      handler.setInputAction(function () {
        moveActive = false;
      }, ScreenSpaceEventType.PINCH_END);
    } else {
      if (imageryLayer1) {
        compareLayers.remove(imageryLayer1, true);
      }
      if (imageryLayer2Asset) {
        const imageryLayer2 = compareLayers.get(1); // Assuming it's at index 1
        if (imageryLayer2) {
          compareLayers.remove(imageryLayer2, true);
        }
      }
      handler.destroy();
      viewer.imageryLayers.removeAll();
      const openStreetMapLayer = new OpenStreetMapImageryProvider();

      compareLayers.addImageryProvider(openStreetMapLayer);
      dispatch(gisActions.refCounterUpdate());
    }

    return async () => {
      if (handler && !handler.isDestroyed()) {
        handler.destroy();
      } else {
        const imageryLayer = imageryLayer1Asset
          ? viewer.imageryLayers.addImageryProvider(
              await IonImageryProvider.fromAssetId(imageryLayer1Asset)
            )
          : viewer.imageryLayers.addImageryProvider(
              await IonImageryProvider.fromAssetId(asset)
            );
        viewer.camera.flyTo(imageryLayer);
      }
    };
  }, [showCompare, compareAssets, viewerRef]);
}
