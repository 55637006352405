import React from "react";
import {
  Modal,
  IconButton,
  TextField,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";

import { S3Apis } from "../../Services/ThirdPartyApi/AWS/S3";
import { AnnotationManager } from "../../Services/ThirdPartyApi/AWS/DynamoDB";

import ImageSlider from "./ImageSlider";
import PropTypes from "prop-types";
import CommentBox from "./CommentBox";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import { formatDate } from "../../Features/GIS/Utils/otherUtils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const dropzoneStyle = {
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
  border: "2px dashed #cccccc",
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-full w-full">{children}</div>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const MetaDataModal = ({
  isOpen,
  onClose,
  name,
  id,
  markerData,
  onDeleteMarker,
  descriptions,
  Category,
  Severity,
  images,
  // refCounterUpdate
}) => {
  const [isEditMode, setIsEditMode] = React.useState(true);
  const [description, setDescription] = React.useState(descriptions);
  const [category, setCategory] = React.useState(Category);
  const [severity, setSeverity] = React.useState(Severity);
  const [value, setValue] = React.useState(0);
  const [uploadedFile, setUploadedFile] = React.useState(null);

  const { userPermissions } = useSelector((state) => state.gis);

  // redux
  const dispatch = useDispatch();

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setDescription(descriptions);
    setCategory(Category);
    setSeverity(Severity);
    setUploadedFile(null);
    setIsEditMode(!isEditMode);
  };

  const resetData = () => {
    setDescription(descriptions);
    setCategory(Category);
    setSeverity(Severity);
    setUploadedFile(null);
    setIsEditMode(!isEditMode);
  };

  const updateAnnotation = async (id, timestamp) => {
    var imagedata = [];
    if (uploadedFile) {
      const imageData = await S3Apis.uploadData(uploadedFile, "images");
      imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
    }

    const key = {
      id: id,
      timestamp: timestamp,
    };
    const fieldsToUpdate = {
      description: description,
      category: category,
      severity: severity,
      updatedOn: formatDate(Date.now()),
      images: imagedata,
    };
    AnnotationManager.editAnnotations(key, fieldsToUpdate);
    // fetchData();
    // refCounterUpdate();
    dispatch(gisActions.refCounterUpdate());

    onClose();
  };

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };
  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        onClose();
        setIsEditMode(true);
      }}
    >
      <div
        style={style}
        className="flex flex-col items-center justify-between bg-white w-full h-max rounded-[12px] "
      >
        {/* Modal Header */}
        <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px] w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
          {markerData && (
            <>
              {markerData.filter_type === "polygon" &&
                userPermissions.includes("annotation_polygon_view") && (
                  <IconButton
                    aria-label="delete"
                    onClick={handleEdit}
                    size="small"
                    className="text-[#ffffff]"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              {markerData.filter_type === "polyline" &&
                userPermissions.includes("annotation_polyline_view") && (
                  <IconButton
                    aria-label="delete"
                    onClick={handleEdit}
                    size="small"
                    className="text-[#ffffff]"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              {markerData.filter_type === "marker" &&
                userPermissions.includes("annotation_marker_edit") && (
                  <IconButton
                    aria-label="delete"
                    onClick={handleEdit}
                    size="small"
                    className="text-[#ffffff]"
                  >
                    <EditIcon />
                  </IconButton>
                )}
            </>
          )}

          <h3 className="text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
            {name}
          </h3>
          <div className="mr-[10px]">
            <IconButton
              onClick={() => {
                onClose();
                setIsEditMode(true);
              }}
              aria-label="Close"
              size="small"
              className="text-[#211f1c]"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="grow h-full w-full p-3 custom-scrollbar">
          <Tabs value={value} onChange={handleChange} className="w-full">
            <Tab label="Description" {...a11yProps(0)} className="w-[50%]" />
            <Tab
              label="Comments"
              {...a11yProps(1)}
              className={`w-[50%] ${
                userPermissions.includes("comments_view") ? "" : "hidden"
              }`}
            />
          </Tabs>
          <CustomTabPanel value={value} index={0} className="h-full">
            <>
              <div className="grow w-full p-3 flex flex-col gap-3">
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Description:</label>
                      <TextField
                        name="textNote"
                        size="small"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        InputProps={{
                          readOnly: isEditMode,
                        }}
                        className="grow"
                      />
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Description :</p>{" "}
                      <p>{descriptions ? descriptions : "-"}</p>
                    </label>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  {isEditMode === false && Category !== "Polygon" ? (
                    <>
                      <label className="w-[30%]">Category:</label>
                      <Select
                        id="demo-simple-select-error"
                        name="category"
                        size="small"
                        value={category}
                        className="grow"
                        onChange={(e) => setCategory(e.target.value)}
                        inputProps={{ readOnly: isEditMode }}
                      >
                        <MenuItem value="Garbage">Garbage</MenuItem>
                        <MenuItem value="Scrap">Scrap</MenuItem>
                        <MenuItem value="Debris">Debris</MenuItem>
                        <MenuItem value="Stock Pile">Stock Pile</MenuItem>
                        <MenuItem value="Steel Scrap">Steel Scrap</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                        {/* <MenuItem value="polygon">Polygon</MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Category :</p>{" "}
                      <p>{Category ? Category : "-"}</p>
                    </label>
                  )}
                </div>
                {markerData?.filter_type === "polygon" && (
                  <div className="flex items-center justify-center w-full">
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Perimeter :</p>{" "}
                      <p>
                        {markerData.shapeData.perimeter
                          ? parseFloat(markerData.shapeData.perimeter).toFixed(
                              2
                            ) + " m"
                          : "-"}
                      </p>
                    </label>
                  </div>
                )}
                {markerData?.filter_type === "polygon" && (
                  <div className="flex items-center justify-center w-full">
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Area :</p>{" "}
                      <p>
                        {markerData &&
                        markerData.shapeData &&
                        markerData.shapeData.area !== undefined ? (
                          // Data is available, format it to four decimal places
                          <span>
                            {markerData.shapeData.area
                              ? parseFloat(markerData.shapeData.area).toFixed(
                                  2
                                ) + " m²"
                              : "-"}
                          </span>
                        ) : (
                          // Data is not available, show "Calculating..."
                          <span>Calculating...</span>
                        )}
                      </p>
                    </label>
                  </div>
                )}
                {markerData?.filter_type === "polygon" && (
                  <div className="flex items-center justify-center w-full">
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Volume :</p>{" "}
                      <p>
                        {markerData && markerData.volume ? (
                          <span>
                            {parseFloat(markerData.volume).toFixed(2)} m³
                          </span>
                        ) : (
                          <span>-</span>
                        )}
                      </p>
                    </label>
                  </div>
                )}
                {markerData?.filter_type === "polyline" && (
                  <div className="flex items-center justify-center w-full">
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Length :</p>{" "}
                      <p>
                        {markerData &&
                        markerData.shapeData &&
                        markerData.shapeData.totalLengthofSegment !==
                          undefined ? (
                          // Data is available, format it to four decimal places
                          <span>
                            {markerData.shapeData.totalLengthofSegment
                              ? parseFloat(
                                  markerData.shapeData.totalLengthofSegment
                                ).toFixed(2) + " m"
                              : "-"}
                          </span>
                        ) : (
                          // Data is not available, show "Calculating..."
                          <span>Calculating...</span>
                        )}
                      </p>
                    </label>
                  </div>
                )}

                <div className="flex items-center justify-center w-full">
                  {isEditMode === false ? (
                    <>
                      <label className="w-[30%]">Severity :</label>
                      <Select
                        className="grow"
                        size="small"
                        name="severity"
                        id="demo-simple-select-error"
                        value={severity}
                        onChange={(e) => setSeverity(e.target.value)}
                      >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                        {/* <MenuItem value="Require Immediate Review">
                          Require Immediate Review
                        </MenuItem> */}
                      </Select>
                    </>
                  ) : (
                    <label className="flex items-center w-full">
                      <p className="w-[30%]">Severity :</p>{" "}
                      <p>{Severity ? Severity : "-"}</p>
                    </label>
                  )}
                </div>
                <div className="flex items-center justify-center w-full">
                  <label className="w-[30%]">Image :</label>
                  <div className="flex flex-col w-[70%] gap-2">
                    {images?.length > 0 ? (
                      <div>
                        <ImageSlider markerData={images} />
                      </div>
                    ) : (
                      <>{isEditMode && <p>No Images Available</p>}</>
                    )}

                    {!isEditMode && (
                      <div className="flex items-center justify-center w-full gap-2">
                        {!uploadedFile ? (
                          <>
                            <div
                              className="flex flex-col h-[30px] grow"
                              {...getRootProps()}
                              style={dropzoneStyle}
                            >
                              {/* <input {...getInputProps()} /> */}
                              <p className="m-auto text-xs">
                                Drag & Drop Image here or Click to upload
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            <TextField
                              name="textNote"
                              size="small"
                              value={uploadedFile.name}
                              className="grow"
                              inputProps={{ readOnly: true }}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="my-2 flex gap-2 items-center justify-center">
                {!isEditMode ? (
                  <>
                    <button
                      className="text-slate-700 bg-green-200 border hover:border-green-300 hover:bg-green-300 py-[5px] px-[18px] rounded  cursor-pointer my-2"
                      onClick={() => {
                        updateAnnotation(id, markerData.timestamp);
                        toast.success("Changes Saved Successfully!");
                        setIsEditMode(true);
                      }}
                    >
                      Save Changes
                    </button>
                    <button
                      className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded  cursor-pointer my-2"
                      onClick={resetData}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  markerData && (
                    <>
                      {markerData.filter_type === "polygon" &&
                        userPermissions.includes(
                          "annotation_polygon_delete"
                        ) && (
                          <button
                            className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded cursor-pointer my-2"
                            id="deleteButton"
                            onClick={() => {
                              onDeleteMarker(); // Call the delete callback
                              // refCounterUpdate();
                              dispatch(gisActions.refCounterUpdate());
                              onClose();
                            }}
                          >
                            Delete Marker
                          </button>
                        )}
                      {markerData.filter_type === "polyline" &&
                        userPermissions.includes(
                          "annotation_polyline_delete"
                        ) && (
                          <button
                            className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded cursor-pointer my-2"
                            id="deleteButton"
                            onClick={() => {
                              onDeleteMarker(); // Call the delete callback
                              // refCounterUpdate();
                              dispatch(gisActions.refCounterUpdate());
                              onClose();
                            }}
                          >
                            Delete Marker
                          </button>
                        )}
                      {markerData.filter_type === "marker" &&
                        userPermissions.includes(
                          "annotation_marker_delete"
                        ) && (
                          <button
                            className="text-slate-700 bg-red-200 border hover:border-red-300 hover:bg-red-300 py-[5px] px-[18px] rounded cursor-pointer my-2"
                            id="deleteButton"
                            onClick={() => {
                              onDeleteMarker(); // Call the delete callback
                              // refCounterUpdate();
                              dispatch(gisActions.refCounterUpdate());
                              onClose();
                            }}
                          >
                            Delete Marker
                          </button>
                        )}
                    </>
                  )
                )}
              </div>
            </>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <CommentBox id={id} />
          </CustomTabPanel>
        </div>
      </div>
    </Modal>
  );
};

export default MetaDataModal;
