import React, { useState, useEffect, useRef } from "react";
import GIS from "../../Components/GIS/GIS";
import GisSidebar from "../../Components/GIS/GisSidebar";
import { CircularProgress } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import PreviewIcon from "@mui/icons-material/Preview";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import ShapeLineRoundedIcon from "@mui/icons-material/ShapeLineRounded";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import { useNavigate, useParams } from "react-router";
import Annotations from "../../Components/GIS/Annotations";
import {
  agpLogo,
  kmdaLogo,
  inspectLogo,
  apsslrLogo,
} from "../../Assets/images/gisImg/images";
import "../../Assets/styles/GISStyle.css";
import ShapeFilesIndexing from "../../Components/GIS/ShapeFilesIndexing";
import { useSelector, useDispatch } from "react-redux";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import toast from "react-hot-toast";
import { tokenChecker } from "../../Utils/Cookie";
import BottomReportTable from "../../Components/GIS/BottomReportTable";
import CesiumAsset from "./CesiumAsset";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import Cookies from "js-cookie";

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNzhkZjc0ZS02YzQzLTRjMzAtODg3ZC0zOTk4MzFlMWRmNzgiLCJpZCI6MTU5MTU1LCJpYXQiOjE2OTIyODIwODV9._OhEfJPn8nLJ6LwuOSd7-Z3MB8MpQT_FwyxIOnWL2cI"; // as this is going to be stored on the aws as its env variables

const GISHome = () => {
  const user_permissions = Cookies.get("user_permissions")?.split(",");

  const viewerRef = useRef(null);
  // const [asset, setAsset] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(null);

  // const [compareAssets, setCompareAssets] = useState([]);
  const [showAssetsSelect, setShowAssetsSelect] = useState(false);
  const [openSidebar, setOpenSiderbar] = React.useState(false);
  const [openShapes, setOpenShapes] = React.useState(false);
  const [openReport, setOpenReport] = React.useState(false);
  const [annotation, setAnnotations] = React.useState(0);

  const navigate = useNavigate();
  const { grp } = useParams();

  // redux
  const dispatch = useDispatch();
  const asset = useSelector((state) => state.gisHome.asset);
  const compareAssets = useSelector((state) => state.gisHome.compareAssets);

  const { showComparer } = useSelector((state) => state.gisTools);
  const clientNameFromCookie = Cookies.get("clientName");

  React.useEffect(() => {
    tokenChecker(navigate);
  }, [navigate]);

  const handleSidebar = (status) => {
    setOpenSiderbar(status);
  };
  const handleReport = (status) => {
    setOpenReport(status);
  };

  const setLoader = (type) => {
    if (type) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (asset !== null && showComparer === true) {
      dispatch(gisHomeActions.setCompareAssets([...compareAssets, asset]));
    } else {
      // setCompareAssets([]);
      dispatch(gisHomeActions.setCompareAssets([]));
    }
  }, [showComparer]);

  const compareAssetsArray = (asset) => {
    if (compareAssets.includes(asset)) {
      const updatedCompareAssets = compareAssets.filter(
        (item) => item !== asset
      );
      dispatch(gisHomeActions.setCompareAssets(updatedCompareAssets));
    } else {
      if (compareAssets.length < 2) {
        const updatedCompareAssets = [...compareAssets, asset];
        dispatch(gisHomeActions.setCompareAssets(updatedCompareAssets));
      } else {
        toast.error("You can only compare two assets at a time.");
      }
    }
  };

  const setAnnotation = () => {
    setAnnotations(annotation + 1);
  };

  useEffect(() => {
    dispatch(gisHomeActions.setShapesIds([]));
    dispatch(gisActions.setUserPermissions(user_permissions));

    // dispatch(gisHomeActions.addShapeId(2408435));
  }, []);
  const [isAssetModalOpen, setIsAssetModalOpen] = useState(false);

  const handleCloseModalAsset = () => {
    setIsAssetModalOpen(false);
  };

  const handleOpenModalAsset = () => {
    setIsAssetModalOpen(true);
  };

  return (
    <div className="flex h-full w-full overflow-hidden relative ">
      <div
        id="GISSidebarBody"
        className="flex flex-row  absolute top-0 left-0  h-full bg-white z-50 rounded-tr-lg "
      >
        <div className="flex flex-row  relative top-0 left-0  h-full bg-white rounded-tr-lg  ">
          <div className="flex flex-col gap-2 p-2  w-[4vw] ">
            <div
              className="flex items-center justify-center rounded-[5px] cursor-pointer mb-2"
              onClick={() =>
                grp === "PIL" || grp === "pil"
                  ? navigate("/pil")
                  : navigate("/projects")
              }
            >
              <Tooltip title="Go to Home" arrow placement="bottom">
                <img src={inspectLogo} className="w-[52px]" alt="logo" />
              </Tooltip>
            </div>
            <Tooltip title="Timelines" placement="bottom" arrow>
              <div
                onClick={() => {
                  handleSidebar(!openSidebar);
                }}
                className={`bg-[#f2f3f3] ${
                  openSidebar ? "border border-gray-500 border-dotted" : ""
                } flex p-2 items-center justify-center rounded-[5px] cursor-pointer`}
              >
                <span>
                  <FormatListBulletedRoundedIcon style={{ color: "#4a4844" }} />
                </span>
              </div>
            </Tooltip>
            {grp !== "ESSEL" && clientNameFromCookie !== "skyhigh" && (
              <Tooltip title="Observations" placement="bottom" arrow>
                <div
                  onClick={() => {
                    handleReport(!openReport);
                  }}
                  className={`bg-[#f2f3f3] ${
                    openReport ? "border border-gray-500 border-dotted" : ""
                  } flex p-2 items-center justify-center rounded-[5px] cursor-pointer`}
                >
                  <span>
                    <PreviewIcon style={{ color: "#4a4844" }} />
                  </span>
                </div>
              </Tooltip>
            )}
            {isAssetModalOpen && (
              <CesiumAsset
                isAssetModalOpen={isAssetModalOpen}
                onClose={handleCloseModalAsset}
              />
            )}
            {/* {asset !== null &&
              showComparer === false &&
              grp !== "ESSEL" && ( //showAssetsSelect
                <Tooltip title="Shapes" placement="bottom" arrow>
                  <div
                    onClick={() => {
                      // handleSidebar(!openSidebar);
                      setOpenShapes(!openShapes);
                    }}
                    className={`bg-[#f2f3f3] ${
                      openShapes ? "border border-gray-500 border-dotted " : ""
                    } flex p-2 items-center justify-center rounded-[5px] cursor-pointer`}
                  >
                    <span>
                      <ShapeLineRoundedIcon style={{ color: "#4a4844" }} />
                    </span>
                  </div>
                </Tooltip>
              )} */}
            {asset !== null &&
              showComparer === false &&
              grp !== "ESSEL" &&
              clientNameFromCookie !== "skyhigh" && ( //showAssetsSelect
                <Tooltip title="Upload Timelines" placement="bottom" arrow>
                  <div
                    onClick={() => {
                      // handleSidebar(!openSidebar);
                      // setOpenShapes(!openShapes);
                      handleOpenModalAsset();
                    }}
                    className={`bg-[#f2f3f3] ${
                      openShapes ? "border border-gray-500 border-dotted " : ""
                    } flex p-2 items-center justify-center rounded-[5px] cursor-pointer`}
                  >
                    <span>
                      <FileUploadOutlinedIcon style={{ color: "#4a4844" }} />
                    </span>
                  </div>
                </Tooltip>
              )}
            <Tooltip title="Back" placement="bottom" arrow>
              <div
                className=" absolute bottom-5 left-4 hover:left-2 hover:bg-grey-200 p-1 hover:rounded-full"
                onClick={() => navigate("/projects")}
              >
                <ArrowBackIosRoundedIcon style={{ color: "#4a4844" }} />
              </div>
            </Tooltip>
          </div>
          <div className="flex flex-col  h-[100vh]  rounded-lg focus:border focus:border-b-2">
            <GisSidebar
              token={token}
              grp={grp}
              compareAssetsArray={compareAssetsArray}
              openSidebar={openSidebar}
            />
            <Annotations
              // assetId={asset}
              // annotations={annotation}
              viewer={viewerRef.current}
              openReport={openReport}
            />
            {/* {showComparer === false && (
              <ShapeFilesIndexing
                token={token}
                grp={grp}
                compareAssetsArray={compareAssetsArray}
                openSidebar={openShapes}
              />
            )} */}
          </div>
        </div>
        {clientNameFromCookie !== "skyhigh" && (
          <BottomReportTable
            isSidebaropen={openSidebar}
            openShapes={openShapes}
            viewer={viewerRef.current}
          />
        )}
      </div>

      <GIS
        token={token}
        grp={grp}
        setLoader={setLoader}
        setShowAssetsSelect={setShowAssetsSelect}
        viewerRef={viewerRef}
        setAnnotation={setAnnotation}
      />

      <div className="absolute bottom-1 text-xs flex justify-center items-center w-full z-8 text-white">
        <span>
          &#169; 2024 Indrones Solutions Pvt Ltd. All Rights Reserved.
        </span>
      </div>
      {isLoading && (
        <div className="absolute top-[50%] left-[50%]">
          <CircularProgress color="inherit" size={60} />
        </div>
      )}
    </div>
  );
};

export default GISHome;
