import { useState, useEffect, useRef } from "react";
import MetaInput from "./MetaInput";
import {
  Viewer,
  Ion,
  IonImageryProvider,
  CzmlDataSource,
  ScreenSpaceEventType,
  defined,
  Math as MathOfCesium,
  ColorMaterialProperty,
  Color,
  CesiumTerrainProvider,
  Cesium3DTileset,
  HeadingPitchRange,
  Cartesian3,
  HorizontalOrigin,
  VerticalOrigin,
  Cartesian2,
  BoundingSphere,
  ScreenSpaceEventHandler,
  Cartographic,
  NearFarScalar,
  PolylineOutlineMaterialProperty,
  EntityCollection,
  Entity,
  GridMaterialProperty,
  WebMapServiceImageryProvider,
} from "cesium";
import MetaDataModal from "./MetaDataModal";
import VodBox from "./VodBox";
import VideoModal from "./VideoModal";
import {
  chevronIco,
  customBillboardImg,
  debrisBillboardImg,
  garbageBillboardImg,
  scrapBillboardImg,
} from "../../Assets/icons/gisIcons/icons";
import { v4 as uuidv4 } from "uuid";
import { formatDate } from "../../Features/GIS/Utils/otherUtils";
import GISToolbar from "./GISToolbar";
import { getDefaultPoints } from "../../Features/GIS/Utils/DefaultPoints";
import { useCompareAssets } from "../../Features/GIS/hooks/useCompareAssets";
import { useGeoJsonOnMap } from "../../Features/GIS/hooks/useGeoJsonOnMap";
import { useCzml } from "../../Features/GIS/hooks/useCzml";
import { handleHomeButtonClick } from "../../Features/GIS/Utils/GoHome";
import { usePolygonAnnotationTool } from "../../Features/GIS/hooks/usePolygonAnnotation";
import { usePolylineAnnotationTool } from "../../Features/GIS/hooks/usePolylineAnnotation";
import PolygonAnnotationModal from "./PolygonAnnotationModal";
import {
  AnnotationManager,
  DynamoDBApis,
} from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { useSelector, useDispatch } from "react-redux";
import { gisToolActions } from "../../redux/slices/GIS/gis-tools-slice";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import { getDate } from "../../Utils/OtherUtils";
import { S3Apis } from "../../Services/ThirdPartyApi/AWS/S3";
import PolylineAnnotationModal from "./PolylineAnnotationModal";
import { usePolylineMeasureTool } from "../../Features/GIS/hooks/usePolylineMeasureTool";
import { usePolygonMeasureTool } from "../../Features/GIS/hooks/usePolygonMeasureTool";
import { useLineMeasureTool } from "../../Features/GIS/hooks/useLineMeasureTool";
import { usePointMarkerAnnotation } from "../../Features/GIS/hooks/usePointMarkerAnnotation";
// import { CSStationsList, PILDates } from "../../Data/config/pilData";
import GISUtils from "../../Utils/GISUtils";
import axios from "axios";
import { getUserEmail } from "../../Utils/Cookie";
import Cookies from "js-cookie";

const getTimestamp = (idToFind, arrayOfObjects, timestamp) => {
  const resultObject = arrayOfObjects.find((obj) => obj.id === idToFind);
  if (timestamp) {
    return resultObject?.timestamp;
  } else {
    return resultObject;
  }
};

const findAssetNameById = (assetId, data) => {
  const foundAsset = data.find((item) => item.id === assetId);

  return foundAsset ? getDate(foundAsset.date) : null;
};

const newTimestamp = Date.now();

const GIS = ({ token, setLoader, viewerRef, grp, setAnnotation }) => {
  Ion.defaultAccessToken = token;
  const cookieValue = Cookies.get("clientName");

  const [selectedPosition, setSelectedPosition] = useState(null);
  const [isModalOpenCustom, setIsModalOpenCustom] = useState(false);
  const [isMetaModalOpen, setIsMetaModalOpen] = useState(false);
  const [vodOpen, setVodOpen] = useState(false);
  const [hlsOpen, setHlsOpen] = useState(false);
  const [source, setSource] = useState(null);
  const [isLiveSrc, setIsLiveSrc] = useState(false);
  const [liveSource, setLiveSource] = useState(null);
  // const [zoomLevel, setZoomLevel] = useState(false);
  const [goHome, setGoHome] = useState(false);
  const [imageryLayer, setImageryLayer] = useState(null);
  const handleVodOpen = () => setVodOpen(true);
  const handleVodClose = () => setVodOpen(false);
  const handleHlsClose = () => setHlsOpen(false);
  const handleOpen = () => setIsModalOpenCustom(true);
  const metaOpen = () => setIsMetaModalOpen(true);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [toolCategory, setToolCategory] = useState("");
  const [shapeCord, setShapeCord] = useState([]);
  const [shapeCordLatLan, setShapeCordLatLan] = useState([]);
  const [shapeData, setShapeData] = useState({});

  const [allMarkersArray, setAllMarkersArray] = useState([]);

  const [openPolygonAnnotationModal, setOpenPolygonAnnotationModal] =
    useState(false);
  const [openPolylineAnnotationModal, setOpenPolylineAnnotationModal] =
    useState(false);
  const [promptFor, setPromptFor] = useState("");
  const [imageryLabel, setImageryLabel] = useState("2D");

  // redux
  const dispatch = useDispatch();
  const asset = useSelector((state) => state.gisHome.asset);
  const tileCategory = useSelector((state) => state.gisHome.assetType);
  const compareAssets = useSelector((state) => state.gisHome.compareAssets);
  const grpAssets = useSelector((state) => state.gisHome.grpData);
  const { userPermissions } = useSelector((state) => state.gis);

  const [mousePosition, setMousePosition] = useState({ lat: 0, lng: 0 });

  const findTimeline = (assetId) => {
    const filteredAssets = grpAssets.filter((asset) => asset.id === assetId);
    if (filteredAssets.length > 0) {
      return getDate(filteredAssets[0].date);
    }
    return null;
  };

  const {
    showComparer,
    annotationTool,
    measureToolToggle,
    polygonAnnotation,
    polylineAnnotation,
    polygonMeasureTool,
    polylineMeasureTool,
    lineMeasureTool,
  } = useSelector((state) => state.gisTools);

  const {
    markerCategory,
    markerArray,
    refCounter,
    zoomLevel,
    polygonAnnotationsData,
    polylineAnnotationsData,
    allObservations,
  } = useSelector((state) => state.gis);

  const clientNameFromCookie = Cookies.get("clientName");

  const defaultCzml = [...getDefaultPoints(grp)];

  const handlePolygonAnnotationModal = () => {
    if (polygonAnnotation === true) {
      setOpenPolygonAnnotationModal(!openPolygonAnnotationModal);
    }
  };

  const handlePolylineAnnotationModal = () => {
    if (polylineAnnotation === true) {
      setOpenPolylineAnnotationModal(!openPolylineAnnotationModal);
    }
  };

  const handleZoomOnChange = () => {
    const viewer = viewerRef.current;
    const camera = viewer.camera;
    viewer.camera.defaultZoomAmount = 0.001;

    camera.zoomOut();
  };

  //zoom control function
  const handleZoom = (direction) => {
    const viewer = viewerRef.current;
    const camera = viewer.camera;
    const maxZoomHeight = 100000;
    const minZoomHeight = 2;
    const zoomStep = 100;

    if (viewer) {
      const currentHeight = camera.positionCartographic.height;
      // console.log(currentHeight, "currentHeight");
      let destinationHeight;

      if (direction === "in" && currentHeight > minZoomHeight) {
        destinationHeight = Math.max(currentHeight - zoomStep, minZoomHeight);
      } else if (direction === "out" && currentHeight < maxZoomHeight) {
        destinationHeight = Math.min(currentHeight + zoomStep, maxZoomHeight);
      }

      if (destinationHeight !== undefined) {
        viewer.camera.flyTo({
          destination: Cartesian3.fromRadians(
            camera.positionCartographic.longitude,
            camera.positionCartographic.latitude,
            destinationHeight
          ),
          duration: 1.0,
        });
      }
    }
  };

  const handleHlsOpen = (vodLink) => {
    setIsLiveSrc(false);
    setLiveSource(null);
    setSource(vodLink);
    setHlsOpen(true);
  };

  const handleGoLive = (liveSource) => {
    setIsLiveSrc(true);
    setSource(null);
    setLiveSource(liveSource);
    setHlsOpen(true);
  };

  const handleMarkerSave = async (
    description,
    keyValuePairs,
    category,
    severity,
    markerName,
    assetId,
    typeOfMarker,
    file,
    date
  ) => {
    if (selectedPosition) {
      const { longitude, latitude } = selectedPosition;
      var imagedata;
      if (file) {
        const imageData = await S3Apis.uploadData(file, "images");
        imagedata = [{ path: imageData.s3Url.key, date: imageData.Imagedate }];
      }

      if (typeOfMarker === "Custom") {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          longitude: longitude,
          latitude: latitude,
          description: description,
          keyValuePairs: keyValuePairs,
          category: "Other",
          severity: severity,
          markerName: markerName,
          assetId: assetId,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: grp,
          filter_type: "marker",
          visible: true,
        };

        AnnotationManager.addAnnotations(newMarker);
        if (severity === "High") {
          const dataForMail = {
            recipients: [getUserEmail()],
            dataJson: {
              name: markerName,
              description,
              category,
              date: findAssetNameById(assetId, grpAssets),
            },
          };

          const responses = await axios.post(
            "http://192.168.0.104:8001/api/v1/email/notify",
            dataForMail
          );

          // console.log("mmmmm", responses);
        }
        // dispatch(gisActions.setMarkerArray(newMarker));
      } else {
        const newMarker = {
          id: uuidv4(),
          timestamp: newTimestamp,
          longitude: longitude,
          latitude: latitude,
          description: description,
          keyValuePairs: keyValuePairs,
          category: typeOfMarker,
          severity: severity,
          markerName: markerName,
          assetId: assetId,
          markerDate: date,
          createdOn: formatDate(newTimestamp),
          images: imagedata,
          groupName: grp,
          filter_type: "marker",
          visible: true,
        };
        AnnotationManager.addAnnotations(newMarker);
        if (severity === "High") {
          const dataForMail = {
            recipients: [getUserEmail()],
            dataJson: {
              name: markerName,
              description,
              category,
              date: findAssetNameById(assetId, grpAssets),
            },
          };

          const responses = await axios.post(
            "http://192.168.0.104:8001/api/v1/email/notify",
            dataForMail
          );

          // console.log("mmmmm", responses);
        }
        // dispatch(gisActions.setMarkerArray(newMarker));
      }
    }
    setSelectedPosition(null);
  };

  const handleDeleteMarker = (index, selectedMarkerID) => {
    const updatedMarkers = [...markerArray];
    updatedMarkers.splice(index, 1);
    dispatch(gisActions.setMarkerArray(updatedMarkers));
    const timestamp = getTimestamp(selectedMarkerID, allMarkersArray, true);
    AnnotationManager.deleteAnnotations(selectedMarkerID, timestamp);
    const viewer = viewerRef.current;
    viewer.entities.removeById(selectedMarkerID);
    // handleZoomOnChange();
  };

  // cesiumContainer initilization
  useEffect(() => {
    try {
      Ion.defaultAccessToken = token;

      // If the viewer doesn't exist, create one
      if (!viewerRef.current) {
        viewerRef.current = new Viewer("cesiumContainer", {
          // viewer defaulf parameters
          requestRenderMode: true,
          maximumRenderTimeChange: Infinity,
          baseLayerPicker: true,
          timeline: false,
          homeButton: false,
          sceneModePicker: false,
          navigationHelpButton: false,
          fullscreenButton: false,
          animation: false,
          creditContainer: null,
        });
      }

      const viewer = viewerRef.current;
      var customBaseLayerPicker = document.createElement("div");
      customBaseLayerPicker.className = "cesium-baseLayerPicker";
      viewer.container.appendChild(customBaseLayerPicker);
    } catch (error) {}
  });

  let flyToFlag = 0;

  useEffect(() => {
    const initCesium = async () => {
      try {
        const viewer = viewerRef.current;
        // const baseLayer = viewer.imageryLayers.get(0);
        // viewer.imageryLayers.removeAll();
        // var cameraHeight = viewer.camera.positionCartographic.height; // This gets the camera's height in meters
        // // ////console.log(cameraHeight, "camera Height");
        // // baseLayer.alpha = 0.5;

        // viewer.imageryLayers.addImageryProvider(baseLayer.imageryProvider);
        // to disable pan and tilt

        const baseLayer =
          viewer.imageryLayers.length > 0 ? viewer.imageryLayers.get(0) : null;

        if (baseLayer) {
          // Add imagery layer from the selected Ion asset

          if (tileCategory === "IMAGERY" || tileCategory === "GEOSERVER") {
            viewer.imageryLayers.removeAll();
            const existingTileset = findTilesetInPrimitives(
              viewer?.scene.primitives
            );
            if (existingTileset) {
              viewer.scene.primitives.remove(existingTileset);
            }
            viewer.imageryLayers.addImageryProvider(baseLayer.imageryProvider);
            viewer.scene.screenSpaceCameraController.enableTilt = false;
            viewer.scene.screenSpaceCameraController.enableTranslate = false;
            if (clientNameFromCookie === "skyhigh") {
              var imageryProvider = new WebMapServiceImageryProvider({
                url: "https://gis.indrones.com/geoserver/skyhigh/wms",
                layers: asset,

                parameters: {
                  transparent: "true",
                  format: "image/png",
                },
              });
              // console.log(asset);

              const imageryLayer =
                viewer.imageryLayers.addImageryProvider(imageryProvider);

              var latitude = 15.63578;
              var longitude = 73.87924;
              var height = 2000;

              // Fly to the location
              viewer.camera.flyTo({
                destination: Cartesian3.fromDegrees(
                  longitude,
                  latitude,
                  height
                ),
                orientation: {
                  heading: MathOfCesium.toRadians(0),
                  pitch: MathOfCesium.toRadians(-90),
                  roll: 0.0,
                },
                duration: 3, // Optional: Duration of the flight animation in seconds
              });
              setTimeout(() => {
                setLoader(false);
              }, 1000);
            }
            // Disable camera tilt and pan

            setImageryLabel("2D");

            imageryLayer.saturation = 1.3;
            imageryLayer.brightness = 1.1;

            setImageryLayer(imageryLayer);

            if (
              !lineMeasureTool &&
              !polylineMeasureTool &&
              !polygonMeasureTool &&
              !polygonAnnotation &&
              flyToFlag === 0
            ) {
              await viewer.flyTo(imageryLayer);

              // flyToFlag.current = flyToFlag.current + 1;
              flyToFlag++;
            }
          } else {
            if (clientNameFromCookie === "skyhigh") {
              viewer.scene.screenSpaceCameraController.enableTilt = true;
              viewer.scene.screenSpaceCameraController.enableTranslate = true;
              const terrainProvider =
                await CesiumTerrainProvider.fromIonAssetId(1);
              const tileset = await Cesium3DTileset.fromIonAssetId(asset);
              viewer.terrainProvider = terrainProvider;
              viewer.scene?.primitives.add(tileset);
              setImageryLabel("3D");

              const destination = Cartesian3.fromDegrees(
                73.87908,
                15.63318,
                500
              );
              const options = {
                destination: destination,
                orientation: {
                  heading: MathOfCesium.toRadians(0.0), // Heading in radians (east)
                  pitch: MathOfCesium.toRadians(-60.0), // Pitch in radians (look straight down)
                  roll: 0.0, // Roll in radians
                },
              };

              viewer.camera.flyTo(options);
            } else {
              viewer.scene.screenSpaceCameraController.enableTilt = true;
              viewer.scene.screenSpaceCameraController.enableTranslate = true;
              const terrainProvider =
                await CesiumTerrainProvider.fromIonAssetId(1);
              const tileset = await Cesium3DTileset.fromIonAssetId(asset);
              viewer.terrainProvider = terrainProvider;
              viewer.scene?.primitives.add(tileset);
              setImageryLabel("3D");

              // Wait for both the tileset and terrain provider to be ready
              Promise.all([
                tileset.readyPromise,
                terrainProvider.readyPromise,
              ]).then(() => {
                const boundingSphere = tileset.boundingSphere;

                let offset = new HeadingPitchRange(
                  0.0, // heading to create a slight side view
                  -MathOfCesium.PI_OVER_TWO, // Look straight down (top view)
                  boundingSphere.radius * 0.05
                );

                viewer.camera.flyToBoundingSphere(boundingSphere, {
                  offset: offset,
                  complete: () => {
                    const screenCenterX = viewer.canvas.clientWidth / 2;
                    const screenCenterY = viewer.canvas.clientHeight / 2;

                    const assetPosition =
                      viewer.scene.cartesianToCanvasCoordinates(
                        boundingSphere.center
                      );

                    const deltaX = screenCenterX - assetPosition.x + 5000;
                    const deltaY = screenCenterY - assetPosition.y + 6000;

                    const adjustedPosition = viewer.camera.position.clone();
                    adjustedPosition.x += deltaX;
                    adjustedPosition.y += deltaY;

                    viewer.camera.position = adjustedPosition;
                  },
                });
              });
            }
          }

          setTimeout(() => {
            setLoader(false);
          }, 1000);
        } else {
          console.error("No imagery layer found.");
          setLoader(false); // Adjust this as needed based on your requirements.
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const findTilesetInPrimitives = (primitives) => {
      for (let i = 0; i < primitives.length; i++) {
        const primitive = primitives.get(i);
        if (primitive instanceof Cesium3DTileset) {
          return primitive;
        }
      }
      return null;
    };

    if (asset) {
      setLoader(true);
      setTimeout(() => {
        initCesium();
      }, 2000);
    } else if (asset === null && imageryLayer) {
      var imageryLayers = viewerRef.current.imageryLayers;
      imageryLayers.remove(imageryLayer);
    }
    // showComparer && dispatch(gisHomeActions.toggleShowComparer(showComparer));
    // setShowCompare(false);
    // setShowAssetsSelect(false);
    showComparer && dispatch(gisToolActions.toggleTools("showComparer"));
  }, [asset]);

  // show shape
  useGeoJsonOnMap(viewerRef);

  useEffect(() => {
    // effect for showing the lat and lng of cursor positionq
    function locationToolTip() {
      const viewer = viewerRef.current;
      let handler;

      const entity = viewer.entities.add({
        label: {
          show: false,
          showBackground: true,
          font: "14px monospace",
          horizontalOrigin: HorizontalOrigin.LEFT,
          verticalOrigin: VerticalOrigin.TOP,
          pixelOffset: new Cartesian2(15, 0),
          translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
        },
      });

      // Mouse over the globe to see the cartographic position
      handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(function (movement) {
        const cartesian = viewer.camera.pickEllipsoid(
          movement.endPosition,
          viewer.scene.globe.ellipsoid
        );

        if (cartesian) {
          const cartographic = Cartographic.fromCartesian(cartesian);
          const longitudeString = MathOfCesium.toDegrees(
            cartographic.longitude
          ).toFixed(5);
          const latitudeString = MathOfCesium.toDegrees(
            cartographic.latitude
          ).toFixed(5);

          setMousePosition({ lat: latitudeString, lng: longitudeString });
        } else {
          entity.label.show = false;
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
    }
    locationToolTip();
  });

  useEffect(() => {
    const viewer = viewerRef.current;
    viewer.screenSpaceEventHandler.setInputAction((click) => {
      const pickedObject = viewer.scene.pick(click.position);

      if (defined(pickedObject) && pickedObject.id) {
        // Open modal and set selected marker data
        setSelectedMarker({
          id: pickedObject.id.id,
          name: pickedObject.id.name,
          timestamp: pickedObject.id.timestamp,
          description: pickedObject?.id?.description?.getValue(),
          category: pickedObject.id.category,
          mainId: pickedObject.id,
        });

        if (pickedObject.id.id?.includes("default_")) {
          setSelectedMarker({});
          setSelectedMarker({
            id: pickedObject.id.id,
            name: pickedObject.id.name,
            description: JSON.parse(pickedObject?.id?.description?.getValue()),
            grpID: `${pickedObject.id.id}_${grp}`,
          });
          handleVodOpen();
        } else if (
          pickedObject.id.name?.includes("Boundary") ||
          pickedObject.id.name?.includes("Main Road")
        ) {
          setSelectedMarker({});
          setSelectedMarker({
            id: pickedObject.id.id,
            name: pickedObject.id.name,
            description: "videoMarkers",
            grpID: `${pickedObject.id.id}_${grp}`,
          });
          handleVodOpen();
        } else {
          if (
            measureToolToggle === false &&
            polygonAnnotation === false &&
            !pickedObject.id.id?.includes("default_")
          ) {
            metaOpen();
          } else {
            polygonAnnotation && metaOpen();
          }
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK);
  });

  // show shape
  useGeoJsonOnMap(viewerRef);
  // Attach right-click event handler when enable or viewerRef changes
  const fetchData = async () => {
    try {
      const retrievedData = await DynamoDBApis.getDataByID(asset);

      // setMarkerArray(retrievedData);
      // dispatch(gisActions.setMarkerArray(retrievedData));
      dispatch(gisActions.setAllObservations(retrievedData));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [asset, refCounter]);
  // console.log(asset, "asset");

  // useCzml(viewerRef, grp);

  useCompareAssets(viewerRef);

  useEffect(() => {
    setAnnotation();
  }, [markerArray]);

  useEffect(() => {
    const viewer = viewerRef.current;
    const getAnno = async () => {
      // setGetPolygonData([]);
      dispatch(gisActions.setPolygonAnnotationsData([]));
      dispatch(gisActions.setMarkerArray([]));
      // const response = await DynamoDBApis.getDataByID(asset);
      // console.log(response, "from the another");

      // dispatch(gisActions.setAllObservations(response));

      const polygonObjects = userPermissions.includes("annotation_polygon_view")
        ? allObservations?.filter((item) => item?.filter_type === "polygon")
        : [];

      const polylineObjects = userPermissions.includes(
        "annotation_polyline_view"
      )
        ? allObservations?.filter((item) => item?.filter_type === "polyline")
        : [];

      const markerObjects = userPermissions.includes("annotation_marker_view")
        ? allObservations?.filter(
            (item) =>
              item?.filter_type !== "polygon" &&
              item?.filter_type !== "polyline"
          )
        : [];

      // console.log(markerObjects, "markerObjects");
      setAllMarkersArray(allObservations);
      dispatch(gisActions.setMarkerArray(markerObjects));

      dispatch(gisActions.setPolygonAnnotationsData(polygonObjects));
      dispatch(gisActions.setPolylineAnnotationsData(polylineObjects));

      if (defaultCzml.length > 0) {
        const dataSourcePromise = CzmlDataSource.load(defaultCzml);
        viewer.dataSources.add(dataSourcePromise);
      }
    };

    getAnno();
  }, [allObservations]);

  useEffect(() => {
    const viewer = viewerRef.current;
    const PolygonEntityCollection = new EntityCollection();
    // console.log(PolygonEntityCollection, "PolygonEntityCollection");
    handleZoomOnChange();
    viewer?.entities.removeAll();

    polygonAnnotationsData?.forEach((poly) => {
      // console.log(poly, "poly");
      if (poly.shapeCord && poly.shapeCord.length >= 3) {
        var materialColor, outline;
        if (poly.severity === "High") {
          materialColor = Color.RED.withAlpha(0.3);
          outline = Color.RED.withAlpha(0.9);
        } else if (poly.severity === "Medium") {
          materialColor = Color.ORANGE.withAlpha(0.3);
          outline = Color.ORANGE.withAlpha(0.9);
        } else if (poly.severity === "Low") {
          materialColor = Color.YELLOW.withAlpha(0.3);
          outline = Color.YELLOW.withAlpha(0.9);
        } else {
          materialColor = Color.PURPLE.withAlpha(0.3);
          outline = Color.PURPLE.withAlpha(0.3);
        }

        const materialSelector = () => {
          if (poly.assetId !== asset) {
            return new GridMaterialProperty({
              color: outline,
              cellAlpha: 0.3,
              lineCount: new Cartesian2(12, 12),
              lineThickness: new Cartesian2(2.0, 2.0),
            });
          } else {
            return new ColorMaterialProperty(materialColor);
          }
        };

        const positions = poly.shapeCord.map(
          (coord) => new Cartesian3(coord.x, coord.y, coord.z)
        );

        const center = BoundingSphere.fromPoints(positions).center;

        const shape = new Entity({
          id: poly.id,
          name: poly.markerName,
          show: poly?.visible,
          clampToGround: true,

          polygon: {
            hierarchy: positions,
            material: materialColor,
            translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
          },
          polyline: {
            positions: positions.concat(positions[0]),
            width: 2,
            material: outline,
            granularity: 0.01,
            translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
          },

          label: {
            text: `${poly.severity} Risk`,
            fillColor: Color.WHITE,
            showBackground: true,
            font: "14px sans-serif",
            horizontalOrigin: HorizontalOrigin.CENTER,
            verticalOrigin: VerticalOrigin.CENTER,
            // pixelOffset: new Cartesian2(0, -10), // Adjust the offset as needed
            background: new ColorMaterialProperty(Color.BLACK.withAlpha(0.5)), // Adjust the background color and alpha
            backgroundPadding: new Cartesian2(8, 4),
            translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
          },
          position: center,
        });

        // console.log(shape, "shape from gis");
        if (viewer.entities.getById(poly?.id) === undefined) {
          PolygonEntityCollection.removeAll();
          PolygonEntityCollection.add(shape);
          viewer.entities.add(shape);
        } else {
          if (viewer.entities.getById(poly.id).show !== poly?.show) {
            let latestPoly = viewer.entities?.getById(poly.id);
            latestPoly.show = poly.visible;
          }
        }
      } else {
        console.error(
          `Polygon with id ${poly.id} does not have enough vertices.`
        );
      }
    });
  }, [polygonAnnotationsData]);

  //   useEffect(() => {
  //     const viewer = viewerRef.current;
  // const PolygonEntityCollection = new EntityCollection();
  //     // console.log(PolygonEntityCollection, "PolygonEntityCollection");
  //     handleZoomOnChange();
  //     viewer?.entities.removeAll();

  //     polygonAnnotationsData?.forEach((poly) => {
  // console.log(poly, "poly");
  //       if (poly.shapeCord && poly.shapeCord.length >= 3) {
  //         var materialColor, outline;
  //         if (poly.category === "Stock Pile") {
  //           materialColor = Color.ORANGE.withAlpha(0.3);
  //           outline = Color.ORANGE.withAlpha(0.9);
  //         } else {
  //           materialColor = Color.PURPLE.withAlpha(0.3);
  //           outline = Color.PURPLE.withAlpha(0.3);
  //         }

  //         const positions = poly.shapeCord.map(
  //           (coord) => new Cartesian3(coord.x, coord.y, coord.z)
  //         );

  //         const shape = viewer.entities.add({
  //           id: poly.id,
  //           name: poly.markerName,
  //           polygon: {
  //             hierarchy: positions,
  //             material: new ColorMaterialProperty(materialColor),
  //           },
  //         });

  //         const edges = viewer.entities.add({
  //           id: `${poly.id}_edges`,
  //           polyline: {
  //             positions: positions.concat(positions[0]),
  //             width: 2,
  //             material: new ColorMaterialProperty(outline),
  //             granularity: 0.01,
  //           },
  //         });
  //       } else {
  //         console.error(
  //           `Polygon with id ${poly.id} does not have enough vertices.`
  //         );
  //       }
  //     });
  //   }, [polygonAnnotationsData]);

  useEffect(() => {
    var shape;
    const viewer = viewerRef.current;
    const PolylineEntityCollection = new EntityCollection();
    // viewer?.entities.removeAll();

    polylineAnnotationsData?.map((poly) => {
      console.table(poly, "poly");
      var materialColor, outline;
      if (poly.markerName === "Boundary" || poly.markerName === "Main Road") {
        materialColor = Color.BROWN.withAlpha(0.4);
        outline = Color.BROWN.withAlpha(0.1);
      } else if (poly.severity === "High") {
        materialColor = Color.RED.withAlpha(0.9);
        outline = Color.RED.withAlpha(0.9);
      } else if (poly.severity === "Medium") {
        materialColor = Color.ORANGE.withAlpha(0.9);
        outline = Color.ORANGE.withAlpha(0.9);
      } else if (poly.severity === "Low") {
        materialColor = Color.YELLOW.withAlpha(0.9);
        outline = Color.YELLOW.withAlpha(0.9);
      } else {
        materialColor = Color.PURPLE.withAlpha(0.9);
        outline = Color.PURPLE.withAlpha(0.3);
      }
      shape = new Entity({
        id: poly.id,
        name: poly.markerName,
        show: poly?.visible,
        polyline: {
          positions: poly.shapeCord,
          width: 4,
          material: new PolylineOutlineMaterialProperty({
            color: materialColor,
            outlineColor: materialColor,
            outlineWidth: 2,
          }),
          clampToGround: true,
        },
        label:
          poly.markerName === "Boundary" || poly.markerName === "Main Road"
            ? null
            : {
                text: `${poly.severity} Risk`,
                fillColor: Color.WHITE,
                showBackground: true,
                font: "14px sans-serif",
                horizontalOrigin: HorizontalOrigin.CENTER,
                verticalOrigin: VerticalOrigin.CENTER,
                pixelOffset: new Cartesian2(0, -10), // Adjust the offset as needed
                background: new ColorMaterialProperty(
                  Color.BLACK.withAlpha(0.5)
                ), // Adjust the background color and alpha
                backgroundPadding: new Cartesian2(8, 4),
                translucencyByDistance: new NearFarScalar(
                  3.5e2,
                  1,
                  30.0e2,
                  0.0
                ),
              },

        position: poly.shapeCord[0],
      });

      if (viewer.entities.getById(poly?.id) === undefined) {
        PolylineEntityCollection.removeAll();
        PolylineEntityCollection.add(shape);
        viewer.entities.add(shape);
      } else {
        if (viewer.entities.getById(poly.id).show !== poly?.show) {
          let latestPoly = viewer.entities?.getById(poly.id);
          latestPoly.show = poly.visible;
        }
      }
    });
  }, [polylineAnnotationsData]);

  useEffect(() => {
    const viewer = viewerRef.current;
    // viewer?.entities.removeAll();
    const MarkerEntityCollection = new EntityCollection();

    // console.log(markerArray, "markerArray");

    markerArray?.forEach((marker) => {
      // console.log(marker, "marker");
      // if (marker.visible) {
      // const markerImg = (marker) => {
      //   let materialColor, icon;
      //   if (marker.severity === "High" && marker.typeName === "Tree") {
      //     materialColor = Color.RED.withAlpha(0.3);
      //     icon = treeIconHigh;
      //   } else if (
      //     marker.severity === "Medium" &&
      //     marker.typeName === "Building"
      //   ) {
      //     materialColor = Color.ORANGE.withAlpha(0.3);
      //     icon = buildingIconHigh;
      //   } else if (marker.severity === "Medium") {
      //     materialColor = Color.ORANGE.withAlpha(0.3);
      //     icon = buildingIconMedium;
      //   } else if (marker.severity === "Low") {
      //     materialColor = Color.YELLOW.withAlpha(0.3);
      //     icon = buildingIconLow;
      //   } else {
      //     materialColor = Color.PURPLE.withAlpha(0.3);
      //     icon = customBillboardImg;
      //   }
      //   return { materialColor, icon };
      // };
      const markerEntity = new Entity({
        id: marker.id,
        name: marker.markerName,
        show: marker?.visible,
        clampToGround: true,
        position: Cartesian3.fromDegrees(marker?.longitude, marker?.latitude),
        billboard: {
          image:
            marker.category === "Garbage"
              ? garbageBillboardImg
              : marker.category === "Debris"
              ? debrisBillboardImg
              : marker.category === "Scrap"
              ? scrapBillboardImg
              : marker.category === "Other"
              ? customBillboardImg
              : undefined,
          verticalOrigin: VerticalOrigin.BOTTOM,
          width: 40,
          height: 40,
          scaleByDistance: new NearFarScalar(35.5e2, 1, 90.0e2, 0.0),
        },
        // translucencyByDistance: new NearFarScalar(3.5e2, 1, 30.0e2, 0.0),
      });

      // console.log("added");

      if (viewer.entities.getById(marker?.id) === undefined) {
        MarkerEntityCollection.removeAll();
        MarkerEntityCollection.add(markerEntity);
        viewer.entities.add(markerEntity);
        // console.log("added");
      } else {
        if (viewer.entities.getById(marker.id).show !== marker?.show) {
          let latestPoly = viewer.entities?.getById(marker.id);
          latestPoly.show = marker.visible;
        }
      }
      // } else {
      //   console.log(`Marker with id ${marker.id} is not visible.`);
      // }
    });

    return () => {
      viewer?.entities.removeAll();
    };
  }, [markerArray]);

  usePolygonAnnotationTool(
    viewerRef,
    setShapeCord,
    setShapeData,
    handlePolygonAnnotationModal,
    setShapeCordLatLan
  );

  usePolylineAnnotationTool(
    viewerRef,
    setShapeCord,
    setShapeData,
    handlePolylineAnnotationModal
  );

  usePointMarkerAnnotation(viewerRef, setSelectedPosition, handleOpen);
  usePolylineMeasureTool(viewerRef, setShapeCord, setShapeData);

  usePolygonMeasureTool(viewerRef, setShapeCord);

  useLineMeasureTool(viewerRef, setShapeCord, setShapeData);
  // mouse down on chevron
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      // Only activate on left mouse button down (button 0)
      setIsClicked(true);
    }
  };

  const handleMouseUp = () => {
    setIsClicked(false);
  };

  const chevronOuterBox = `h-12 w-12 relative right-5 rounded-full top-[47%] border-2 border-[#d9d9d9] flex justify-evenly items-center flex-row-reverse ${
    isClicked ? "bg-gray-200" : ""
  }`;

  return (
    <>
      <div className="h-full w-full" id="cesiumContainer">
        <div id="tooltip" className="none"></div>
        <GISToolbar
          handleHomeButtonClick={() =>
            handleHomeButtonClick(viewerRef, setGoHome, goHome, imageryLayer)
          }
          handleZoom={handleZoom}
          imageryLabel={imageryLabel}
          cursorLatLng={mousePosition}
        />
        {showComparer === true ? (
          <div id="slider" className="relative">
            {cookieValue !== "forest" && (
              <>
                <h2
                  className={`${
                    compareAssets?.[0] &&
                    `m-0 w-max font-semibold text-center absolute top-1 right-5 bg-gray-100 p-1 rounded-md border-2`
                  } `}
                >
                  {tileCategory === "GEOSERVER"
                    ? compareAssets[0]
                    : findTimeline(compareAssets?.[0])}
                </h2>
                <h2
                  className={`${
                    compareAssets?.[1] &&
                    `m-0 w-max font-semibold text-center absolute top-1 left-5 bg-gray-100 p-1 rounded-md border-2`
                  } `}
                >
                  {tileCategory === "GEOSERVER"
                    ? compareAssets[1]
                    : findTimeline(compareAssets?.[1])}
                </h2>
              </>
            )}

            <div
              className={chevronOuterBox}
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
            >
              <button
                id="right-chevron"
                className=" rounded-full h-6 w-6 flex items-center justify-center"
              >
                {/* Right chevron */}
                <img src={chevronIco} className="h-max w-max " alt="" />
              </button>
              {/* Rest of your JSX */}
              <button
                id="left-chevron"
                className=" h-6 w-6 rounded-full flex items-center justify-center"
              >
                {/* Right chevron */}
                <img
                  src={chevronIco}
                  className="h-max w-max transform scaleX(-1) rotate-180"
                  alt=""
                />
              </button>
            </div>
          </div>
        ) : null}
        {/* {promptFor === "polyline" ? (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Polyline Measure Tool : </strong> Add points using left
            click and complete the polyline using right click.
          </p>
        ) : (
          promptFor === "polygon" && (
            <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
              <strong>Polygon Measure Tool : </strong> Add points using left
              click and complete the polygon using right click.
            </p>
          )
        )}
        {polygonAnnotation && (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Polygon Marker Tool : </strong> Add points using left click
            and complete the polyline using right click.
          </p>
        )}
        {polylineAnnotation && (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Polyline Marker Tool : </strong> Add points using left click
            and complete the polygon using right click.
          </p>
        )}

        {annotationTool && (
          <p className="bg-[#303336] absolute bottom-2 right-40 text-xs px-2 py-1 text-white rounded-md tracking-wide border border-white z-10">
            <strong>Point Marker Tool : </strong> Right click to add marker.
          </p>
        )} */}
      </div>
      {annotationTool && isModalOpenCustom && (
        <MetaInput
          isOpen={isModalOpenCustom}
          onClose={() => {
            setIsModalOpenCustom(false);
          }}
          onSave={handleMarkerSave}
        />
      )}
      {openPolygonAnnotationModal && measureToolToggle === false && (
        <PolygonAnnotationModal
          isOpen={openPolygonAnnotationModal}
          onClose={() => setOpenPolygonAnnotationModal(false)}
          shapeData={shapeData}
          shapeCord={shapeCord}
          grp={grp}
        />
      )}
      {openPolylineAnnotationModal && measureToolToggle === false && (
        <PolylineAnnotationModal
          isOpen={openPolylineAnnotationModal}
          onClose={() => setOpenPolylineAnnotationModal(false)}
          shapeData={shapeData}
          shapeCord={shapeCord}
        />
      )}
      {measureToolToggle === false &&
        polygonAnnotation === false &&
        polylineAnnotation === false &&
        selectedMarker?.name !== "Boundary" &&
        selectedMarker?.name !== "Main Road" &&
        selectedMarker?.name !== undefined && (
          <>
            <MetaDataModal
              isOpen={isMetaModalOpen}
              onClose={() => setIsMetaModalOpen(false)}
              name={selectedMarker?.name}
              id={selectedMarker?.id}
              markerData={getTimestamp(selectedMarker?.id, allMarkersArray)}
              descriptions={
                getTimestamp(selectedMarker?.id, allMarkersArray)?.description
              }
              Category={
                getTimestamp(selectedMarker?.id, allMarkersArray)?.category
              }
              Severity={
                getTimestamp(selectedMarker?.id, allMarkersArray)?.severity
              }
              images={getTimestamp(selectedMarker?.id, allMarkersArray)?.images}
              viewer={viewerRef.current}
              // fetchData={fetchData}
              onDeleteMarker={() =>
                handleDeleteMarker(
                  selectedMarker?.mainId?.id,
                  selectedMarker?.id
                )
              }
              // refCounterUpdate={refCounterUpdate}
            />
          </>
        )}

      {measureToolToggle === false &&
        polygonAnnotation === false &&
        selectedMarker?.name !== undefined && (
          <>
            <VodBox
              selectedMarker={selectedMarker}
              handleGoLive={handleGoLive}
              vodOpen={vodOpen}
              handleVodOpen={handleVodOpen}
              handleVodClose={handleVodClose}
              handleHlsOpen={handleHlsOpen}
            />
            <VideoModal
              liveSource={liveSource}
              isLiveSrc={isLiveSrc}
              src={source}
              hlsOpen={hlsOpen}
              handleHlsClose={handleHlsClose}
              selectedMarker={selectedMarker?.id}
            />
          </>
        )}
      {measureToolToggle === false &&
        polygonAnnotation === false &&
        (selectedMarker?.name === "Boundary" ||
          selectedMarker?.name === "Main Road") && (
          <>
            <VodBox
              selectedMarker={selectedMarker}
              handleGoLive={handleGoLive}
              vodOpen={vodOpen}
              handleVodOpen={handleVodOpen}
              handleVodClose={handleVodClose}
              handleHlsOpen={handleHlsOpen}
            />
            <VideoModal
              liveSource={liveSource}
              isLiveSrc={isLiveSrc}
              src={source}
              hlsOpen={hlsOpen}
              handleHlsClose={handleHlsClose}
              selectedMarker={selectedMarker?.id}
            />
          </>
        )}
    </>
  );
};

export default GIS;
