import React from "react";
import { IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VideoContainer from "./VideoPlayers/VideoContainer";
import LiveVideoConatiner from "./VideoPlayers/LiveVideoConatiner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const VideoModal = (props) => {
  return (
    <Modal open={props.hlsOpen} onClose={props.handleHlsClose}>
      <div
        style={style}
        className="flex flex-col items-center justify-between bg-white w-[50%] h-auto rounded-[12px]"
      >
        <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px]  w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
          <h3 className="!text-[1.20rem]  text-[#211f1c] grow flex items-center justify-center">
            Video Player
          </h3>
          <div className="mr-[10px]">
            <IconButton
              onClick={props.handleHlsClose}
              aria-label="Close"
              size="small"
              className="text-[#211f1c]"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="grow w-full h-full p-3">
          {props.isLiveSrc ? (
            <>
              <div className="h-[450px] w-full">
                <LiveVideoConatiner src={props.liveSource} />
              </div>
            </>
          ) : (
            <>
              <VideoContainer src={props.src} />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
