import { createSlice } from "@reduxjs/toolkit";

const gisSlice = createSlice({
  name: "gis",
  initialState: {
    markerCategory: "",
    allObservations: [],
    mlvName: [],
    markerArray: [],
    refCounter: 0,
    latestAssetCounter: 0,
    zoomLevel: false,
    polygonAnnotationsData: [],
    polylineAnnotationsData: [],
    filteredAndInheritedAnnotations: [],
    measurementLengthUnit: "meter",
    measurementAreaUnit: "square meter",
    userPermissions: [],
  },
  reducers: {
    setMarkerCategory: (state, action) => {
      state.markerCategory = action.payload;
    },
    setAllObservations: (state, action) => {
      state.allObservations = action.payload;
    },
    setMlvName: (state, action) => {
      state.mlvName = action.payload;
    },
    setMarkerArray: (state, action) => {
      //   state.markerArray = [...state.markerArray, action.payload];
      state.markerArray = action.payload;
    },
    refCounterUpdate: (state) => {
      state.refCounter += 1;
    },
    latestAssetCounter: (state, action) => {
      if (action.payload) {
        state.latestAssetCounter = 0;
      } else {
        state.latestAssetCounter += 1;
      }
    },
    setZoomLevel: (state) => {
      state.zoomLevel = true;
    },
    setPolygonAnnotationsData: (state, action) => {
      state.polygonAnnotationsData = action.payload;
    },
    setPolylineAnnotationsData: (state, action) => {
      state.polylineAnnotationsData = action.payload;
    },
    setFilteredAndInheritedAnnotations: (state, action) => {
      state.filteredAndInheritedAnnotations = action.payload;
    },
    setMeasurementLengthUnit: (state, action) => {
      state.measurementLengthUnit = action.payload;
    },
    setMeasurementAreaUnit: (state, action) => {
      state.measurementAreaUnit = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.userPermissions = action.payload;
    },
  },
});

export const gisActions = gisSlice.actions;

export default gisSlice.reducer;
