import axios from "axios";
import React from "react";
import { getTokenFromCookie } from "../../Utils/Cookie";

const Roles = () => {
  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    const getRoles = async () => {
      const response = await axios.get(
        "https://staging.incharge.indrones.com/api/role",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenFromCookie()}`,
          },
        }
      );
      //   console.log("mohit", response);
      setRoles(response.data);
    };
    getRoles();
  }, []);

  return (
    <>
      <div
        className={`h-[calc(100%-340px)] cursor-default m-4 overflow-y-auto overflow-x-auto custom-scrollbar`}
      >
        <table className="table w-full border-[1px] border-solid border-[#e5e7eb]">
          <thead className="bg-[#4a4844] text-[#fff] sticky top-0">
            <tr>
              <th className="px-2 py-3 font-[500]">Sr No</th>
              <th className="px-2 py-3 font-[500]">Name</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {roles.map((role, index) => {
              return (
                <tr key={index}>
                  <td className="px-2 py-3">{index + 1}</td>
                  <td className="px-2 py-3">{role.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Roles;
