import React, { useState, useEffect, useRef, useId } from "react";
import { icon, infoIco } from "../../Assets/icons/gisIcons/icons";
import { DynamoDBApis } from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { Cartesian3 } from "cesium";
import { useDispatch, useSelector } from "react-redux";
import { getDate } from "../../Utils/OtherUtils";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { AnnotationReport } from "./AnnotationReport";
import { gisActions } from "../../redux/slices/GIS/gis-slice";

import CesiumUtils from "../../Utils/CesiumUtils";
import GISUtils from "../../Utils/GISUtils";

const Annotations = ({ viewer, openReport }) => {
  const dispatch = useDispatch();
  const [categoryFilter, setCategoryFilter] = useState("");
  const [severityFilter, setSeverityFilter] = useState("");
  const [openAnnoReport, setOpenAnnoReport] = useState(null);

  const { grpData: groupData } = useSelector((state) => state.gisHome);
  const { allObservations } = useSelector((state) => state.gis);

  const [finalFilteredAnnotations, setFinalFilteredAnnotations] = useState([]);
  // console.log(finalFilteredAnnotations, "finalFilteredAnnotations");
  const predefinedCategories = [
    "All",
    "Garbage",
    "Debris",
    "Steel Scrap",
    "Stock Pile",
    "Other",
  ];
  const severities = ["All", "Low", "Medium", "High"];
  const assetId = useSelector((state) => state.gisHome.asset);

  const [assetFilter, setAssetFilter] = useState(null);

  useEffect(() => {
    if (assetId) {
      setAssetFilter(assetId);
    }
  }, [assetId]);

  useEffect(() => {
    setFinalFilteredAnnotations(
      categoryFilter === "" && severityFilter === ""
        ? allObservations
        : allObservations.filter(
            (observation) =>
              (categoryFilter === "" ||
                observation.category === categoryFilter) &&
              (severityFilter === "" || observation.severity === severityFilter)
          )
    );
  }, [allObservations, categoryFilter, severityFilter]);

  const handleReportClick = (annotation) => {
    setOpenAnnoReport(true);
  };

  const handleCloseModal = () => {
    setOpenAnnoReport(null);
  };

  const handleFlyToMarker = (longitude, latitude) => {
    if (viewer) {
      const desiredZoomLevel = 1000;
      viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(
          longitude,
          latitude,
          desiredZoomLevel
        ),
        duration: 2.0,
        maximumHeight: desiredZoomLevel,
      });
    }
  };

  const handleChange = (name) => {
    if (name === "Low") {
      return "bg-yellow-200 border border-yellow-500 text-black";
    } else if (name === "Medium") {
      return "bg-orange-200 border border-orange-500 text-black";
    } else if (name === "High") {
      return "bg-red-200 border border-red-500 text-black";
    } else {
      return "bg-grey-200 border border-grey-500 text-black";
    }
  };
  const myElementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(null);

  useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const handleCheckboxChange = (id) => {
    const updatedAnnotations = finalFilteredAnnotations.map((annotation) =>
      annotation.id === id
        ? { ...annotation, visible: !annotation.visible }
        : annotation
    );
    // setFinalFilteredAnnotations(updatedAnnotations);
    dispatch(gisActions.setAllObservations(updatedAnnotations));
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  function toNormalCase(inputString) {
    return inputString?.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
  }

  const idElementRender = useId();
  const findAssetNameById = (assetId, data) => {
    const foundAsset = data.find((item) => item.id === assetId);

    return foundAsset ? getDate(foundAsset.date) : null;
  };

  return (
    <>
      <div
        ref={myElementRef}
        className={`${
          openReport ? "block" : "hidden"
        }  rounded-md w-[275px] resize-able !grow h-[100%] mr-2 ml-2 mb-2 mt-2 border-solid border-[#d7d8d8] border-[1px] bg-[#fff]`}
      >
        <div className="w-full rounded-tl-md rounded-tr-md flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
          <div className="grow flex items-center justify-center">
            Annotations
          </div>
        </div>
        <div className="flex gap-2 flex-col p-2 my-2 items-center overflow-hidden">
          {/* <div className="flex flex-col w-full">
            <label className="text-sm">Timeline</label>
            <select
              className="border border-gray-400 rounded text-xs min-h-[1.5rem] "
              value={assetFilter}
              onChange={(e) =>
                e.target.value === "All"
                  ? setAssetFilter(null)
                  : setAssetFilter(parseInt(e.target.value))
              }
            >
              <option value={"All"}>All</option>
              {groupData?.map((groupData, index) => (
                <option key={index} value={groupData.id}>
                  {getDate(groupData?.date)}
                </option>
              ))}
            </select>
          </div> */}
          <div className="flex flex-col w-full">
            <label className="text-sm">Category</label>
            <select
              className="border border-gray-400 rounded text-xs min-h-[1.5rem] "
              value={categoryFilter}
              onChange={(e) =>
                e.target.value === "All"
                  ? setCategoryFilter("")
                  : setCategoryFilter(e.target.value)
              }
            >
              {predefinedCategories?.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col w-full">
            <label className="text-sm">Severity</label>
            <select
              className="border border-gray-400 rounded text-xs m-h-[1.5rem]"
              value={severityFilter}
              onChange={(e) => {
                e.target.value === "All"
                  ? setSeverityFilter("")
                  : setSeverityFilter(e.target.value);
              }}
            >
              {severities?.map((severity, index) => (
                <option key={index} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <Tooltip title="Generate Annotation Report" placement="left" arrow>
          <div className="h-5 flex w-full justify-end">
            <img
              className="h-5 mr-3"
              src={icon}
              alt="!"
              onClick={() => handleReportClick()}
            />
          </div>
        </Tooltip> */}
        {finalFilteredAnnotations.length > 0 && assetId ? (
          <ul className="p-2 overflow-auto">
            {finalFilteredAnnotations?.map((annotation, index) => (
              <>
                <li
                  key={index}
                  className="my-2 rounded-md flex justify-between items-center gap-1"
                >
                  <input
                    type="checkbox"
                    name={`checkbox-${index}`}
                    checked={annotation.visible}
                    className=" focus:ring-transparent rounded-md "
                    onChange={() => handleCheckboxChange(annotation.id)}
                  />

                  {/* <img
                    src={annotation?.visible ? visibilityIco : visibilityOffIco}
                    onClick={() => handleCheckboxChange(annotation.id)}
                    alt={
                      annotation?.visible ? "visibilityIco" : "visibilityOffIco"
                    }
                    srcSet=""
                  /> */}
                  <h2
                    className={`text-base  text-left cursor-pointer   ml-2   truncate w-auto grow`}
                    onClick={() => {
                      // console.log(annotation, "annotation");
                      if (
                        annotation?.filter_type === "polygon" ||
                        annotation?.filter_type === "polyline"
                      ) {
                        const shapeCordLen = annotation?.shapeCord.length;

                        CesiumUtils.handleFlyToMarkerUtil(
                          viewer,
                          annotation?.filter_type,
                          annotation?.shapeCord[shapeCordLen - 1]
                        );
                      } else {
                        CesiumUtils.handleFlyToMarkerUtil(
                          viewer,
                          annotation.filter_type,
                          {
                            longitude: annotation.longitude,
                            latitude: annotation.latitude,
                          }
                        );
                      }
                    }}
                  >
                    {annotation?.markerName}
                  </h2>

                  <p
                    className={` text-xs  p-1 px-2  rounded-md   cursor-default w-max
                  ${handleChange(annotation.severity)} 
                 `}
                  >
                    {annotation?.category}
                  </p>
                  <div className="relative">
                    <HtmlTooltip
                      title={
                        <div className=" rounded-md">
                          <p>
                            Type Of Marker :{" "}
                            <b>{toNormalCase(annotation?.filter_type)}</b>
                          </p>
                          <p>
                            Timeline :{" "}
                            <b>
                              {findAssetNameById(annotation.assetId, groupData)}
                            </b>
                          </p>
                        </div>
                      }
                      placement="right"
                      arrow
                    >
                      <img
                        src={infoIco}
                        alt="Info"
                        className="cursor-pointer"
                        // onClick={() => handleInfoClick(annotation)}
                      />
                    </HtmlTooltip>
                  </div>
                  {/* <img src={infoIco} alt="" srcset="" /> */}
                </li>
              </>
            ))}
          </ul>
        ) : (
          <div className="text-center text-[12px] pb-3">
            {finalFilteredAnnotations?.length > 0
              ? " No Annotations with Selected Filters"
              : "Please select asset from timeline."}
          </div>
        )}
        <div className="text-[12px] w-full flex pb-2 ">
          <span className="ml-auto pr-2">
            Number of Annotations: {finalFilteredAnnotations.length}
          </span>
        </div>
      </div>
      {openAnnoReport && (
        <AnnotationReport
          onClose={handleCloseModal}
          elementWidth={elementWidth}
          selectedAsset={assetFilter}
          selectedCategory={categoryFilter}
          selectedSeverity={severityFilter}
        />
      )}
    </>
  );
};

export default Annotations;
