import React from "react";
import MapBox from "../Map/MapBox";
import { Button, Popper } from "@mui/material";
import EditAnnotations from "../../Features/Videography/hooks/EditAnnotations";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { useSelector } from "react-redux";
import { ExpressApis } from "../../Services/SecondPartyApi/Express";
import toast from "react-hot-toast";

const SeverityColor = (color) => {
  if (color === "" || color === "low") {
    return "bg-green-500";
  } else if (color === "medium") {
    return "bg-orange-500";
  } else if (color === "high") {
    return "bg-red-500";
  } else if ((color = "RFI")) {
    return "bg-yellow-400";
  }
};

const AnnotationBox = (props) => {
  var currentTime = props.currentTime;
  const [selectedAnnotationIndex, setSelectedAnnotationIndex] =
    React.useState(null);
  const [tobeDeletedIndex, setTobeDeletedIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activeIndices, setActiveIndices] = React.useState([]);
  const [annotation, setAnnotation] = React.useState([]);
  const sidebarRef = React.useRef(null);
  const activeElementRef = React.useRef(null);
  const showMap = useSelector((state) => state.mapToggle.showMap);
  const isCompare = useSelector((state) => state.project.isCompare);
  const get_annotations = useSelector((state) => state.project.annotations);
  const get_annotations2 = useSelector((state) => state.project.annotations2);
  const filterList = useSelector((state) => state.filterToggle.filterList);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const openPopper = (event, index) => {
    setTobeDeletedIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  const handleShowEdit = (index) => {
    setSelectedAnnotationIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  React.useEffect(() => {
    if (isCompare) {
      if (props.round) {
        setAnnotation(get_annotations);
      } else {
        setAnnotation(get_annotations2);
      }
    } else {
      setAnnotation(get_annotations);
    }
  }, [isCompare, get_annotations, get_annotations2, props.annoChange]);

  React.useEffect(() => {
    var indices = [];
    indices = annotation.reduce((acc, data, index) => {
      if (
        parseInt(currentTime) >= data.timeInSec &&
        parseInt(currentTime) <= data.endTime
      ) {
        acc.push(index);
      }
      return acc;
    }, []);

    setActiveIndices(indices);
  }, [currentTime, annotation]);

  React.useEffect(() => {
    if (activeElementRef.current) {
      const sidebarElement = sidebarRef.current;
      const activeElement = activeElementRef.current;
      const sidebarRect = sidebarElement.getBoundingClientRect();
      const activeElementRect = activeElement.getBoundingClientRect();

      if (
        activeElementRect.top < sidebarRect.top ||
        activeElementRect.bottom > sidebarRect.bottom
      ) {
        sidebarElement.scrollTo({
          top:
            activeElement.offsetTop -
            sidebarElement.offsetTop -
            sidebarElement.clientHeight / 2 +
            activeElement.clientHeight / 2,
          behavior: "smooth",
        });
      }
    }
  }, [activeIndices]);

  React.useEffect(() => {
    var filteredList;
    if (filterList.length) {
      if (isCompare) {
        if (props.round) {
          filteredList = get_annotations.filter((item) => {
            return filterList.some((filterItem) =>
              item.description.includes(filterItem.filter)
            );
          });
          setAnnotation(filteredList);
        } else {
          filteredList = get_annotations2.filter((item) => {
            return filterList.some((filterItem) =>
              item.description.includes(filterItem.filter)
            );
          });
          setAnnotation(filteredList);
        }
      } else {
        filteredList = get_annotations.filter((item) => {
          return filterList.some((filterItem) =>
            item.description.includes(filterItem.filter)
          );
        });
        setAnnotation(filteredList);
      }
    } else {
      setAnnotation(get_annotations);
    }
  }, [filterList, get_annotations]);

  const handleAnnotationDelete = async (id) => {
    try {
      const response = await ExpressApis.deleteAnnotation(id);
      // console.log("deleted", response);
      props.handleAnnoRefresh();
      toast.success("Annotation Deleted Sucessfully");
    } catch (error) {
      console.error(error);
      toast.success("Couldn't Delete Annotation");
    }
    closePopper();
  };

  return (
    <div
      className={`max-[700px]:w-[100%] hight-123 h-full flex flex-col bg-[#fffffa] ${
        isCompare ? "w-full" : "w-[33%]"
      }`}
    >
      <div
        className="h-[50%] grow max-[500px]:mr-0 max-[500px]:mt-0 mr-1 mt-2 overflow-y-scroll custom-scrollbar no-track grow"
        ref={sidebarRef}
      >
        {annotation.length ? (
          <ul>
            {annotation.map((data, index) => {
              const isSelected = selectedAnnotationIndex === index;
              const isActive = activeIndices.includes(index);
              const tobeDeleted = tobeDeletedIndex === index;
              return (
                <li
                  className={`bg-box-model-list border mb-[8px] mr-[8px] ml-[8px] mgt-8 rounded-[4px] p-2 cursor-pointer relative flex flex-col ${
                    isActive ? SeverityColor(data.severity) : "bg-white"
                  }`}
                  key={index}
                  name={`anno${index}`}
                  ref={isActive ? activeElementRef : null}
                >
                  {!isSelected && (
                    <div onClick={() => props.handleAnnoSeek(data.timeInSec)}>
                      <div className="flex text-[14px] items-center justify-between mx-[10px] border-dashed  border-b">
                        <h3 className="text-[#3a3a3c] font-[500]">
                          <span className="text-[#ffca00] font-[500] tracking-[0.6px] uppercase ">
                            Chainage:{" "}
                          </span>
                          {data.chainage}Km
                        </h3>
                        <h2 className="font-[500]">{data.time}</h2>
                      </div>
                      <div
                        key={index}
                        className="mx-[10px] my-[5px] text-[13px]"
                      >
                        {data.description}
                      </div>
                    </div>
                  )}
                  <div className="ml-auto flex items-center">
                    <button
                      className="text-center p-[3px] text-[rgba(0,0,0,0.54)] rounded-full hover:bg-[rgba(0,0,0,0.04)] b-0 m-0"
                      aria-label="delete"
                      onClick={(event) => openPopper(event, index)}
                    >
                      <DeleteRoundedIcon fontSize="small" />
                    </button>
                    {tobeDeleted && (
                      <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        placement="top"
                      >
                        <div className="border p-3 rounded bg-white w-max flex flex-col">
                          <span>
                            Are you sure you want to delete this item?
                          </span>
                          <span>This action cannot be undone.</span>
                          <div className="flex items-center justify-center gap-2 mt-3">
                            <Button
                              variant="contained"
                              size="small"
                              onClick={closePopper}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              // sx={{ backgroundColor: "red", color: "white" }}
                              className="!bg-[#FF0000] hover:!bg-[#cf0202]"
                              onClick={() => handleAnnotationDelete(data.id)}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      </Popper>
                    )}
                    <button
                      className="text-center p-[3px] text-[rgba(0,0,0,0.54)] rounded-full hover:bg-[rgba(0,0,0,0.04)] b-0 m-0"
                      aria-label="edit"
                      onClick={() => handleShowEdit(index)}
                    >
                      <EditRoundedIcon fontSize="small" />
                    </button>
                  </div>
                  {isSelected && (
                    <div>
                      <EditAnnotations
                        data={data}
                        handleAnnoRefresh={props.handleAnnoRefresh}
                        handleShowEdit={handleShowEdit}
                        index={index}
                      />
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        ) : (
          <>
            <h1 className="flex justify-center mt-3 text-lg font-bold items-center w-full">
              No Annoations Found
            </h1>
          </>
        )}
      </div>
      {showMap && !isCompare && (
        <div className="h-[50%] w-[99%] mx-auto my-2 p-[5px]">
          <MapBox
            currentTime={currentTime}
            CSMT_DADAR={props.isCSMT}
            isPanvelKarjat={props.isPanvelKarjat}
            isIOCL={props.isIOCL}
            isIOCL2={props.isIOCL2}
          />
        </div>
      )}
    </div>
  );
};

export default AnnotationBox;
