import Cookies from "js-cookie";

export const getTokenFromCookie = () => {
  return Cookies.get("access_token");
};

export const removeAllCookies = () => {
  Cookies.remove("userDetails");
  Cookies.remove("access_token");
  Cookies.remove("userId");
  Cookies.remove("firstLogin");
  Cookies.remove("nhaiSignUp");
};

export const tokenChecker = (navigate) => {
  const token = getTokenFromCookie();
  if (!token) {
    navigate("/");
  }
};
export const getUserId = () => {
  return Cookies.get("userId");
};

export const getUserEmail = () => {
  return Cookies.get("userEmail");
};

export const getUserPermissions = () => {
  return Cookies.get("user_permissions");
};
