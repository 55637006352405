export const formatDate = (timestamp) => {
  const currentDate = new Date(timestamp);

  // Extract day, month, and year components
  const day = currentDate.getDate().toString().padStart(2, "0");
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based, so add 1
  const year = currentDate.getFullYear().toString().slice(-2); // Get the last two digits of the year

  const formattedDate = `${day}-${month}-${year}`;
  // console.log(formattedDate);
  return formattedDate;
};
