import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Checkbox, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { filterToggleActions } from "../../../redux/slices/Videography/filter-toggle-slice";

const filterList = [
  { label: "Nallah Crossing", filterName: "NALLA" },
  { label: "Road Crossing", filterName: "ROAD" },
  { label: "River Crossing", filterName: "RIVER" },
  { label: "Bridges", filterName: "BR" },
  { label: "Station", filterName: "STATION" },
  { label: "FOB", filterName: "FOB" },
  { label: "ROB", filterName: "ROB" },
];

const FilterAnnotations = (props) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const dispatch = useDispatch();
  const filteredList = useSelector((state) => state.filterToggle.filterList);
  const [checkedCheckboxes, setCheckedCheckboxes] =
    React.useState(filteredList);

  const handleCheckboxChange = (index, filter) => {
    const updatedCheckboxes = [...checkedCheckboxes];
    const existingIndex = updatedCheckboxes.findIndex(
      (item) => item.index === index
    );

    if (existingIndex !== -1) {
      updatedCheckboxes.splice(existingIndex, 1);
    } else {
      updatedCheckboxes.push({ index, filter });
    }
    setCheckedCheckboxes(updatedCheckboxes);
    dispatch(filterToggleActions.setFilter(updatedCheckboxes));
  };

  return (
    <div className="bg-box-model rounded-[12px] bg-white  flex flex-col gap-2 w-[300px] ml-[10px]">
      <div className="w-full rounded-tl-[12px] rounded-tr-[12px]  flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
        <div className="grow flex items-center justify-center">Filter</div>
        <div className="mr-[10px]" onClick={props.openFilterPopper}>
          <IconButton aria-label="delete" size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-col p-2">
        {filterList.map((filter, index) => {
          var isChecked = false;
          if (filteredList.length) {
            isChecked = filteredList.some(
              (item) => item.filter === filter.filterName
            );
          }
          return (
            <>
              <div
                key={index}
                className="flex w-[100%] items-center justify-between"
              >
                <Checkbox
                  color="default"
                  size="small"
                  id={`checkbox${index + 1}`}
                  {...label}
                  onChange={() =>
                    handleCheckboxChange(index, filter.filterName)
                  }
                  checked={isChecked}
                />
                <div className={`grow "text-black" `}>{filter.label}</div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default FilterAnnotations;
