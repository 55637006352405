//Importing cesium dependencies for util functions
import { Cartesian3, Cartographic, Math as MathOfCesium } from "cesium";

/**
 * @file CesiumUtils.js
 * @summary Collection of GIS-related utility functions in one CesiumUtils module/object
 * @description This file contains utility functions related to Geographic Information Systems (GIS).
 *              Please ensure that all GIS utility functions are added in this file.
 *              Don't forget to include JavaScript comments documenting each function.
 */

const CesiumUtils = {
  /**
   * Converts Cartesian coordinates to degrees.
   * @param {Object} cartesian - Cartesian coordinates.
   * @returns {Object} - Latitude, longitude, and height in degrees.
   */
  cartesianToDegreesUtil: (cartesian) => {
    // Convert Cartesian coordinates to Cartographic
    const cartographic = Cartographic.fromCartesian(cartesian);

    // Convert latitude and longitude to degrees

    const latitude = MathOfCesium.toDegrees(cartographic.latitude);
    const longitude = MathOfCesium.toDegrees(cartographic.longitude);

    // Get the height
    const height = cartographic.height;

    // Return the result as an object
    return {
      latitude: latitude,
      longitude: longitude,
      height: height,
    };
  },

  /**
   * Handles flying to a Annotations / Observations on the globe.
   * @param {Object} viewer - Cesium viewer object.
   * @param {string} type - Type of shape ("marker", "polygon", "polyline").
   * @param {Object} shapeCord - Coordinates of the shape.
   */

  handleFlyToMarkerUtil: (viewer, type, shapeCord) => {
    if (viewer) {
      // Get the current camera height
      const ellipsoid = viewer.scene.globe.ellipsoid;
      const cameraHeight = ellipsoid.cartesianToCartographic(
        viewer.camera.position
      ).height;

      // Set the desired zoom level based on the camera height
      const desiredZoomLevel = cameraHeight < 300 ? cameraHeight : 300;

      // Fly to the marker based on the type of shape
      if (type === "polygon" || type === "polyline") {
        // Convert Cartesian coordinates to degrees
        const forshape = CesiumUtils.cartesianToDegreesUtil(shapeCord);

        // Fly to the destination
        viewer.camera.flyTo({
          destination: Cartesian3.fromDegrees(
            forshape?.longitude,
            forshape?.latitude,
            desiredZoomLevel
          ),
          duration: 2.0,
          maximumHeight: desiredZoomLevel,
        });
      } else {
        // Fly to the destination
        viewer.camera.flyTo({
          destination: Cartesian3.fromDegrees(
            shapeCord?.longitude,
            shapeCord?.latitude,
            desiredZoomLevel
          ),
          duration: 2.0,
          maximumHeight: desiredZoomLevel,
        });
      }
    }
  },
};

export default CesiumUtils;
