import testData_R5 from "../../src/Data/Jsons/full_vids_with_video_time_R5_consolidated.json";

export var latLngs = [];
function convertTimeToSeconds(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);
  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalTimeInSeconds;
}

for (let i = 0; i < testData_R5.length; i++) {
  var obj = {
    lat: testData_R5[i].lat,
    lon: testData_R5[i].lon,
    time: convertTimeToSeconds(testData_R5[i].video_time),
    chainage: testData_R5[i].chainage,
  };
  latLngs.push(obj);
}

const filterlabel = (label) => {
  if (label < 0 && (label.toFixed(1) * 10) % 2 === 0) {
    if (Math.abs(label.toFixed(1)) !== 0) {
      return label.toFixed(1);
    }
  } else if ((label.toFixed(1) * 10) % 2 === 0) {
    return label.toFixed(1);
  }
};

export var chainages = [];
for (let i = 0; i < testData_R5.length; i++) {
  var Chainobj = {
    value: convertTimeToSeconds(testData_R5[i].video_time),
    label: filterlabel(testData_R5[i].chainage),
    tobeFiltered: true,
  };
  chainages.push(Chainobj);
}
var Firstobj = {
  value: convertTimeToSeconds(testData_R5[0].video_time),
  label: testData_R5[0].chainage.toFixed(1),
  tobeFiltered: false,
};
chainages.push(Firstobj);

export var annotations_R5 = [];
for (let i = 0; i < testData_R5.length; i++) {
  var annoObj = {};
  if (testData_R5[i].ann_on_vid_time[0]) {
    annoObj.timeInSec = convertTimeToSeconds(testData_R5[i].video_time);
    annoObj.time = testData_R5[i].video_time;
    annoObj.annotations = testData_R5[i].ann_on_vid_time;
    annoObj.chainage = testData_R5[i].chainage.toFixed(1);
    annoObj.endTime = convertTimeToSeconds(testData_R5[i].end_ann_time);
    annoObj.severity = "low";
    annotations_R5.push(annoObj);
  }
}
