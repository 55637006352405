import testData_Ct_Dr from "../../src/Data/Jsons/CSMT_DADAR_Reprocessing_consolidated.json";

function convertTimeToSeconds(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);
  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalTimeInSeconds;
}

export var latLngs_ct_dr = [];
for (let i = 0; i < testData_Ct_Dr.length; i++) {
  var obj = {
    lat: testData_Ct_Dr[i].lat,
    lon: testData_Ct_Dr[i].lon,
    time: convertTimeToSeconds(testData_Ct_Dr[i].video_time),
    chainage: testData_Ct_Dr[i].chainage,
  };
  latLngs_ct_dr.push(obj);
}

const filterlabel = (label) => {
  if (label < 0) {
    return;
  } else if ((label.toFixed(1) * 10) % 2 === 0) {
    return label.toFixed(1);
  }
};

export var chainages_Ct_Dr = [];
for (let i = 0; i < testData_Ct_Dr.length - 1; i++) {
  var Chainobj = {
    value: convertTimeToSeconds(testData_Ct_Dr[i].video_time),
    label: filterlabel(testData_Ct_Dr[i].chainage),
    tobeFiltered: true,
  };
  chainages_Ct_Dr.push(Chainobj);
}
var Firstobj = {
  value: convertTimeToSeconds(testData_Ct_Dr[0].video_time),
  label: testData_Ct_Dr[0].chainage.toFixed(1),
  tobeFiltered: false,
};
chainages_Ct_Dr.push(Firstobj);
var Lastobj = {
  value: convertTimeToSeconds(
    testData_Ct_Dr[testData_Ct_Dr.length - 1].video_time
  ),
  label: testData_Ct_Dr[testData_Ct_Dr.length - 1].chainage.toFixed(1),
  lastobj: true,
};
chainages_Ct_Dr.push(Lastobj);

export var annotations_Ct_Dr = [];
for (let i = 0; i < testData_Ct_Dr.length; i++) {
  var annoObj = {};
  if (testData_Ct_Dr[i].ann_on_vid_time[0]) {
    annoObj.timeInSec = convertTimeToSeconds(testData_Ct_Dr[i].video_time);
    annoObj.time = testData_Ct_Dr[i].video_time;
    annoObj.annotations = testData_Ct_Dr[i].ann_on_vid_time;
    annoObj.chainage = testData_Ct_Dr[i].chainage.toFixed(1);
    annoObj.endTime = convertTimeToSeconds(testData_Ct_Dr[i].end_ann_time);
    annoObj.severity = "low";
    annotations_Ct_Dr.push(annoObj);
  }
}
