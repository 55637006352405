import React, { useEffect, useState } from "react";
import {
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import { AnnotationManager } from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { useDispatch, useSelector } from "react-redux";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { getDate } from "../../Utils/OtherUtils";
import { getUserEmail } from "../../Utils/Cookie";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const findAssetNameById = (assetId, data) => {
  const foundAsset = data.find((item) => item.id === assetId);

  return foundAsset ? getDate(foundAsset.date) : null;
};

const PolylineAnnotationModal = ({
  isOpen,
  onClose,
  shapeData,
  shapeCord,
  grp,
}) => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  // const [volume, setVolume] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [severity, setSeverity] = React.useState("Low");
  const [date, setDate] = React.useState(null);
  const assetId = useSelector((state) => state.gisHome.asset);
  const { grpData: groupData } = useSelector((state) => state.gisHome);
  const pointMarkers = [
    "Steel Scrap",
    "Stock Pile",
    "Garbage",
    "Debris",
    "Other",
  ];

  const dispatch = useDispatch();

  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");

  useEffect(() => {
    if (name && category) {
      setNameError("");
      setCategoryError("");
    } else if (name) {
      setCategoryError("");
    } else {
      setNameError("");
    }
  }, [name, category]);

  const handleAnnotationSave = async () => {
    if (!name && !category) {
      setNameError("Please enter a name");
      setCategoryError("Please select a marker type");
    } else if (!name) {
      setNameError("Please enter a name");
      setCategoryError("");
    } else if (!category) {
      setNameError("");
      setCategoryError("Please select a marker type");
    } else {
      setNameError("");
      setCategoryError("");

      const dataToPush = {
        id: uuidv4(),
        timestamp: Date.now(),
        assetId: assetId,
        markerName: name,
        category,
        // volume,
        filter_type: "polyline",
        shapeCord,
        shapeData,
        description,
        severity,
        markerDate: date,
        groupName: grp,
        visible: true,
      };
      const response = AnnotationManager.addAnnotations(dataToPush);
      toast.success("Polyline Annotated Successfully ! ");

      if (severity === "High") {
        const dataForMail = {
          recipients: [getUserEmail()],
          dataJson: {
            name,
            description,
            category,
            date: findAssetNameById(assetId, groupData),
          },
        };

        const responses = await axios.post(
          "http://192.168.0.104:8001/api/v1/email/notify",
          dataForMail
        );

        // console.log("mmmmm", responses);
      }

      // refCounterUpdate();
      dispatch(gisActions.refCounterUpdate());
      onClose();
    }
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={() => {
          dispatch(gisActions.refCounterUpdate());

          onClose();
        }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <div
          style={style}
          className="w-1/3 flex flex-col items-center justify-between bg-white  !h-auto rounded-[12px]"
        >
          <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px]  w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
            <h3 className="!text-[1.20rem]  text-[#211f1c] grow flex items-center justify-center">
              Polyline Annotation
            </h3>
            <div className="mr-[10px]">
              <IconButton
                onClick={() => {
                  dispatch(gisActions.refCounterUpdate());

                  onClose();
                }}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="grow w-full p-3 flex flex-col gap-3">
            <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">
                Name:<span className="text-red-400">*</span>
              </label>{" "}
              <TextField
                name="textNote"
                size="small"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="grow"
              />
            </div>
            {nameError && (
              <p className="ml-[30%] text-red-500 text-sm">{nameError}</p>
            )}
            <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">Description:</label>
              <TextField
                name="textNote"
                size="small"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="grow"
              />
            </div>
            <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">
                Marker type:<span className="text-red-400">*</span>
              </label>
              <select
                className="border border-gray-600 px-2 py-1 rounded grow"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select a Marker Type</option>
                {pointMarkers.map((marker, index) => (
                  <option key={index} value={marker}>
                    {marker}
                  </option>
                ))}
              </select>
            </div>
            {categoryError && (
              <p className="ml-[30%] text-red-500 text-sm">{categoryError}</p>
            )}
            <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">Length:</label>
              <TextField
                name="textNote"
                size="small"
                value={`${
                  shapeData.totalLengthofSegment
                    ? parseFloat(shapeData?.totalLengthofSegment).toFixed(4) +
                      "m"
                    : "Calculating..."
                }  `}
                inputProps={{ readOnly: true }}
                className="grow"
              />
            </div>
            <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">Date:</label>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                sx={{ flexGrow: 1 }}
              >
                <DemoContainer components={["DatePicker"]} sx={{ flexGrow: 1 }}>
                  <FormControl fullWidth>
                    <DatePicker
                      label="Timeline"
                      slotProps={{ textField: { size: "small" } }}
                      onChange={(selectedDate) => {
                        setDate(dayjs(selectedDate).format("DD_MM_YY"));
                      }}
                    />
                  </FormControl>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            {/* <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">Volume:</label>
              <TextField
                name="textNote"
                size="small"
                placeholder="in cubic meter"
                value={volume}
                onChange={(e) => setVolume(e.target.value)}
                className="grow"
              />
            </div> */}
            <div className="flex items-center justify-center w-full">
              <label className="w-[30%]">Severity:</label>
              <Select
                className="grow"
                size="small"
                name="severity"
                id="demo-simple-select-error"
                value={severity}
                onChange={(e) => setSeverity(e.target.value)}
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
                {/* <MenuItem value="Require Immediate Review">
                  Require Immediate Review
                </MenuItem> */}
              </Select>
            </div>
            <div className="flex gap-2 justify-center items-center">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  handleAnnotationSave();
                }}
              >
                Save
              </button>

              <button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  dispatch(gisActions.refCounterUpdate());

                  onClose();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PolylineAnnotationModal;
