import React from "react";

import Cookies from "js-cookie";
import Header from "../../Components/Layouts/Header";
import { loadingGif, nhaiLogo } from "../../Assets/images/servicesImg/services";

const Thankyou = () => {
  const isNhaiSignup = Cookies.get("nhaiSignUp");

  return (
    <>
      <Header />

      <section className="border h-[90vh] bg-white flex flex-col items-center justify-center">
        {isNhaiSignup === "nhaiSignUp" ? (
          <>
            <img className="h-40" src={nhaiLogo} alt="NHAI logo" />
            <h2 className="text-3xl text-black font-semibold text-center mt-4">
              Thank you for signing up!
              <br />
              We have registered your request.
            </h2>
          </>
        ) : (
          <>
            <img src={loadingGif} alt="loading gif" className="mt-4 h-20" />
            <h3 className="text-lg text-black text-center mt-2">
              Your <span className="font-mono bg-gray-100 px-1">console</span>{" "}
              preparation is underway.
              <br />
              We will get back to you shortly.
            </h3>
          </>
        )}
      </section>
    </>
  );
};

export default Thankyou;
