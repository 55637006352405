import * as AWS from "aws-sdk";

const configuration = {
  region: "ap-south-1",
  secretAccessKey: "aNxVVkZLBNu3aneI85TrHI6pPyIzAFb72bDKlvXP",
  accessKeyId: "AKIAX546GMWHGDP77WX6",
};

AWS.config.update(configuration);
const s3 = new AWS.S3();

export class S3Apis {
  static uploadData = (file, type) => {
    const date = new Date();
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const Imagedate = `${day}-${month}-${year}`;
    const file_name = file.name.replace(/\s+/g, "_");
    const filePath = `${type}/${year}/${month}/${day}/${file_name}`;
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: "staging-inspect",
        Key: filePath,
        Body: file,
      };

      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading file to S3:", err);
          reject(err);
        } else {
          // console.log("File uploaded successfully:", data);
          resolve({ s3Url: data, Imagedate: Imagedate });
        }
      });
    });
  };
}
