import React, { useState } from "react";
import {
  pinIcon,
  switchIcon,
  homeIcon,
  scaleIco,
  lineIco,
  polylineIco,
  polygonIco,
  markers,
  ico2D,
  measuringTapeIco,
} from "../../Assets/icons/gisIcons/icons";
import { Tooltip } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";

import { gisToolActions } from "../../redux/slices/GIS/gis-tools-slice";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Cartesian3, Math as MathOfCesium } from "cesium";

const GISToolbar = ({
  handleHomeButtonClick,
  setShowCompare,
  setToolCategory,
  toolCategory,
  handleZoom,
  imageryLabel,
  cursorLatLng,
}) => {
  const dispatch = useDispatch();

  const { asset, mode } = useSelector((state) => state.gisHome);
  const { userPermissions } = useSelector((state) => state.gis);
  const { grp } = useParams();

  // console.log(userPermissions, "userPermissions");
  const {
    showComparer,
    annotationTool,
    measureToolToggle,
    polygonAnnotation,
    polylineAnnotation,
    annotationToggle,
    unitsIsOpen,
    selectedTool,
    lineMeasureTool,
    polylineMeasureTool,
    polygonMeasureTool,
  } = useSelector((state) => state.gisTools);

  // if (
  //   (showComparer ||
  //     annotationTool ||
  //     measureToolToggle ||
  //     polygonAnnotation ||
  //     polylineAnnotation) &&
  //   shapesIds.length > 0
  // ) {
  //   dispatch(gisHomeActions.setShapesIds([]));
  // }
  const hoverColor = "#a0aab46b";
  const clientNameFromCookie = Cookies.get("clientName");

  const [selectedMeasurmentUnit, setSelectedMeasurmentUnit] = useState("m");
  const [selectedAreaUnit, setSelectedAreaUnit] = useState("sq m");

  const handleUnitChange = (unit) => {
    setSelectedMeasurmentUnit(unit);
  };

  const handleAreaUnitChange = (unit) => {
    setSelectedAreaUnit(unit);
  };

  const measurementPermissions = [
    "measurement_line_view",
    "measurement_polyline_view",
    "measurement_polygon_view",
  ];

  const hasMeasurementPermission = userPermissions.some((permission) =>
    measurementPermissions.includes(permission)
  );

  const annotationPolylinePermissions = [
    "annotation_line_add",
    "annotation_polyline_add",
    "annotation_polygon_add",
  ];

  const hasAnnotationPolylinePermission = userPermissions.some((permission) =>
    annotationPolylinePermissions.includes(permission)
  );

  return (
    <div className="toolbarContainer ">
      {mode !== "3D" && (
        <div
          id="toolbar"
          className="absolute flex flex-col z-10 right-2 top-10 mt-2 gap-2 "
        >
          {asset !== null && (
            <Tooltip title="Home" arrow placement="left">
              <button
                className={` h-8 w-8 bg-[#303336] border rounded active:border active:bg-[#48b] hover:bg-[${hoverColor}]`}
                onClick={handleHomeButtonClick}
              >
                <img className="w-8" src={homeIcon} alt="" />
              </button>
            </Tooltip>
          )}
          {asset !== null &&
            grp !== "ESSEL" &&
            hasAnnotationPolylinePermission &&
            clientNameFromCookie !== "skyhigh" && (
              <div
                className="relative flex "
                onMouseEnter={() => {
                  dispatch(gisToolActions.measureToggle(false));

                  dispatch(gisToolActions.annotationToggle(true));
                }}
              >
                <Tooltip
                  title={
                    !annotationToggle
                      ? "Markers"
                      : selectedTool !== null
                      ? `${selectedTool} annotation`
                      : "Please select annotation tool"
                  }
                  arrow
                  placement="bottom"
                >
                  <button
                    className={`h-8 w-8 ${
                      selectedTool === "marker" ||
                      selectedTool === "polyline" ||
                      selectedTool === "polygon"
                        ? "border bg-[#48b] rounded p-1 "
                        : "bg-[#303336] border rounded"
                    }  hover:bg-[${hoverColor}]`}
                    onClick={() => {
                      if (selectedTool !== null) {
                        dispatch(gisToolActions.setselectedTool(null));
                        dispatch(gisToolActions.turnOffTools());
                      }
                    }}
                  >
                    <img
                      className="w-8"
                      src={
                        selectedTool === "marker"
                          ? pinIcon
                          : selectedTool === "polyline"
                          ? polylineIco
                          : selectedTool === "polygon"
                          ? polygonIco
                          : selectedTool === "line"
                          ? lineIco
                          : markers
                      }
                      alt={` ${selectedTool} icon `}
                    />
                  </button>
                </Tooltip>
                {annotationToggle && (
                  <div
                    className="flex flex-row gap-2 transition-opacity duration-300 ease-in-out absolute right-10"
                    onMouseLeave={() => {
                      annotationToggle &&
                        dispatch(gisToolActions.annotationToggle(false));
                    }}
                  >
                    {userPermissions.includes("annotation_marker_add") && (
                      <Tooltip title="Point Marker" arrow placement="bottom">
                        <button
                          className={`w-8 h-8 p-1 text-white bg-[#303336] border rounded  ${
                            annotationTool ? "border bg-[#48b] rounded" : ""
                          } hover:bg-[${hoverColor}]`}
                          onClick={() => {
                            dispatch(gisToolActions.setselectedTool("marker"));

                            dispatch(
                              gisToolActions.toggleTools("annotationTool")
                            );
                          }}
                        >
                          <img className="w-8" src={pinIcon} alt="" />
                        </button>
                      </Tooltip>
                    )}
                    {userPermissions.includes("annotation_polyline_add") && (
                      <Tooltip title="Polyline Tool" arrow placement="bottom">
                        <button
                          className={`w-8 h-8 p-1 text-white bg-[#303336] border rounded ${
                            polylineAnnotation ? "border bg-[#48b] rounded" : ""
                          } hover:bg-[${hoverColor}]`}
                          onClick={() => {
                            dispatch(
                              gisToolActions.setselectedTool("polyline")
                            );

                            dispatch(
                              gisToolActions.toggleTools(
                                "polylineAnnotationTool"
                              )
                            );
                          }}
                        >
                          <img
                            className="w-8"
                            src={polylineIco}
                            alt=""
                            srcSet=""
                          />
                        </button>
                      </Tooltip>
                    )}
                    {userPermissions.includes("annotation_polygon_add") && (
                      <Tooltip title="Polygon Marker" arrow placement="bottom">
                        <button
                          className={`w-8 h-8 p-1 text-white bg-[#303336] border rounded ${
                            polygonAnnotation ? "border bg-[#48b] rounded" : ""
                          } hover:bg-[${hoverColor}]`}
                          onClick={() => {
                            dispatch(gisToolActions.setselectedTool("polygon"));

                            dispatch(
                              gisToolActions.toggleTools(
                                "polygonAnnotationTool"
                              )
                            );
                          }}
                        >
                          <img
                            className="w-8"
                            src={polygonIco}
                            alt=""
                            srcSet=""
                          />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            )}

          {/* compair  */}
          {grp !== "ESSEL" &&
            asset !== "" &&
            userPermissions.includes("compare_timelines") && (
              <Tooltip title="Click to Compare" arrow placement="left">
                <button
                  className={`h-8 w-8 ${
                    showComparer
                      ? "border bg-[#48b] rounded"
                      : "bg-[#303336] border rounded"
                  } hover:bg-[${hoverColor}]`}
                  onClick={() => {
                    // setShowCompare(!showComparer);
                    dispatch(gisToolActions.toggleTools("showComparer"));

                    // Toggle the showButtons
                    // showAssetsSelect();
                    // dispatch(gisHomeActions.toggleShowComparer(showComparer));
                  }}
                >
                  <img className="w-8 text-red-200" src={switchIcon} alt="" />
                </button>
              </Tooltip>
            )}
          {/* measure tools  */}
          {asset !== "" && hasMeasurementPermission && (
            <div className="relative flex">
              <Toaster />

              <Tooltip
                title={
                  !measureToolToggle
                    ? "Markers"
                    : selectedTool !== null
                    ? `${selectedTool} `
                    : "Please select measure tool"
                }
                arrow
                placement="bottom"
              >
                <button
                  className={`h-8 w-8 ${
                    selectedTool === "lineMeasure" ||
                    selectedTool === "polylineMeasure" ||
                    selectedTool === "polygonMeasure"
                      ? "border bg-[#48b] rounded p-1 "
                      : "bg-[#303336] border rounded"
                  }  hover:bg-[${hoverColor}]`}
                  onClick={() => {
                    if (selectedTool !== null) {
                      dispatch(gisToolActions.setselectedTool(null));
                      dispatch(gisToolActions.turnOffTools());
                    }
                  }}
                  onMouseEnter={() => {
                    dispatch(gisToolActions.annotationToggle(false));
                    dispatch(gisToolActions.measureToggle(true));
                  }}
                >
                  <img
                    className="w-8"
                    src={
                      selectedTool === "polylineMeasure"
                        ? polylineIco
                        : selectedTool === "polygonMeasure"
                        ? polygonIco
                        : selectedTool === "lineMeasure"
                        ? lineIco
                        : scaleIco
                    }
                    alt=""
                  />
                </button>
              </Tooltip>
              {measureToolToggle && !annotationTool && (
                <>
                  <div
                    className={`flex flex-row gap-2 transition-opacity duration-300 ease-in-out absolute right-10`}
                    onMouseLeave={() => {
                      measureToolToggle &&
                        dispatch(gisToolActions.measureToggle(false));
                    }}
                  >
                    {userPermissions.includes("measurement_line_view") && (
                      <Tooltip
                        title="Line Measure Tool"
                        arrow
                        placement="bottom"
                      >
                        <button
                          className={`w-8 h-8 p-1 text-white bg-[#303336] border rounded ${
                            lineMeasureTool ? "border bg-[#48b] rounded" : ""
                          } hover:bg-[${hoverColor}]`}
                          onClick={() => {
                            dispatch(
                              gisToolActions.setselectedTool("lineMeasure")
                            );
                            dispatch(
                              gisToolActions.toggleTools("lineMeasureTool")
                            );
                          }}
                        >
                          <img className="w-8" src={lineIco} alt="" srcSet="" />
                        </button>
                      </Tooltip>
                    )}
                    {userPermissions.includes("measurement_polyline_view") && (
                      <Tooltip
                        title="Polyline Measure Tool"
                        arrow
                        placement="bottom"
                      >
                        <button
                          className={`w-8 h-8 p-1 text-white bg-[#303336] border rounded ${
                            polylineMeasureTool
                              ? "border bg-[#48b] rounded"
                              : ""
                          } hover:bg-[${hoverColor}]`}
                          onClick={() => {
                            dispatch(
                              gisToolActions.setselectedTool("polylineMeasure")
                            );
                            dispatch(
                              gisToolActions.toggleTools("polylineMeasureTool")
                            );
                          }}
                        >
                          <img
                            className="w-8"
                            src={polylineIco}
                            alt=""
                            srcSet=""
                          />
                        </button>
                      </Tooltip>
                    )}
                    {userPermissions.includes("measurement_polygon_view") && (
                      <Tooltip
                        title="Polygon Measure Tool"
                        arrow
                        placement="bottom"
                      >
                        <button
                          className={`w-8 h-8 p-1 text-white bg-[#303336] border rounded ${
                            polygonMeasureTool ? "border bg-[#48b] rounded" : ""
                          } hover:bg-[${hoverColor}]`}
                          onClick={() => {
                            dispatch(
                              gisToolActions.setselectedTool("polygonMeasure")
                            );
                            dispatch(
                              gisToolActions.toggleTools("polygonMeasureTool")
                            );
                          }}
                        >
                          <img
                            className="w-8"
                            src={polygonIco}
                            alt=""
                            srcSet=""
                          />
                        </button>
                      </Tooltip>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
      {asset !== "" && (
        <>
          <div className="bottomToolbar absolute bottom-2 right-2 z-10  flex flex-col gap-2 ">
            {clientNameFromCookie !== "skyhigh" && (
              <div className="unitsSelector flex flex-row-reverse relative gap-2">
                {!unitsIsOpen ? (
                  <Tooltip title="Unit selector" arrow placement="left">
                    <button
                      className={`w-7 h-7 flex items-center justify-center  text-white bg-[#303336] border rounded   ${
                        unitsIsOpen
                          ? "border bg-[#48b] rounded"
                          : "hover:bg-[${hoverColor}] "
                      }`}
                      onClick={() => {
                        dispatch(gisToolActions.setUnitIsOpen());
                      }}
                    >
                      <img
                        className="w-6"
                        src={measuringTapeIco}
                        alt=""
                        srcSet=""
                      />
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    className={`w-7 h-7 flex items-center justify-center  text-white bg-[#303336] border rounded   ${
                      unitsIsOpen
                        ? "border bg-[#48b] rounded"
                        : "hover:bg-[${hoverColor}] "
                    }`}
                    onClick={() => {
                      dispatch(gisToolActions.setUnitIsOpen());
                    }}
                  >
                    <img
                      className="w-6"
                      src={measuringTapeIco}
                      alt=""
                      srcSet=""
                    />
                  </button>
                )}
                {unitsIsOpen && (
                  <div className=" absolute right-8 bottom-1  bg-white p-2 rounded-sm flex flex-col gap-2 bg-[#a0aab4bb]">
                    <div className="flex gap-2 justify-between items-center">
                      <p>Length</p>{" "}
                      <select
                        className={`text-xs !py-0 w-max h-7 text-white bg-[#303336] border rounded hover:bg-[${hoverColor}] relative z-10`}
                        value={selectedMeasurmentUnit}
                        onChange={(e) => handleUnitChange(e.target.value)}
                      >
                        <option className=" text-xs" value="m">
                          m
                        </option>
                        <option className=" text-xs" value="km">
                          km
                        </option>
                        <option className=" text-xs" value="ft">
                          ft
                        </option>
                      </select>
                    </div>
                    <div className="flex justify-between items-center">
                      <p>Area</p>
                      <select
                        className={`text-xs !py-0 w-max h-7 text-white bg-[#303336] border rounded hover:bg-[${hoverColor}] relative z-10`}
                        value={selectedAreaUnit}
                        onChange={(e) => handleAreaUnitChange(e.target.value)}
                      >
                        <option className=" text-xs" value="m">
                          sq m
                        </option>
                        <option className=" text-xs" value="km">
                          sq km
                        </option>
                        <option className=" text-xs" value="ft">
                          sq ft
                        </option>
                        <option className=" text-xs" value="acer">
                          acre
                        </option>
                        <option className=" text-xs" value="hector">
                          hector
                        </option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="zoomController z-10  flex flex-col text-white bg-[#303336] border rounded">
              <button
                className="hover:bg-[#a0aab46b] w-7 h-7 "
                onClick={() => handleZoom("in")}
              >
                <h2 className="font-bold">+</h2>
              </button>
              <hr />
              <button
                className="hover:bg-[#a0aab46b] w-7 h-7 "
                onClick={() => handleZoom("out")}
              >
                <h2 className="font-bold">-</h2>
              </button>
            </div>
            {imageryLabel !== "" && clientNameFromCookie !== "skyhigh" && (
              <Tooltip title="Orthographic View" arrow placement="left">
                <button
                  className={`w-7 h-7  text-white bg-[#303336] border rounded  hover:bg-[${hoverColor}]`}
                >
                  <h2 className=" font-bold">{imageryLabel}</h2>
                </button>
              </Tooltip>
            )}

            {imageryLabel !== "" && clientNameFromCookie === "skyhigh" ? (
              <Tooltip
                title={mode === "2D" ? "Switch to 3D" : "Switch to 2D"}
                arrow
                placement="left"
              >
                <button
                  onClick={() => {
                    dispatch(gisHomeActions.setMode());
                    mode === "2D"
                      ? dispatch(
                          gisHomeActions.setAsset({
                            asset: 2529502,
                            assetType: "3DTILES",
                          })
                        )
                      : dispatch(
                          gisHomeActions.setAsset({
                            asset: "SKY_HIGH_GOA_ORI",
                            assetType: "IMAGERY",
                          })
                        );
                  }}
                  className={`w-7 h-7  text-white bg-[#303336] border rounded  hover:bg-[${hoverColor}] font-semibold `}
                >
                  {mode}
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Orthographic View" arrow placement="left">
                <button
                  className={`w-7 h-7  text-white bg-[#303336] border rounded  hover:bg-[${hoverColor}]`}
                >
                  <h2 className=" font-bold">2D</h2>
                </button>
              </Tooltip>
            )}
          </div>
          <div className="bottomToolbar absolute bottom-2 right-11 z-10 flex flex-col gap-2 items-center">
            <div className="zoomController z-10  flex items-center h-7 p-1 text-white bg-[#303336] border rounded ">
              <p className="text-xs"> {cursorLatLng.lat},</p>

              <p className="text-xs">{cursorLatLng.lng}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GISToolbar;
