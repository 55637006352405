import React, { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css"; // Import Video.js CSS

const LiveVideoConatiner = (props) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    // HLS live stream URL
    const liveStreamURL = props.src;

    // Video.js options
    const options = {
      techOrder: ["html5"],
      controls: true,
      sources: [
        {
          type: "application/x-mpegURL", // HLS type
          src: liveStreamURL,
        },
      ],
      liveui: true, // Enable Live UI features
      playsinline: true,
      controlBar: {
        liveDisplay: true,
        pictureInPictureToggle: false,
        subsCapsButton: false,
      },
      autoplay: true,
    };

    // Initialize the video player
    playerRef.current = videojs(videoRef.current, options);

    // Clean up Video.js player on unmount
    // return () => {
    //   if (playerRef.current) {
    //     playerRef.current.dispose();
    //   }
    // };
  }, [props.src]); // Empty dependency array to run the effect once

  return (
    <div data-vjs-player className="h-full w-full">
      <video ref={videoRef} className="video-js h-full w-full" />
    </div>
  );
};

export default LiveVideoConatiner;
