import axios from "axios";

export class ExpressApis {
  static getAnnotation = async (id) => {
    try {
      const response = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/meta_data/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  static addAnnotation = async (params) => {
    try {
      const response = await axios.post(
        "https://delivery.inspect.indrones.com/api/v1/meta_data/create",
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  static editAnnotation = async (params) => {
    try {
      const response = await axios.post(
        "https://delivery.inspect.indrones.com/api/v1/meta_data/edit",
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  static deleteAnnotation = async (id) => {
    try {
      const response = await axios.post(
        `https://delivery.inspect.indrones.com/api/v1/meta_data/delete/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };
}
