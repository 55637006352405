import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../Components/Layouts/Header";
import Sidebar from "../../Components/Layouts/Sidebar";

const ProjectViewLayout = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const drawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const drawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <div className="flex h-full w-full flex-col overflow-y-hidden overflow-x-hidden">
      <Header drawerOpen={drawerOpen} />
      <div className="flex h-full w-[100%]">
        <Sidebar isDrawerOpen={isDrawerOpen} drawerClose={drawerClose} />
        <Outlet />
      </div>
    </div>
  );
};

export default ProjectViewLayout;
