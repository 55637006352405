import React, { useMemo, useRef, useState } from "react";
import { DynamoDBApis } from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import { Tooltip, Fade } from "@mui/material";

import "rc-pagination/assets/index.css";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css";
import { getDate } from "../../Utils/OtherUtils";
import {
  dashIco,
  checkIco,
  downIco,
  allIco,
  checkBlackIco,
  exportIco,
  icon,
} from "../../Assets/icons/gisIcons/icons";
import { useParams } from "react-router";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import html2canvas from "html2canvas";
import toast from "react-hot-toast";
import { AgGridReact } from "ag-grid-react";
import CesiumUtils from "../../Utils/CesiumUtils";

const convertDDMMYYToDate = (ddmmyy) => {
  const [day, month, year] = ddmmyy.split("_"); // Split DD_MM_YY into day, month, and year
  return new Date(`20${year}`, month - 1, day); // Assuming the year is 20YY
};

const lineChartOptions = {
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
  },

  dataLabels: {
    enabled: true,
  },

  xaxis: {
    type: "datetime",
    title: {
      text: "Timeline",
    },
  },
  yaxis: {
    title: {
      text: "Volume in m³",
    },
  },
};

const barChartOptions = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
  },

  dataLabels: {
    enabled: true,
  },

  xaxis: {
    type: "datetime",
    title: {
      text: "Timeline",
    },
  },
  yaxis: {
    title: {
      text: "Number of Sites",
    },
  },
};

const BottomReportTable = ({ isSidebaropen, openShapes, viewer }) => {
  const { grp } = useParams();
  const { grpData: groupData, asset } = useSelector((state) => state.gisHome);
  const {
    markerArray,
    polylineAnnotationsData,
    polygonAnnotationsData,
    userPermissions,
  } = useSelector((state) => state.gis);

  const chartRef = useRef(null);
  const chartRefBar = useRef(null);
  const dispatch = useDispatch();
  const [series, setSeries] = useState([]);
  const [seriesBar, setSeriesBar] = useState([]);
  const [assetFilter, setAssetFilter] = useState();
  // console.log(assetFilter, "assrt filter ");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState();
  const [severityFilter, setSeverityFilter] = useState();
  const [showReport, setShowreport] = useState(false);
  const { bottomReportToggle } = useSelector((state) => state.gisHome);

  const [selectedItems, setSelectedItems] = useState([]);
  const [isSelectableReportCheckbox, setIsSelectableReportCheckbox] =
    useState(false);
  const [searchResults, setSearchResults] = useState([]);

  // console.log(groupData, "groupData");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const retrievedData = await DynamoDBApis.getDataByFilter(
          categoryFilter,
          severityFilter,
          assetFilter ? parseInt(assetFilter) : null,
          searchQuery !== "" ? searchQuery : null,
          grp
        );

        setFilteredData(retrievedData);
        // console.log("retrievedData", retrievedData);
        // Handle the data as needed
      } catch (error) {
        // Handle the error
        console.error(error);
      }
    };
    fetchData();
  }, [
    assetFilter,
    categoryFilter,
    severityFilter,
    searchQuery,
    grp,
    markerArray,
    polylineAnnotationsData,
    polygonAnnotationsData,
  ]);

  // showing shape metrics
  const shapeMetrics = (item) => {
    let result;

    if (item?.shapeData?.shape_type === "polygon") {
      result = (item?.shapeData?.area / 1000).toFixed(2) + " sq. m";
    } else {
      result = "lat: " + item?.latitude.toFixed(4);
    }

    return <p>{result}</p>;
  };

  const predefinedCategories = [
    "Categories",
    "Garbage",
    "Debris",
    "Steel Scrap",
    "Stock Pile",
    "Other",
  ];
  const severities = ["Low", "Medium", "High"];

  const findAssetNameById = (assetId, data) => {
    const foundAsset = data.find((item) => item.id === assetId);

    return foundAsset ? getDate(foundAsset.date) : null;
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // getting time string from the timestamp
  function formatTimestamp(timestamp, dateOnly) {
    const date = new Date(timestamp);

    const year = date.getFullYear();

    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const seconds = date.getSeconds().toString().padStart(2, "0");

    var formattedDate;
    if (!dateOnly) {
      formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    } else {
      formattedDate = `${day}_${month}_${year}`;
    }

    return formattedDate;
  }

  const generateChartImage = async (chartSeries, ref, type) => {
    for (let i = 0; i < chartSeries.length; i++) {
      chartSeries[i].data.sort((a, b) => new Date(a.x) - new Date(b.x));
    }

    if (type === "line") {
      setSeries(chartSeries);
    } else {
      setSeriesBar(chartSeries);
    }

    return new Promise((resolve, reject) => {
      setTimeout(async () => {
        if (ref) {
          try {
            const canvas = await html2canvas(ref.chartRef.current, {
              useCORS: true,
            });
            const data = canvas.toDataURL("image/png");
            resolve(data);
          } catch (error) {
            console.error("Error capturing chart image:", error);
            reject(error);
          }
        }
      }, 1000);
    });
  };

  const generateChartData = async (data) => {
    setShowreport(true);
    const assetIdToDate = {};
    groupData.forEach((item) => {
      assetIdToDate[item.id] = convertDDMMYYToDate(item?.date);
    });

    const groupedByCategory = data.reduce((result, currentObject) => {
      const date = assetIdToDate[currentObject.assetId];
      const category = currentObject.category;

      if (!result[category]) {
        result[category] = { name: category, data: [] };
      }

      // Check if the category is "Stock Pile" or "Steel Scrap"
      if (category === "Stock Pile" || category === "Steel Scrap") {
        // Calculate total volume for "Stock Pile" and "Steel Scrap"
        const existingDataIndex = result[category].data.findIndex(
          (item) => item.x === date
        );

        if (existingDataIndex !== -1) {
          // If date already exists, update the volume
          result[category].data[existingDataIndex].y += currentObject.volume;
        } else {
          // If date doesn't exist, add a new entry
          result[category].data.push({ x: date, y: currentObject.volume });
        }
      } else {
        // For other categories, count the number of elements
        const existingDataIndex = result[category].data.findIndex(
          (item) => item.x === date
        );

        if (existingDataIndex !== -1) {
          // If date already exists, update the count
          result[category].data[existingDataIndex].y += 1;
        } else {
          // If date doesn't exist, add a new entry
          result[category].data.push({ x: date, y: 1 });
        }
      }

      return result;
    }, {});

    const resultArray = Object.values(groupedByCategory);

    const lineChartSeries = resultArray.filter(
      (item) => item.name === "Stock Pile" || item.name === "Steel Scrap"
    );
    const lineChart = await generateChartImage(
      lineChartSeries,
      chartRef.current,
      "line"
    );

    const barChartSeries = resultArray.filter(
      (item) => item.name !== "Stock Pile" && item.name !== "Steel Scrap"
    );
    const barChart = await generateChartImage(
      barChartSeries,
      chartRefBar.current,
      "bar"
    );

    setShowreport(false);
    return [lineChart, barChart];
  };

  // report modal
  const handleReportClick = async () => {
    toast.success("Report generation started successfully");

    const params = {};
    params.timeline = assetFilter
      ? findAssetNameById(assetFilter, groupData)
      : "Multiple";
    params.data = filteredData;
    params.createdOn = formatTimestamp(Date.now());
    params.grp = grp;
    params.grpData = groupData;
    params.charts = await generateChartData(filteredData);

    try {
      const response = await axios.post(
        "https://delivery.inspect.indrones.com/api/v1/getPdf/generate_annotation_report",
        params,
        {
          responseType: "blob", // Tell Axios to expect a binary response
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a download link and trigger a click to initiate download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `report_${formatTimestamp(Date.now(), 1)}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Report downloaded successfully");
    } catch (error) {
      // console.error("response mohit error", error);
    }
  };

  const myElementRef = useRef(null);

  const [elementWidth, setElementWidth] = useState(null);

  useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const gridRef = useRef();

  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);
  const sideBar = useMemo(() => {
    return {
      toolPanels: ["columns"],
    };
  }, []);

  const columnsOfDiff = [
    {
      headerName: "Name",
      field: "markerName",
    },

    {
      headerName: "Timeline",

      // valueGetter: (params) => GISUtils.getTimelineTitle(params.data.assetId),
      valueGetter: (params) =>
        params?.data?.assetId
          ? findAssetNameById(params?.data?.assetId, groupData)
          : "-",
    },
    // {
    //   headerName: "Type",
    //   field: "typeName",
    // },
    {
      headerName: "Category",
      field: "category",
      sortable: true,
    },
    {
      headerName: "Created On",

      valueGetter: (params) =>
        params?.data?.timestamp
          ? formatTimestamp(params?.data?.timestamp)
          : "-",
      sortable: true,
    },
    {
      headerName: "Description",
      field: "description",
    },
    {
      headerName: "ID",

      valueGetter: (params) =>
        params?.data?.id ? params?.data?.id.split("-")[0] : "-",
    },
    // {
    //   headerName: "Reviewed By",
    //   field: "reviewedBy",
    //   valueGetter: (params) => params.data.reviewedBy || "-",
    // },
    {
      headerName: "Risk",
      field: "severity",

      cellStyle: (params) => {
        if (params.value === "High") {
          return {
            color: "red",
            fontWeight: 600,
            backgroundColor: "rgb(255 0 0 / 10%)",
          };
        } else if (params.value === "Medium") {
          return {
            color: "orange",
            fontWeight: 600,
            backgroundColor: "rgb(255 165 0 / 10%)",
          };
        } else {
          return {
            fontWeight: 600,
            backgroundColor: "rgb(255 255 0 / 23%)",
          };
        }
      },
    },
    // {
    //   headerName: "Task Status",
    //   field: "taskStatus",
    //   valueGetter: (params) => params.data.taskStatus || "-",
    // },
    // {
    //   headerName: "View",
    //   cellRenderer: (params) => {
    //     return `Click to view ${params.data.markerName}`; // Adjust as needed
    //   },
    //   onCellClicked: (params) => {
    //     // handleCellClicked(params);
    //   },
    // },
  ];

  // console.log(filteredData, "filteredData");
  useEffect(() => {
    const otherItem = document.getElementById("GISSidebarBody");

    if (otherItem) {
      const width = otherItem.offsetWidth;
      const component = document.getElementById("BottomReporttable");

      if (component) {
        component.style.width = `calc(100vw - ${width}px)`;
      }
    }
  }, [isSidebaropen, openShapes]);

  return (
    <>
      <div
        className={`z-50 absolute  bottom-0  left-[100%] `}
        id="BottomReporttable"
      >
        {!bottomReportToggle && (
          <div
            onClick={() => {
              dispatch(gisHomeActions.bottomReportToggle());
            }}
            className={`w-max bg-white flex rounded-tl-md rounded-tr-md`}
          >
            <div className="bg-yellow-400 rounded-tl-md px-2">
              {" "}
              <img src={downIco} className=" rotate-180" alt="" srcSet="" />
            </div>
            <h3 className="px-2  "> Detailed Reports</h3>
          </div>
        )}

        {showReport && (
          <div id="chart" className="h-[300px] w-[500px] px-2 absolute z-[1]">
            <ReactApexChart
              options={lineChartOptions}
              series={series}
              ref={chartRef}
              type="line"
              height="100%"
              width="100%"
            />
            <ReactApexChart
              options={barChartOptions}
              series={seriesBar}
              ref={chartRefBar}
              type="bar"
              height="100%"
              width="100%"
            />
          </div>
        )}

        {bottomReportToggle && (
          <>
            <div className="  h-auto bg-white relative z-20 flex flex-col border justify-between border-l-gray-300 ">
              <div className="actionBarOfBottomReportTable flex flex-col  h-full ">
                <div className=" flex justify-between items-center h-[8vh] px-2 ">
                  <div className="h-8 flex items-center">
                    <h1 className="font-bold border-b-4 border-b-yellow-400">
                      Detailed Reports
                    </h1>
                  </div>
                  {/* filter options  */}
                  <div className="flex gap-2 py-2 pl-2 overflow-hidden">
                    <select
                      className="border border-gray-400 rounded-md text-sm min-h-[1.5rem]"
                      value={assetFilter}
                      onChange={(e) =>
                        e.target.value === "All"
                          ? setAssetFilter(null)
                          : setAssetFilter(parseInt(e.target.value))
                      }
                    >
                      <option value={"All"}>All Timelines</option>
                      {groupData?.map((groupData, index) => (
                        <option key={index} value={groupData.id}>
                          {getDate(groupData.date)}
                        </option>
                      ))}
                    </select>

                    <select
                      className="border border-gray-400 rounded-md text-sm min-h-[1.5rem] "
                      value={categoryFilter}
                      onChange={(e) =>
                        e.target.value === "All"
                          ? setCategoryFilter(null)
                          : setCategoryFilter(e.target.value)
                      }
                    >
                      {predefinedCategories.map((category, index) =>
                        category === "Categories" ? (
                          <option key={index} value="All">
                            All Categories
                          </option>
                        ) : (
                          <option key={index} value={category}>
                            {category}
                          </option>
                        )
                      )}
                    </select>

                    <select
                      className="border border-gray-400 border-[0.5px] rounded-md text-sm m-h-[1.5rem] "
                      value={severityFilter}
                      onChange={(e) => {
                        e.target.value === "All"
                          ? setSeverityFilter(null)
                          : setSeverityFilter(e.target.value);
                      }}
                    >
                      <option value="All">All Severity</option>
                      {severities.map((severity, index) => (
                        <option key={index} value={severity}>
                          {severity}
                        </option>
                      ))}
                    </select>
                    <div className="searchBarBottomReports rounded-md">
                      <input
                        type="text"
                        name="searchBar"
                        placeholder="Search "
                        value={searchQuery}
                        onChange={handleInputChange}
                        className=" rounded-md  focus:border focus:border-b-2 "
                      />

                      {/* <button onClick={handleSearch}>Search</button> */}
                    </div>
                  </div>
                </div>
                <div className="reportTableContainer flex flex-col justify-between  grow-1 ">
                  <div className="max-h-[44vh]  min-h-[24vh] border-b px-2">
                    {filteredData.length > 0 && asset ? (
                      <>
                        <div className={"ag-theme-quartz h-[35vh]"}>
                          <AgGridReact
                            ref={gridRef}
                            rowData={filteredData}
                            columnDefs={columnsOfDiff}
                            defaultColDef={{ flex: 1 }}
                            // defaultColDef={defaultColDef}
                            autoGroupColumnDef={autoGroupColumnDef}
                            sideBar={sideBar}
                            rowGroupPanelShow={"always"}
                            onRowDoubleClicked={(row) => {
                              const idToCopy = row.data.id.split("-")[0];
                              navigator.clipboard
                                .writeText(idToCopy)
                                .then(() => {
                                  toast.success(
                                    `Id ${idToCopy} copied successfully  `
                                  );
                                  // You can also provide user feedback here if needed
                                })
                                .catch((err) => {
                                  console.error(
                                    "Error copying ID to clipboard:",
                                    err
                                  );
                                  // Handle the error or provide user feedback
                                });
                            }}
                            onRowClicked={(row) => {
                              if (row.data.filter_type === "polygon") {
                                const shapeCordLen = row.data?.shapeCord.length;

                                CesiumUtils.handleFlyToMarkerUtil(
                                  viewer,
                                  row.data.filter_type,
                                  row.data.shapeCord[shapeCordLen - 1]
                                );
                              } else {
                                CesiumUtils.handleFlyToMarkerUtil(
                                  viewer,
                                  row.data.filter_type,
                                  {
                                    longitude: row.data.longitude,
                                    latitude: row.data.latitude,
                                  }
                                );
                              }
                            }}
                          />
                        </div>
                      </>
                    ) : filteredData.length === 0 && asset ? (
                      <p className="text-center mt-12">
                        No Annotations with Selected Filters
                      </p>
                    ) : (
                      <p className="text-center mt-12">
                        Please select asset from timeline.
                      </p>
                    )}
                  </div>
                  <div className="reportTableBottomBox  w-full  flex px-2 justify-between items-start  bottom-0 my-2 ">
                    <div className="flex">
                      <p className="mr-4  text-center px-1 text-sm flex items-center gap-2">
                        <span className="flex items-center ">
                          <img
                            src={allIco}
                            className=" h-5"
                            alt="All items icon of report table"
                          />
                          <p className="  font-extrabold">Total Count</p>
                        </span>
                        <span className=" px-1 py-[2px] rounded-sm min-h-4 min-w-4  bg-green-300 font-extrabold">
                          {
                            filteredData?.length /* {searchQuery !== ""
                              ? searchResults?.length
                              : filteredData?.length} */
                          }
                        </span>
                      </p>
                      {isSelectableReportCheckbox ? (
                        <p className="mr-4  text-center px-1 text-sm flex items-center gap-2">
                          <span className="flex items-center ">
                            <img
                              src={checkBlackIco}
                              className=" h-5"
                              alt="All items icon of report table"
                            />

                            <p className="  font-extrabold">Selected</p>
                          </span>
                          <span className=" px-1 py-[2px] rounded-sm  bg-blue-300 font-extrabold">
                            {selectedItems.length}
                          </span>
                        </p>
                      ) : null}
                    </div>

                    <div
                      className="bottomButtons flex items-center"
                      onClick={() => handleReportClick()}
                    >
                      {/* <Tooltip
                          title="Select"
                          placement="top"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          arrow
                        >
                          <button
                            className="mr-2 p-1 bg-blue-400 hover:bg-blue-500 active:bg-blue-700 text-white rounded-full text-sm "
                            onClick={() => {
                              setIsSelectableReportCheckbox(
                                !isSelectableReportCheckbox
                              );
                            }}
                          >
                            <img
                              src={checkIco}
                              alt="check icon"
                              className="h-4"
                              srcSet=""
                            />
                          </button>
                        </Tooltip> */}

                      {userPermissions.includes("export_reports") && (
                        <Tooltip
                          title="Export Report"
                          placement="top"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          arrow
                        >
                          <button className="py-1  px-2 bg-green-400 hover:bg-green-500 active:bg-green-700  text-white rounded-full text-sm flex gap-1 justify-end items-center">
                            <img
                              src={exportIco}
                              alt="export icon"
                              className="h-4"
                              srcSet=""
                            />{" "}
                            <span className="text-black hover:tracking-wide">
                              Export
                            </span>
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {bottomReportToggle && (
          <button
            onClick={() => {
              dispatch(gisHomeActions.bottomReportToggle());
            }}
            className={` absolute -top-6 bg-red-400 right-0 rounded-tr-md rounded-tl-md px-2`}
          >
            <img src={downIco} className="" alt="" srcSet="" />{" "}
          </button>
        )}
      </div>
    </>
  );
};

export default BottomReportTable;
