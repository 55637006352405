// this file contains default points data of groups along with getDefaultPoints function
import { cctvCamBillBoardImg } from "../../../Assets/icons/gisIcons/icons";
const agp_defaults = [
  {
    id: "document",
    name: "defaultCzml Point",
    version: "1.0",
  },
  {
    id: "default_1",
    name: "AGP Group Entrance",
    position: {
      cartographicDegrees: [72.9957777778, 19.1829913519, 0],
    },
    width: "50 Meters",
    type: "Infrastructure",
    road_segment_material: "Asphalt",
    lanes: 2,
    road_segment_type: "Straight Road",
    road_segment_length: "773 Kms",
    latitude: 72.9957777778,
    longitude: 19.1829913519,
    description:
      '[{"date": "07 Feb 2022","link": "https://dgi6n13b4acc9.cloudfront.net/d715f3cf-9f6d-4672-a2a7-e64919009f36/AppleHLS1/AGP_Entrance_V2.m3u8"},{"date": "13 Apr 2022","link": "https://dgi6n13b4acc9.cloudfront.net/76f43b9d-d150-4a4d-9baf-4f3afe95a6ff/AppleHLS1/AGP_Entrance_V1.m3u8"}]',
    billboard: {
      image: cctvCamBillBoardImg,
      height: 40,
      width: 40,
    },
  },
  {
    id: "default_2",
    name: "AGP Group Boundaries",
    position: {
      cartographicDegrees: [73.0048748402, 19.1833599149, 0],
    },
    type: "Infrastructure",
    perimeter: "2.15 Kms",
    thickness: "1.2 Feets",
    latitude: 73.0048748402,
    longitude: 19.1833599149,
    average_height: "4.7 Feets",
    phase: "PLANNING",
    description:
      '[{"date": "07 Feb 2022","link": "https://dgi6n13b4acc9.cloudfront.net/8553b586-997a-4fc9-99c6-a14b5283220e/AppleHLS1/AGP_Boundary_V2.m3u8"},{"date": "13 Apr 2022","link": "https://dgi6n13b4acc9.cloudfront.net/9632e73e-285d-4f95-8b18-f4e387b3384a/AppleHLS1/AGP_Boundary_V1.m3u8"}]',
    billboard: {
      image: cctvCamBillBoardImg,
      height: 40,
      width: 40,
    },
  },
  {
    id: "default_3",
    name: "AGP Group DC Phase-1",
    position: {
      cartographicDegrees: [73.004120205, 19.1890632388, 0],
    },
    type: "Infrastructure",
    latitude: 73.004120205,
    longitude: 19.1890632388,
    phase: "DESIGNING",
    description:
      '[{"date": "07 Feb 2022","link": "https://dgi6n13b4acc9.cloudfront.net/4961c09e-baf7-4e2a-b163-deab71d80900/AppleHLS1/AGP_DC_Phase_1_V2.m3u8"},{"date": "13 Apr 2022","link": "https://dgi6n13b4acc9.cloudfront.net/b4817642-d254-4b55-ada5-a8904517278c/AppleHLS1/AGP_Boundary_V1_2.m3u8"}]',
    billboard: {
      image: cctvCamBillBoardImg,
      height: 40,
      width: 40,
    },
  },
];

const kmda_defaults = [
  {
    id: "document",
    name: "defaultCzml Point",
    version: "1.0",
  },
  {
    id: "default_kmda_1",
    name: "Dunlop Canal",
    position: {
      cartographicDegrees: [88.3756199817, 22.6668490152, 0],
    },
    description:
      '[{"date": "04 Aug 2023","link": "https://dgi6n13b4acc9.cloudfront.net/563f2b25-6233-48e8-b7cd-ad224a7f1075/AppleHLS1/DJI_0944.m3u8"}]',
    width: "50 Meters",
    type: "Infrastructure",
    road_segment_material: "Asphalt",
    lanes: 2,
    road_segment_type: "Straight Road",
    road_segment_length: "773 Kms",
    latitude: 88.3741245662,
    longitude: 22.664419492,
    billboard: {
      image: cctvCamBillBoardImg,
      height: 40,
      width: 40,
    },
  },
];

/**
 * Util function which returns default czml points as per group
 *
 * @param {string} group - selected group name
 * @returns {string[]} default_pnts
 * @version 1.0.0
 */

export function getDefaultPoints(group) {
  var default_pnts = [];

  if (group === "AGP") {
    default_pnts = agp_defaults;
  } else if (group === "KMDA") {
    default_pnts = kmda_defaults;
  }

  return default_pnts;
}
