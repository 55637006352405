import React from "react";
// import "../../styles/video-canvas-respo.css";
import Loader from "@mui/material/CircularProgress";
import Hls from "hls.js";
import { useSelector } from "react-redux";

const VideoPlayer = (props) => {
  const canvasRef = React.useRef(null);
  const hlsRef = React.useRef(null);
  const videoRef = props.videoRef;
  const videoSrc = useSelector((state) => state.project.videoSrc);
  const { currentLevel, getQualityLevels } = props;
  const level = currentLevel;

  React.useEffect(() => {
    let hls;

    if (videoRef.current) {
      const video = videoRef.current;

      if (Hls.isSupported() && !hlsRef.current) {
        hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          const levels = hls.levels;
          getQualityLevels(levels);
        });

        hlsRef.current = hls;
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
        hlsRef.current = null;
      }
    };
  }, [videoSrc, videoRef]);

  React.useEffect(() => {
    if (hlsRef.current) {
      hlsRef.current.currentLevel = level;
    }
  }, [level]);

  React.useEffect(() => {
    const canvas = canvasRef.current;
    const videoRef = props.videoRef;
    const context = canvas.getContext("2d");
    let animationFrameId;

    const renderFrame = () => {
      const video = videoRef.current;
      if (video) {
        const aspectRatio = video.videoWidth / video.videoHeight;

        const canvasWidth = canvas.width;
        const canvasHeight = canvasWidth / aspectRatio;

        const offsetX = (canvas.width - canvasWidth) / 2;
        const offsetY = (canvas.height - canvasHeight) / 2;

        context.drawImage(video, offsetX, offsetY, canvasWidth, canvasHeight);

        animationFrameId = requestAnimationFrame(renderFrame);
      }
    };

    const videoCanPlayHandler = () => {
      if (videoRef) {
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        renderFrame();
      }
    };

    videoRef.current.addEventListener("canplay", videoCanPlayHandler);

    return () => {
      cancelAnimationFrame(animationFrameId);
      if (videoRef.current) {
        videoRef.current.removeEventListener("canplay", videoCanPlayHandler);
      }
    };
  }, [props.videoRef]);

  return (
    <div className="relative min-[700px]:h-full w-[67%] max-[700px]:w-[100%] border-l ml-2 mr-2 bg-gray-200 flex items-center parent-canvas-video-respo">
      {props.showLoader && (
        <div className="absolute top-0 items-center flex bg-black w-full h-full justify-center z-[1]">
          <Loader className="!w-[60px] !h-[60px]" />
        </div>
      )}
      <div className="h-full w-full">
        <video
          onLoadedMetadata={props.handleDuration}
          onTimeUpdate={props.handleTimeUpdate}
          ref={videoRef}
          muted
          autoPlay
          onClick={props.handlePlayPause}
          className="h-full w-full hidden"
          style={{
            objectFit: "fill",
          }}
        />
        <canvas
          ref={canvasRef}
          className="h-[100%] w-[100%] canvas-video-respo"
          onClick={props.handlePlayPause}
          style={{ transform: "translateZ(0)", objectFit: "fill" }}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
