import React from "react";
import AnnotationBox from "../../Components/Video/AnnotationBox";
import { useNavigate, useParams } from "react-router-dom";
import Controls from "../../Components/Video/Controls";
import VideoPlayer from "../../Components/Video/VideoPlayer";
import Header from "../../Components/Layouts/Header";
import SidePanel from "../../Components/Layouts/SidePanel";
import { useDispatch, useSelector } from "react-redux";
import { projectActions } from "../../redux/slices/Videography/set-project-slice";
import { ExpressApis } from "../../Services/SecondPartyApi/Express";
import { Toaster } from "react-hot-toast";
import { tokenChecker } from "../../Features/GIS/Utils/CookieUtils";
import axios from "axios";

const SingleScreen = () => {
  const videoRef = React.useRef(null);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [duration, setDuration] = React.useState(0);
  const [annoChange, setAnnoChange] = React.useState(0);
  const [showLoader, setShowLoader] = React.useState(true);
  const [qualityLevels, setQualityLevels] = React.useState([]);
  const [currentLevel, setCurrentLevel] = React.useState(-1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { project } = useParams();

  React.useEffect(() => {
    const getTimelineData = async () => {
      const response = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/timeline/${project}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("timeline", response.data);
      dispatch(
        projectActions.setProjects({ data: response.data, id: project })
      );
    };
    getTimelineData();
  }, [project, annoChange]);

  // React.useEffect(() => {
  //   const getMetaData = async () => {
  //     const response = await ExpressApis.getAnnotation(project);
  //     dispatch(
  //       projectActions.setProjects({ data: response.data, id: project })
  //     );
  //   };
  //   getMetaData();
  // }, [project, annoChange]);

  React.useEffect(() => {
    tokenChecker(navigate);
  }, [navigate]);

  const pauseVideos = () => {
    videoRef.current.pause();
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const getQualityLevels = (levels) => {
    setQualityLevels(levels);
  };

  const setLevel = (index) => {
    setCurrentLevel(index);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const handleDuration = () => {
    setDuration(videoRef.current.duration);
    setShowLoader(false);
  };

  const handleAnnoSeek = (time) => {
    videoRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleSeek = (e) => {
    const time = parseFloat(e.target.value);
    videoRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const handleAnnoRefresh = () => {
    setAnnoChange(annoChange + 1);
  };

  return (
    <>
      <div className="h-full w-auto flex flex-col">
        <Header />
        <div className="grow flex formobilevew">
          <SidePanel
            currentTime={currentTime}
            pauseVideos={pauseVideos}
            handleAnnoRefresh={handleAnnoRefresh}
          />
          <div className="h-full w-[90%] grow flex flex-col max-[700px]:w-[100%]  max-[700px]:h-[86%] videoandAnnotations">
            <div className="flex mb-2 video-canvas-respo-view">
              <AnnotationBox
                handleAnnoSeek={handleAnnoSeek}
                currentTime={currentTime}
                annoChange={annoChange}
                handleAnnoRefresh={handleAnnoRefresh}
              />
              <VideoPlayer
                handleTimeUpdate={handleTimeUpdate}
                videoRef={videoRef}
                handleDuration={handleDuration}
                handlePlayPause={handlePlayPause}
                showLoader={showLoader}
                getQualityLevels={getQualityLevels}
                currentLevel={currentLevel}
              />
            </div>
            <div className="grow m-2 border rounded flex flex-col">
              <Controls
                videoRef={videoRef}
                handlePlayPause={handlePlayPause}
                isPlaying={isPlaying}
                currentTime={currentTime}
                duration={duration}
                handleSeek={handleSeek}
                qualityLevels={qualityLevels}
                setLevel={setLevel}
              />
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          duration: 2000,
          style: {
            background: "#fff",
            color: "#4a4844",
            border: "1px solid #fff",
          },
        }}
      />
    </>
  );
};

export default SingleScreen;
