import React from "react";
import Hls from "hls.js";

const VideoContainer = (props) => {
  const hlsRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const videoSrc = props.src;

  React.useEffect(() => {
    let hls;

    if (videoRef.current) {
      const video = videoRef.current;

      if (Hls.isSupported() && !hlsRef.current) {
        hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          hls.loadLevel = 3;
          hls.startLoad();
        });

        hlsRef.current = hls;
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      }
    }

    // return () => {
    //   if (hlsRef.current) {
    //     hlsRef.current.destroy();
    //     hlsRef.current = null;
    //   }
    // };
  }, [videoSrc, videoRef]);

  return (
    <div className="h-full w-full">
      <video
        ref={videoRef}
        muted
        autoPlay
        controls
        className="h-full w-full"
        style={{
          objectFit: "fill",
        }}
      />
    </div>
  );
};

export default VideoContainer;
