import * as AWS from "aws-sdk";
import {
  IvsClient,
  CreateChannelCommand,
  DeleteChannelCommand,
} from "@aws-sdk/client-ivs";

var config = new AWS.Config({
  region: "ap-south-1",
  secretAccessKey: "aNxVVkZLBNu3aneI85TrHI6pPyIzAFb72bDKlvXP",
  accessKeyId: "AKIAX546GMWHGDP77WX6",
});

const ivs = new IvsClient(config);

export const createStream = async (streamName) => {
  const channelParams = {
    authorized: false,
    latencyMode: "LOW",
    name: streamName,
    insecureIngest: true,
    recordingConfigurationArn:
      "arn:aws:ivs:ap-south-1:545255744910:recording-configuration/wGsVs9IIk7zo",
  };
  const createChannel = new CreateChannelCommand(channelParams);
  const channel_response = await ivs.send(createChannel);
  const playback_url = channel_response.channel.playbackUrl;
  const rtmp_link = `rtmp://${channel_response.channel.ingestEndpoint}/app/${channel_response.streamKey.value}`;
  return {
    channel_arn: channel_response.channel.arn,
    rtmp_link: rtmp_link,
    playback_url: playback_url,
  };
};

export const deleteChannel = async (channelArn) => {
  const command = new DeleteChannelCommand({
    arn: channelArn,
  });
  const response = await ivs.send(command);
  return response;
};
