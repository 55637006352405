import React from "react";
import Header from "../../Components/Layouts/Header";
import AnnotationBox from "../../Components/Video/AnnotationBox";
import MapBox from "../../Components/Map/MapBox";
import Controls from "../../Components/Video/Controls";
import Loader from "@mui/material/CircularProgress";
import { useNavigate, useParams } from "react-router-dom";
import { formatTime, convertTimeToSeconds } from "../../Utils/OtherUtils";
import Hls from "hls.js";
import SidePanel from "../../Components/Layouts/SidePanel";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { projectActions } from "../../redux/slices/Videography/set-project-slice";
import { ExpressApis } from "../../Services/SecondPartyApi/Express";
import { Toaster } from "react-hot-toast";
import { tokenChecker } from "../../Features/GIS/Utils/CookieUtils";
import axios from "axios";

const findCorrespondingtime = (time, firstDatabase, secondDatabase) => {
  const formatedTime = formatTime(time);
  var correspondingChainage = null;

  const foundData = firstDatabase.find(
    (data) => data.start_time === formatedTime
  );
  correspondingChainage = foundData.chainage;

  let left = 0;
  let right = secondDatabase.length - 1;
  let closestChainage = null;
  let videoTime = null;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const chainage = secondDatabase[mid].chainage;

    if (chainage === correspondingChainage) {
      closestChainage = chainage;
      videoTime = secondDatabase[mid].start_time;
      break;
    }

    if (
      closestChainage === null ||
      Math.abs(chainage - correspondingChainage) <
        Math.abs(closestChainage - correspondingChainage)
    ) {
      closestChainage = chainage;
      videoTime = secondDatabase[mid].start_time;
    }

    if (chainage < correspondingChainage) {
      left = mid + 1;
    } else {
      right = mid - 1;
    }
  }
  return convertTimeToSeconds(videoTime);
};

const CompareScreen = () => {
  const videoRef_R5 = React.useRef(null);
  const videoRef_R4 = React.useRef(null);
  const [currentTime_R5, setCurrentTime_R5] = React.useState(0);
  const [duration_R5, setDuration_R5] = React.useState(0);
  const [currentTime_R4, setCurrentTime_R4] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [annoChange, setAnnoChange] = React.useState(0);
  const [lastSyncedTime, setLastSyncedTime] = React.useState(null);
  const [loaderR5, setLoaderR5] = React.useState(true);
  const [loaderR4, setLoaderR4] = React.useState(true);
  const [db1, setDb1] = React.useState([]);
  const [db2, setDb2] = React.useState([]);
  const [videoSrc1, setVideoSrc1] = React.useState(null);
  const [videoSrc2, setVideoSrc2] = React.useState(null);
  const showMap = useSelector((state) => state.mapToggle.showMap);
  const dispatch = useDispatch();
  const { ids } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    tokenChecker(navigate);
  }, [navigate]);

  React.useEffect(() => {
    var project = ids.split("").map(Number);
    const getMetaData = async () => {
      const timeline_1 = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/timeline/${project[0]}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const timeline_2 = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/timeline/${project[1]}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setDb1(timeline_1.data.MetaData);
      setDb2(timeline_2.data.MetaData);
      setVideoSrc1(timeline_1.data.video_src);
      setVideoSrc2(timeline_2.data.video_src);
      // console.log("timeline12", timeline_1, timeline_2.video_src);
      dispatch(
        projectActions.setCompareProjects({
          data1: timeline_1.data.MetaData,
          data2: timeline_2.data.MetaData,
          id: project,
        })
      );
    };
    getMetaData();
  }, [ids, annoChange]);

  var round1, round2, annotation;
  if (ids === "12") {
    round1 =
      "https://dgi6n13b4acc9.cloudfront.net/211817fa-4094-4cf7-af65-d4df403fd4ab/AppleHLS1/PNVL_KJT_R4_final_concat.m3u8";

    round2 =
      "https://dgi6n13b4acc9.cloudfront.net/a13a1a88-da7c-4f15-892e-845cf313c5ee/AppleHLS1/PNVL_KJT_R5_final_concat.m3u8";

    annotation = ["R4", "R5"];
  } else if (ids === "23") {
    round1 =
      "https://dgi6n13b4acc9.cloudfront.net/211817fa-4094-4cf7-af65-d4df403fd4ab/AppleHLS1/PNVL_KJT_R4_final_concat.m3u8";

    round2 =
      "https://dgi6n13b4acc9.cloudfront.net/e0cfba2c-c3c9-4c17-a9dd-2b8be88f4356/AppleHLS1/PNVL_KJT_R6_final_concat.m3u8";

    annotation = ["R4", "R6"];
  } else {
    round1 =
      "https://dgi6n13b4acc9.cloudfront.net/a13a1a88-da7c-4f15-892e-845cf313c5ee/AppleHLS1/PNVL_KJT_R5_final_concat.m3u8";

    round2 =
      "https://dgi6n13b4acc9.cloudfront.net/e0cfba2c-c3c9-4c17-a9dd-2b8be88f4356/AppleHLS1/PNVL_KJT_R6_final_concat.m3u8";

    annotation = ["R5", "R6"];
  }

  // Function to create the video.js player
  const createVideoPlayer = (url, ref) => {
    let hls;

    if (ref.current) {
      const video = ref.current;

      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // Access the quality levels array
          // const levels = hls.levels;
          hls.loadLevel = 4;
          hls.startLoad();
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = url;
      }
    }
  };

  // Handle creation and updates of the video.js player
  React.useEffect(() => {
    if (videoSrc1 && videoSrc2) {
      // console.log("videoSrc1", videoSrc1, videoSrc2);
      createVideoPlayer(videoSrc1, videoRef_R5); // Call the function with the current round1 URL
      createVideoPlayer(videoSrc2, videoRef_R4);
    }
  }, [videoSrc1, videoSrc2]);

  const handleTimeUpdateR5 = () => {
    setCurrentTime_R5(videoRef_R5.current.currentTime);
  };

  const handleDuration = () => {
    setDuration_R5(videoRef_R5.current.duration);
    setLoaderR5(false);
  };

  const handleDurationR4 = () => {
    setDuration_R5(videoRef_R4.current.duration);
    setLoaderR4(false);
  };

  const handleTimeUpdateR4 = () => {
    setCurrentTime_R4(videoRef_R4.current.currentTime);
  };

  const handlePlayPause = () => {
    if (videoRef_R5.current.paused || videoRef_R4.current.paused) {
      playVideos();
    } else {
      pauseVideos();
    }
  };

  const pauseVideos = () => {
    videoRef_R5.current.pause();
    videoRef_R4.current.pause();
    setIsPlaying(false);
  };

  const playVideos = () => {
    videoRef_R5.current.play();
    videoRef_R4.current.play();
    setIsPlaying(true);
  };

  const playVideosWhenReady = () => {
    if (videoRef_R4 && videoRef_R5) {
      const waitForVideosLoad = () => {
        if (
          videoRef_R5.current.readyState >= 2 &&
          videoRef_R4.current.readyState >= 2
        ) {
          playVideos();
        } else {
          setTimeout(waitForVideosLoad, 200);
        }
      };
      waitForVideosLoad();
    }
  };

  //Create a function to reduce repeatation
  const handleSeek = (e) => {
    pauseVideos();
    const time = parseFloat(e.target.value);
    const time_2 = findCorrespondingtime(time, db1, db2);
    videoRef_R5.current.currentTime = time;
    videoRef_R4.current.currentTime = time_2;
    setCurrentTime_R5(time);
    setCurrentTime_R4(time_2);
    playVideosWhenReady();
  };

  const handleAnnoSeek = (vTime, round) => {
    if (round === "round1") {
      pauseVideos();
      const time = vTime;
      const time_2 = findCorrespondingtime(time, db1, db2);
      videoRef_R5.current.currentTime = time;
      videoRef_R4.current.currentTime = time_2;
      setCurrentTime_R5(time);
      setCurrentTime_R4(time_2);
      playVideosWhenReady();
    } else {
      pauseVideos();
      const time = vTime;
      const time_2 = findCorrespondingtime(time, db2, db1);
      videoRef_R4.current.currentTime = time;
      videoRef_R5.current.currentTime = time_2;
      setCurrentTime_R5(time_2);
      setCurrentTime_R4(time);
      playVideosWhenReady();
    }
  };

  if (
    currentTime_R5 &&
    parseInt(currentTime_R5) > 0 &&
    parseInt(currentTime_R5) % 10 === 0
  ) {
    const currentTime_R5Parsed = parseInt(currentTime_R5);
    if (currentTime_R5Parsed !== lastSyncedTime) {
      const time_R4 = findCorrespondingtime(currentTime_R5Parsed, db1, db2);
      videoRef_R4.current.currentTime = time_R4;
      setLastSyncedTime(currentTime_R5Parsed);
    }
  }

  const handleAnnoRefresh = () => {
    setAnnoChange(annoChange + 1);
  };

  return (
    <>
      <div className="h-full w-full flex flex-col">
        <Header />
        <div className="formobilevew grow flex">
          <SidePanel
            currentTime={currentTime_R5}
            currentTime_R4={currentTime_R4}
            pauseVideos={pauseVideos}
            handleAnnoRefresh={handleAnnoRefresh}
          />
          <div className="flex w-[90%] flex-col h-full grow">
            <div className="flex h-[60%] p-[0.5rem] mb-[5px] bg-[#e5e7eb] gap-2">
              <div className="relative h-full w-[50%] flex justify-center">
                {loaderR5 && (
                  <div className="absolute top-0 items-center flex bg-black w-full h-full justify-center z-[1]">
                    <Loader className="!w-[60px] !h-[60px]" />
                  </div>
                )}
                <div className="h-full w-full">
                  <video
                    ref={videoRef_R5}
                    onTimeUpdate={handleTimeUpdateR5}
                    onLoadedMetadata={handleDuration}
                    className="video-js h-full w-full"
                    style={{
                      objectFit: "fill",
                    }}
                  />
                </div>
              </div>
              <div className="relative h-full w-[50%] flex justify-center">
                {loaderR4 && (
                  <div className="absolute top-0 items-center flex bg-black w-full h-full justify-center z-[1]">
                    <Loader className="!w-[60px] !h-[60px]" />
                  </div>
                )}
                <div className="h-full w-full">
                  <video
                    ref={videoRef_R4}
                    onTimeUpdate={handleTimeUpdateR4}
                    onLoadedMetadata={handleDurationR4}
                    className="video-js h-full w-full"
                    muted
                    style={{
                      objectFit: "fill",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="h-[15%] border mx-2  my-2 rounded-lg">
              <Controls
                videoRef={videoRef_R5}
                currentTime={currentTime_R5}
                currentTime_R4={currentTime_R4}
                duration={duration_R5}
                compare={true}
                handlePlayPause={handlePlayPause}
                isPlaying={isPlaying}
                handleSeek={handleSeek}
              />
            </div>
            <div className="flex h-[23%] grow w-full">
              <div className={`m-2 ${showMap ? "w-[35%]" : "w-[50%]"}`}>
                <AnnotationBox
                  round={1}
                  handleAnnoSeek={handleAnnoSeek}
                  currentTime={currentTime_R5}
                  handleAnnoRefresh={handleAnnoRefresh}
                />
              </div>
              {showMap && (
                <div className="m-2 grow">
                  <MapBox currentTime={currentTime_R5} />
                </div>
              )}
              <div className={`m-2 ${showMap ? "w-[35%]" : "w-[50%]"}`}>
                <AnnotationBox
                  handleAnnoSeek={handleAnnoSeek}
                  round={0}
                  currentTime={currentTime_R4}
                  handleAnnoRefresh={handleAnnoRefresh}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{
          duration: 2000,
          style: {
            background: "#fff",
            color: "#4a4844",
            border: "1px solid #fff",
          },
        }}
      />
    </>
  );
};

export default CompareScreen;
