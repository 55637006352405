import { convertTimeToSeconds } from "./OtherUtils";

const filterlabel = (label) => {
  if (label < 0) {
    return;
  } else if ((label.toFixed(1) * 10) % 2 === 0) {
    return label.toFixed(1);
  }
};

export const getLatLon = (data) => {
  var latLon = [];
  for (let i = 0; i < data.length; i++) {
    var obj = {
      lat: data[i].lat,
      lon: data[i].lon,
      time: convertTimeToSeconds(data[i].start_time),
      chainage: data[i].chainage,
    };
    latLon.push(obj);
  }
  return latLon;
};

export const getChainage = (data) => {
  var chainages = [];
  for (let i = 0; i < data.length - 1; i++) {
    var Chainobj = {
      value: convertTimeToSeconds(data[i].start_time),
      label: filterlabel(data[i].chainage),
      tobeFiltered: true,
    };
    chainages.push(Chainobj);
  }
  var Firstobj = {
    value: convertTimeToSeconds(data[0].start_time),
    label: data[0].chainage.toFixed(1),
    tobeFiltered: false,
  };
  chainages.push(Firstobj);
  var Lastobj = {
    value: convertTimeToSeconds(data[data.length - 1].start_time),
    label: data[data.length - 1].chainage.toFixed(1),
    lastobj: true,
  };
  chainages.push(Lastobj);
  return chainages;
};

export const getAnnotation = (data) => {
  //   console.log(data);
  var annotations = [];
  for (let i = 0; i < data.length; i++) {
    var annoObj = {};
    if (data[i].description) {
      annoObj.id = data[i].id;
      annoObj.timeInSec = convertTimeToSeconds(data[i].start_time);
      annoObj.time = data[i].start_time;
      annoObj.description = data[i].description;
      annoObj.chainage = data[i].chainage.toFixed(1);
      annoObj.endTime = convertTimeToSeconds(data[i].end_time);
      annoObj.severity = "low";
      annotations.push(annoObj);
    }
  }
  return annotations;
};
