import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userdetails",
  initialState: {
    first_name: "Test",
    last_name: "User",
    role: "Developer",
    is_first_login: false,
  },
  reducers: {
    setUser(state, action) {
      const userDetails = action.payload;
      state.first_name = userDetails.first_name;
      state.last_name = userDetails.last_name;
      state.role = userDetails.role_name;
      state.is_first_login = userDetails.is_first_time_login;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
