import React, { useEffect } from "react";
import inspectlogo from "../../Assets/images/inspect-single-logo.png";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import { TextField } from "@mui/material";
import { useNavigate, NavLink, useParams, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { validEmail } from "./Regex";
import toast, { Toaster } from "react-hot-toast";
import { FormControl } from "@mui/base";
import { removeAllCookies } from "../../Utils/Cookie";
import { InchargeApis } from "../../Services/SecondPartyApi/Incharge";
import { userActions } from "../../redux/slices/user-slice";
import { useDispatch } from "react-redux";
import axios from "axios";

const SkyHighLogin = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const [MailAlertMge, setMailAlertMge] = React.useState("");
  const [PassAlertMge, setPassAlertMge] = React.useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    // removeAllCookies();
    Cookies.set("clientName", "skyhigh");
  }, []);

  const cookieValue = Cookies.get("clientName");

  const handleEmailChange = (e) => {
    setMailAlertMge("");
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassAlertMge("");
    setPassword(e.target.value);
  };
  const params = {};
  const handleSubmit = async (event) => {
    event.preventDefault();
    const username = event.target.email.value;
    const password = event.target.password.value;
    params.username = username;
    params.password = password;
    params.user_app = "Inspect";
    if (username !== "") {
      const { token, userDetails, status } =
        await InchargeApis.authenticateUser(params);
      if (
        userDetails.email === "arpan.kumar@gmail.com" &&
        cookieValue !== "rail"
      ) {
        alert("You are not allowed to view this service!!!");
      } else if (status === 200) {
        const { access, refresh } = token;

        const getPerms = await axios.get(
          `https://delivery.inspect.indrones.com/api/v1/role_permission/asigned_permission/${userDetails.role_name}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        Cookies.set("user_permissions", getPerms.data.permissions);
        Cookies.set("access_token", access);
        dispatch(userActions.setUser(userDetails));
        Cookies.set("userId", userDetails.user_id);
        Cookies.set("userEmail", userDetails.email);
        if (cookieValue === "hway") {
          navigate("/nhai");
        } else {
          navigate("/projects");
        }
      } else if (status === 404) {
        var userid = toast.error("User not found!");
      } else {
        var wrongid = toast.error("Wrong password!");
      }
    }

    if (username === "") {
      toast.remove(wrongid);
      toast.remove(userid);
      setMailAlertMge("Please enter your email");
    } else if (!validEmail.test(email)) {
      toast.remove(wrongid);
      toast.remove(userid);
      setMailAlertMge("Your email is invalid");
    }
    // password conditions
    if (password === "") {
      toast.remove(wrongid);
      toast.remove(userid);
      setPassAlertMge("Please enter your password");
    } else if (password.length <= 3) {
      toast.remove(wrongid);
      toast.remove(userid);
      setPassAlertMge("Please enter corrent password");
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  return (
    <div className="flex-1 flex w-[100%] h-full items-center justify-between pt-[7%] pb-[3%] flex-col max-[600px]:px-[3%]">
      <div className="flex flex-col items-center w-[50%] max-w-[448px] signeuppage">
        <div className="w-[100px] mt-[10px] mb-[10px]">
          <img src={inspectlogo} alt="LOGO" />
        </div>
        <div className="text-[2rem] font-[600] mt-[10px] tracking-[0.6px] text-[#4a4844] text-center">
          Welcome to Inspect
        </div>
        <div className="mt-[10px] mb-[3rem] text-[#afaeae] font-[500] w-[100%] text-center tracking-[0.6px]">
          <p>Your Infrastructure Management Platform</p>
        </div>
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="mb-[1.5rem] relative">
            <TextField
              id="outlined-Email-input"
              name="email"
              className="w-full"
              variant="outlined"
              label="Email"
              autoComplete="email"
              value={email}
              type="text"
              size="small"
              onChange={handleEmailChange}
              style={{ fontSize: "17px" }}
            />
            <div
              className={`text-[#ff0000] text-[11px] font-500 mt-[2px] absolute left-0`}
            >
              {MailAlertMge}
            </div>
            <div className="loginpage">
              {<AlternateEmailRoundedIcon sx={{ fontSize: 18 }} />}
            </div>
          </div>
          <div className="mb-[20px] relative">
            <FormControl fullWidth size="small">
              <TextField
                id="outlined-password-input"
                className="w-full"
                variant="outlined"
                name="password"
                type={showPassword ? "text" : "password"}
                label="Password"
                size="small"
                autoComplete="current-password"
                // value={password}
                onChange={handlePasswordChange}
                style={{ fontSize: "17px" }}
              />
            </FormControl>
            <div
              className={`text-[#ff0000] text-[11px] mt-[2px] font-500 absolute left-0`}
            >
              {PassAlertMge}
            </div>

            <div className="loginpage" onClick={handleClickShowPassword}>
              {showPassword ? (
                <VisibilityRoundedIcon
                  className="cursor-pointer"
                  sx={{ fontSize: 18 }}
                />
              ) : (
                <VisibilityOffRoundedIcon
                  className="cursor-pointer"
                  sx={{ fontSize: 18 }}
                />
              )}
            </div>
          </div>
          <div className="text-[#4a4844] font-[600] text-[12px] text-right cursor-pointer">
            Forgot Password
          </div>
          <div className="mt-[3rem]">
            <button
              className="padding-[13px] items-center h-[42px] flex w-full justify-center rounded-[3px] bg-[#4a4844] px-3 py-1.5 text-[16px] font-semibold leading-6 text-white shadow-sm hover:bg-[#808787] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              type="submit"
            >
              Login
            </button>
          </div>
          <Toaster
            position="top-center"
            reverseOrder={true}
            toastOptions={{
              duration: 2000,
              style: {
                background: "#fff",
                color: "#4a4844",
                border: "1px solid #fff",
              },
            }}
          />
        </form>
      </div>
      <div className="text-[13px]  text-[#afaeae] font-[500]">
        Don't have an account yet?
        <span className="text-[#4a4844] font-[600] text-[13px] text-right ml-[8px] cursor-pointer">
          <NavLink to="/signup">Sign Up</NavLink>
        </span>
      </div>
    </div>
  );
};

export default SkyHighLogin;
