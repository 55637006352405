import { useEffect } from "react";
import {
  Color,
  IonResource,
  GeoJsonDataSource,
  ScreenSpaceEventType,
  defined,
  Cartographic,
  Math,
  KmlDataSource,
  Cartesian3,
} from "cesium";
import { useSelector, useDispatch } from "react-redux";
import { gisActions } from "../../../redux/slices/GIS/gis-slice";
import { useParams } from "react-router-dom";

/**
 * Custom React hook for displaying GeoJSON data on a CesiumJS map and handling interactions.
 *
 * @param {number[]} shapesIds - An array of selected shape IDs.
 * @param {object} viewerRef - A React ref object pointing to the Cesium viewer component.
 * @param {boolean} setZoomLevel - Function to set the zoom level.
 * @returns {void}
 * @version 1.0.0
 */

export function useGeoJsonOnMap(viewerRef) {
  const { shapesIds, asset } = useSelector((state) => state.gisHome);
  const { refCounter } = useSelector((state) => state.gis);
  const { selectedTool } = useSelector((state) => state.gisTools);

  const dispatch = useDispatch();
  const { grp } = useParams();

  const { showComparer, annotationTool, measureToolToggle, polygonAnnotation } =
    useSelector((state) => state.gisTools);

  useEffect(() => {
    const highlighted = {
      feature: undefined,
      originalColor: new Color(),
    };
    const viewer = viewerRef.current;
    const removeAllDataSources = () => {
      if (viewer) {
        viewer.dataSources.removeAll();
      }
    };
    const showGeoJsonOnMap = async (shapesIds) => {
      if (!shapesIds || shapesIds.length === 0) {
        removeAllDataSources();
        return;
      }
      if (viewerRef.current && shapesIds && shapesIds.length > 0) {
        removeAllDataSources();

        const colors = [
          new Color(1.0, 1.0, 0.5, 0.3), // Enhanced Yellow: Bright and vibrant yellow with 30% opacity
          new Color(0.2, 1.0, 0.0, 0.3), // Enhanced Green: Bright and vivid green with 30% opacity
          new Color(0.0, 0.5, 1.0, 0.3), // Enhanced Blue: Bright and vivid blue with 30% opacity
          new Color(0.5, 0.0, 0.5, 0.3), // New Color: Purple with 30% opacity
          new Color(1.0, 0.6, 0.0, 0.3), // Enhanced Orange: Distinct orange with 30% opacity
          new Color(0.0, 0.8, 0.8, 0.3), // Enhanced Cyan: Bright cyan with 30% opacity
          new Color(0.6, 0.0, 1.0, 0.3), // Enhanced Purple: Rich purple with 30% opacity
        ];

        for (let i = 0; i < shapesIds.length; i++) {
          const assetId = shapesIds[i];
          const colorIndex = i % colors.length;

          try {
            if (grp !== "PIL") {
              const geoJsonResource = await IonResource.fromAssetId(assetId);
              const geoJsonDataSource = new GeoJsonDataSource(
                `customGeoJson_${assetId}`
              );

              await geoJsonDataSource.load(geoJsonResource);

              geoJsonDataSource.entities.values.forEach((entity) => {
                if (entity.polygon) {
                  entity.polygon.material = colors[colorIndex];
                  entity.polygon.outlineColor = colors[colorIndex];
                }
                if (entity.polyline) {
                  entity.polyline.material = colors[colorIndex];
                }
              });

              viewer.dataSources.add(geoJsonDataSource);

              // on left click showing the info of selected area
              if (
                showComparer === false &&
                annotationTool === false &&
                measureToolToggle === false &&
                polygonAnnotation === false
              )
                viewer.screenSpaceEventHandler.setInputAction((click) => {
                  const pickedFeature = viewer.scene.pick(click.position);
                  if (defined(pickedFeature) && pickedFeature.id) {
                    const featureId = pickedFeature.id.geoJsonId;

                    // alert(`featureId : ${featureId} `);
                  }
                }, ScreenSpaceEventType.LEFT_CLICK);

              geoJsonDataSource.entities.values.forEach((entity) => {
                const featureId = entity?.properties?.Id?.getValue();
                entity.geoJsonId = featureId;
              });
            } else {
              IonResource.fromAssetId(assetId)
                .then((resource) => KmlDataSource.load(resource))
                .then((dataSource) => {
                  // Add the loaded data source to the viewer

                  viewer.dataSources.add(dataSource);
                  const entities = dataSource.entities.values;

                  if (asset === "") {
                    viewer.flyTo(dataSource, {
                      duration: 4.0,
                    });
                  }

                  entities.forEach((entity) => {
                    // Assuming you want to set a custom color to the polyline or polygon
                    if (entity.polyline) {
                      entity.polyline.material = colors[colorIndex]; // Set your custom color here
                    } else if (entity.polygon) {
                      entity.polygon.material = colors[colorIndex]; // Set your custom color here
                    } else {
                      entity.polyline.material = colors[colorIndex]; // Red color
                    }
                    // You can customize the color for other types of entities as needed
                  });
                  // Zoom to the extent of the loaded data source
                  // viewer.zoomTo(dataSource);
                })
                .catch((error) => {
                  console.error("Error loading KMZ from Cesium ion:", error);
                });
            }
          } catch (error) {
            console.error(
              `Error loading GeoJSON for Asset ID ${assetId}:`,
              error
            );
          }
        }

        if (shapesIds) {
          // setZoomLevel(true);
          dispatch(gisActions.setZoomLevel());
        }
      }
    };

    showGeoJsonOnMap(shapesIds);
  }, [shapesIds, asset]);
}
