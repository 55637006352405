const CustomPopper = ({ isVisible, position, children }) => {
  const popoverStyle = {
    display: isVisible ? "block" : "none",
    top: position.top,
    left: position.left,
  };

  return (
    <div
      className="absolute z-10 bg-white border border-gray-300 shadow-lg rounded-md p-4 w-auto"
      style={popoverStyle}
    >
      {children}
    </div>
  );
};

export default CustomPopper;
