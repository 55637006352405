import React, { useEffect, useState } from "react";
import { FormControl, IconButton, Modal, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import CloseIcon from "@mui/icons-material/Close";
import { useDropzone } from "react-dropzone";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { gisToolActions } from "../../redux/slices/GIS/gis-tools-slice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30%",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const dropzoneStyle = {
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
  border: "2px dashed #cccccc",
};

// typeOfMarker : Garbage , Debris , Scrap , Custom
const MetaInput = ({ isOpen, onClose, onSave }) => {
  const [volume, setVolume] = React.useState("");
  const [markerName, setMarkerName] = useState("");
  const [description, setDescription] = useState("");
  const [keyValuePairs, setKeyValuePairs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSeverity, setSelectedSeverity] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [date, setDate] = useState(null);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");

  const severities = ["Low", "Medium", "High"];
  const pointMarkers = [
    "Garbage",
    "Debris",
    "Scrap",
    "Stock Pile",
    "Steel Scrap",
    "Other",
  ];
  // "Custom",

  // redux
  const dispatch = useDispatch();
  const assetId = useSelector((state) => state.gisHome.asset);
  const typeOfMarker = useSelector((state) => state.gis.markerCategory);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const maxCharacterLimit = 25;

  const handleMarkerNameChange = (event) => {
    const newValue = event.target.value;
    if (newValue.length <= maxCharacterLimit) {
      setMarkerName(newValue);
    }
  };

  const remainingCharacters = maxCharacterLimit - markerName.length;

  const handleKeyChange = (event) => {
    setKey(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  const handleAddKeyValuePair = () => {
    if (key && value) {
      const uppercaseKey = key.toUpperCase();
      setKeyValuePairs([...keyValuePairs, { key: uppercaseKey, value }]);
      setKey("");
      setValue("");
    }
  };

  const handleOnClose = () => {
    setDescription("");
    setMarkerName("");
    setKeyValuePairs([]);
    setSelectedCategory("");
    setSelectedSeverity("");
    dispatch(gisToolActions.turnOffTools());
    dispatch(gisActions.setMarkerCategory(""));

    dispatch(gisToolActions.setselectedTool(null));
  };
  const [nameError, setNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");

  // console.log(categoryError, "categoryError");

  useEffect(() => {
    if (markerName && typeOfMarker) {
      setNameError("");
      setCategoryError("");
    } else if (markerName) {
      setCategoryError("");
    } else {
      setNameError("");
    }
  }, [typeOfMarker, markerName]);

  const handleSave = () => {
    if (!markerName && !typeOfMarker) {
      setNameError("Please enter a name");
      setCategoryError("Please select a marker type");
    } else if (!markerName) {
      setNameError("Please enter a name");
      setCategoryError("");
    } else if (!typeOfMarker) {
      setNameError("");
      setCategoryError("Please select a marker type");
    } else {
      setNameError("");
      setCategoryError("");

      onSave(
        description,
        keyValuePairs,
        selectedCategory,
        selectedSeverity,
        markerName,
        assetId,
        typeOfMarker,
        uploadedFile,
        date
      );
      // Save description, key-value pairs, and category

      setDescription("");
      setKeyValuePairs([]);
      setSelectedCategory("");
      setSelectedSeverity("");
      setMarkerName("");
      dispatch(gisToolActions.turnOffTools());
      dispatch(gisToolActions.setselectedTool(null));

      onClose();
      dispatch(gisActions.setMarkerCategory(""));
      dispatch(gisActions.refCounterUpdate());
    }
  };

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const { getRootProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleOnClose();
        onClose();
      }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <div
        style={style}
        className="flex flex-col items-center justify-between bg-white w-auto h-max rounded-[12px] "
      >
        <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px] w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
          <h3 className="text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
            {typeOfMarker === "Custom" ? (
              <h1 className="text-lg font-bold ">Enter Marker Details</h1>
            ) : (
              <h1 className="text-lg font-bold "> Marker {typeOfMarker}</h1>
            )}
          </h3>
          <div className="mr-[10px]">
            <IconButton
              onClick={() => {
                handleOnClose();
                onClose();
              }}
              aria-label="Close"
              size="small"
              className="text-[#211f1c]"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <div className="grow w-full p-3 flex flex-col gap-3">
          <div className="flex items-center justify-center w-full ">
            <label className="w-[25%]">
              Name: <span className="text-red-500">*</span>{" "}
            </label>
            <div className="flex flex-grow flex-col">
              <TextField
                name="textNote"
                size="small"
                value={markerName}
                onChange={handleMarkerNameChange}
                className="grow"
                maxLength={25}
              />
              <p className="text-xs text-gray-400">
                Remaining characters: {remainingCharacters}
              </p>
            </div>
          </div>
          {nameError && (
            <p className="ml-[30%] text-red-500 text-sm">{nameError}</p>
          )}
          <div className="flex items-center justify-center w-full">
            <label className="w-[25%]">Note: </label>
            <TextField
              name="textNote"
              size="small"
              value={description}
              onChange={handleDescriptionChange}
              className="grow"
            />
          </div>
          <div className="flex items-center justify-center w-full">
            <label className="w-[25%]">
              Marker type: <span className="text-red-500">*</span>
            </label>
            <select
              className="border border-gray-600 px-2 py-1 rounded grow"
              value={typeOfMarker}
              onChange={(event) => {
                // selectedCategory(event.target.value);
                dispatch(gisActions.setMarkerCategory(event.target.value));
              }}
            >
              <option value="">Select a Marker Type</option>
              {pointMarkers.map((marker, index) => (
                <option key={index} value={marker}>
                  {marker}
                </option>
              ))}
            </select>
          </div>
          {categoryError && (
            <p className="ml-[30%] text-red-500 text-sm">{categoryError}</p>
          )}
          <div className="flex items-center justify-center w-full">
            <label className="w-[25%]">Date:</label>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              sx={{ flexGrow: 1 }}
            >
              <DemoContainer components={["DatePicker"]} sx={{ flexGrow: 1 }}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Timeline"
                    slotProps={{ textField: { size: "small" } }}
                    onChange={(selectedDate) => {
                      setDate(dayjs(selectedDate).format("DD_MM_YY"));
                    }}
                  />
                </FormControl>
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div className="flex items-center justify-center w-full">
            <label className="w-[25%]">Volume:</label>
            <TextField
              name="textNote"
              size="small"
              placeholder="in cubic meter"
              value={volume}
              onChange={(e) => setVolume(e.target.value)}
              className="grow"
            />
          </div>
          <div className="flex items-center justify-center w-full">
            <label className="w-[25%]">Severity:</label>
            <select
              className="border border-gray-600 px-2 py-1 rounded grow"
              value={selectedSeverity}
              onChange={(event) => setSelectedSeverity(event.target.value)}
            >
              <option value="">Select a severity</option>
              {severities.map((severity, index) => (
                <option key={index} value={severity}>
                  {severity}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center justify-center w-full">
            <div className="w-[25%]">Add Image:</div>
            {!uploadedFile ? (
              <>
                <div
                  className="flex flex-col h-[30px] grow"
                  {...getRootProps()}
                  style={dropzoneStyle}
                >
                  {/* <input {...getInputProps()} /> */}
                  <p className="m-auto text-xs">
                    Drag & Drop Image here or Click to upload
                  </p>
                </div>
              </>
            ) : (
              <>
                <TextField
                  name="textNote"
                  size="small"
                  value={uploadedFile.name}
                  className="grow"
                  inputProps={{ readOnly: true }}
                />
              </>
            )}
          </div>
        </div>

        {typeOfMarker === "Custom" ? (
          <div className="flex flex-col w-full p-3">
            <p className="text-left mb-2 font-semibold">
              Add Info <span className="text-xs">(In Key-Value Format)</span>
            </p>
            <div className="flex flex-row w-max space-x-2">
              <input
                className="border border-gray-600 px-2 py-1 w-1/2 rounded"
                type="text"
                placeholder="Enter key"
                value={key}
                onChange={handleKeyChange}
              />
              <input
                className="border border-gray-600 px-2 py-1 w-1/2 rounded"
                type="text"
                placeholder="Enter value"
                value={value}
                onChange={handleValueChange}
              />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-24 py-1 px-4 rounded"
                onClick={handleAddKeyValuePair}
              >
                + Add
              </button>
            </div>
          </div>
        ) : null}
        {keyValuePairs.length > 0 && (
          <div className="my-4">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-100 border">
                  <th className="p-2">Key</th>
                  <th className="p-2">Value</th>
                  <th className="p-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {keyValuePairs.map((pair, index) => (
                  <tr key={index} className="border text-center">
                    <td className="p-2">{pair.key}</td>
                    <td className="p-2">{pair.value}</td>
                    <td className="p-2">
                      <button
                        onClick={() => {
                          const updatedPairs = keyValuePairs.filter(
                            (_, i) => i !== index
                          );
                          setKeyValuePairs(updatedPairs);
                        }}
                        className="border bg-gray-400 py-1 px-2 text-xs rounded"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="my-4 bottom-5 flex w-full justify-center items-center">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
            onClick={() => {
              handleSave(typeOfMarker);

              // refCounter();
            }}
          >
            Save
          </button>

          <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              handleOnClose();
              onClose();
              // refCounter();
              dispatch(gisActions.refCounterUpdate());
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default MetaInput;
