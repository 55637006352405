import React, { useState } from "react";
import { FormControl, InputLabel, TextField } from "@mui/material";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { inspectLogo } from "../../Assets/images/gisImg/images";
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

function Signup() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [emailaddress, setEmailAddress] = useState("");
  const [roles, setRoles] = useState("");
  const [gender, setGender] = useState("");
  const clientName = Cookies.get("clientName");
  const navigate = useNavigate();

  const Roles = [
    {
      id: 0,
      name: "CEO",
    },
    {
      id: 1,
      name: "CTO",
    },
    {
      id: 2,
      name: "Manager",
    },
    {
      id: 3,
      name: "Software",
    },
    {
      id: 4,
      name: "GIS",
    },
    {
      id: 5,
      name: "Pilots",
    },
    {
      id: 6,
      name: "R&D",
    },
  ];

  const Gender = [
    {
      id: 0,
      name: "Male",
    },
    {
      id: 1,
      name: "Female",
    },
    {
      id: 2,
      name: "Other",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if required fields are filled
    if (!phonenumber || !emailaddress) {
      // Display an alert or toast message to inform the user to fill in all required fields
      toast.error("Please fill in all required fields.");
      return;
    } else {
      toast.success("Signed Up Successfully !");
    }

    // Create a URL for your Google Sheets web app
    const scriptUrl =
      "https://script.google.com/macros/s/AKfycbylVpbotBaYiNhOypcfSdGX0GEnV8bXEimiZg2IFI8WLFzQfxXhSbP8n3-ry3GmZy1n/exec";

    // Prepare the data to be sent
    const data = {
      firstname,
      lastname,
      phonenumber,
      emailaddress,
      roles,
      gender,
    };

    try {
      const response = await fetch(scriptUrl, {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Handle a successful form submission, e.g., show a success message
        // console.log("Form submitted successfully");

        // You can access the response text if needed
        const responseText = await response.text();
        // console.log("Response text: ", responseText);

        // Reset the form fields
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setEmailAddress("");
        setRoles("");
        setGender("");
      } else {
        // Handle errors, e.g., show an error message
        console.error("Form submission failed");

        // You can access the response text for error details if needed
        const errorText = await response.text();
        console.error("Error response text: ", errorText);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    Cookies.set("nhaiSignUp", "nhaiSignUp");

    clientName === "hway" ? navigate("/nhai") : navigate("/");
  };

  return (
    <div className="flex-1 flex w-[100%] items-center justify-between pt-[7%] pb-[3%] flex-col max-[600px]:px-[3%]">
      <div className="flex flex-col items-center w-[50%] max-w-[448px] Signup">
        <div className="mt-[10px] flex flex-col items-center mb-[10px]">
          <div className="w-[100px] mt-[10px] mb-[10px]">
            <img src={inspectLogo} alt="Error" />
          </div>
        </div>
        <div className="text-[2rem] font-[600] mt-[10px] text-center mb-[2rem] tracking-[0.6px] text-[#4a4844]">
          Sign Up
        </div>
        <Toaster />

        <form className="flex flex-col gap-3 w-[70%]">
          <div className=" flex flex-row  gap-3">
            <TextField
              id="outlined-Email-input"
              name="firstname"
              className="w-full textfield"
              variant="outlined"
              label="First Name"
              autoComplete="email"
              type="text"
              size="small"
              style={{ fontSize: "17px" }}
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              id="outlined-Email-input"
              name="lastname"
              className="w-full textfield"
              variant="outlined"
              label="Last Name"
              autoComplete="email"
              type="text"
              size="small"
              style={{ fontSize: "17px" }}
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <TextField
            id="outlined-Email-input"
            name="phonenumber"
            className="w-full textfield"
            variant="outlined"
            label="Phone Number"
            autoComplete="email"
            type="number"
            size="small"
            style={{ fontSize: "17px" }}
            value={phonenumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            required
          />
          <TextField
            id="outlined-Email-input"
            name="emailaddress"
            className="w-full textfield"
            variant="outlined"
            label="Email address"
            autoComplete="email"
            type="text"
            size="small"
            style={{ fontSize: "17px" }}
            value={emailaddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            required
          />
          <div className=" flex flex-row  gap-3">
            <FormControl fullWidth size="small">
              <InputLabel id="test-select-label">Choose a Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="category"
                label="Choose a Role"
                name="type"
                value={roles}
                onChange={(e) => setRoles(e.target.value)}
                className="w-full z-[999]"
              >
                {Roles.map((role) => (
                  <MenuItem key={role.id} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="test-select-label">Choose a Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="category"
                label="Choose a Gender"
                name="type"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
                className="w-full z-[999]"
              >
                {Gender.map((gender) => (
                  <MenuItem key={gender.id} value={gender.name}>
                    {gender.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="mt-[3rem]">
            <button
              className="padding-[13px] items-center h-[42px] flex w-full justify-center rounded-[3px] bg-[#4a4844] px-3 py-1.5 text-[16px] font-semibold leading-6 text-white shadow-sm hover:bg-[#808787] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              type="button"
              onClick={handleSubmit}
            >
              Sign Up
            </button>
          </div>
        </form>
      </div>
      <div className="text-[13px] text-[#afaeae] font-[500] text-center">
        Already a member?
        <span className="text-[#4a4844] font-[600] text-[13px] text-right ml-[8px] cursor-pointer">
          <NavLink to="/login">Login</NavLink>
        </span>
      </div>
    </div>
  );
}

export default Signup;
