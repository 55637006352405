import { Checkbox, IconButton, Modal } from "@mui/material";
import axios from "axios";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const style = {
  height: "auto",
  width: 400,
  boxShadow: 24,
};

function formatDate(inputDateString) {
  // Create a new Date object from the input string
  const date = new Date(inputDateString);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const TimelineModal = ({ project, open, handleClose, isCompare }) => {
  const navigate = useNavigate();
  const [compareMsg, setCompareMsg] = React.useState("");
  const [projectTimelines, setProjectTimelines] = useState([]);
  const [checkedCheckboxes, setCheckedCheckboxes] = React.useState([]);

  React.useEffect(() => {
    const getTimelines = async () => {
      const response = await axios.get(
        `https://delivery.inspect.indrones.com/api/v1/timeline/get_by_project/${project}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log("timeline", response.data);
      setProjectTimelines(response.data);
    };
    getTimelines();
  }, [project]);

  const handleFly = (location) => {
    navigate(`/surveillance/${location}`);
  };

  const handleCheckboxChange = (index, round) => {
    const updatedCheckboxes = [...checkedCheckboxes];
    const existingIndex = updatedCheckboxes.findIndex(
      (item) => item.index === index
    );

    if (existingIndex !== -1) {
      updatedCheckboxes.splice(existingIndex, 1);
    } else {
      updatedCheckboxes.push({ index, round });
    }
    setCheckedCheckboxes(updatedCheckboxes);
  };

  const handleCompareScreen = () => {
    if (checkedCheckboxes.length === 2) {
      const [checkbox1, checkbox2] = checkedCheckboxes;
      var round1, round2;
      if (checkbox1.round > checkbox2.round) {
        round1 = checkbox2.round;
        round2 = checkbox1.round;
      } else {
        round1 = checkbox1.round;
        round2 = checkbox2.round;
      }
      navigate(`/compare/${round1}${round2}`);
    } else if (checkedCheckboxes.length < 2 || !checkedCheckboxes.length) {
      setCompareMsg("Please select atleast 2 choices!");
      document.getElementById("errorText").classList.add("shake");
    } else {
      setCompareMsg("Only two choices are allowed!");
      document.getElementById("errorText").classList.add("shake");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className="flex justify-center items-center"
      >
        <div
          style={style}
          className="rounded-[12px] flex flex-col items-center justify-between bg-white w-full h-min"
        >
          <div className="flex items-center justify-between bg-[#ffca0094] mb-[8px] rounded-tl-[12px] rounded-tr-[12px] w-full pt-[10px] pb-[10px] font-[500] border-b">
            <h3 className="!text-[1.20rem] text-[#211f1c] grow flex items-center justify-center">
              Timeline
            </h3>
            <div className="mr-[10px]">
              <IconButton
                onClick={handleClose}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="grow w-full p-3 pb-2 pt-0">
            {projectTimelines.map((timeline, index) => {
              return (
                <>
                  <div
                    key={index}
                    className="flex w-[100%] items-center justify-between my-[10px] p-2 bg-white mx-auto border rounded-lg"
                  >
                    {isCompare && (
                      <Checkbox
                        {...label}
                        color="default"
                        size="small"
                        id={`checkbox${index}`}
                        disabled={timeline.compare ? false : true}
                        onChange={() =>
                          handleCheckboxChange(index, timeline.id)
                        }
                      />
                    )}
                    <div className={`grow`}>{timeline.name}</div>
                    {!isCompare && (
                      <div>
                        <button
                          className={`text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] ${
                            !timeline.active && "opacity-50 cursor-no-drop"
                          }`}
                          onClick={
                            timeline.active
                              ? () => {
                                  handleFly(timeline.id);
                                }
                              : () => {}
                          }
                        >
                          Go
                        </button>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
          {isCompare && (
            <>
              <div
                id="errorText"
                className="mb-3 text-[#ff0000] text-center text-sm font-medium"
              >
                {compareMsg}
              </div>

              <div className="flex items-center mb-[20px]">
                <button
                  onClick={handleCompareScreen}
                  className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
                >
                  Go
                </button>
              </div>
            </>
          )}

          {/* {project.active ? (
            <div className="grow w-full p-3 pb-2 pt-0">
              {project.fly_dates.map((fly, index) => {
                return (
                  <div
                    key={index}
                    className="flex w-[100%] items-center justify-between my-[10px] p-2 bg-white mx-auto border rounded-lg"
                  >
            
                    <div
                      className={`grow ${
                        fly.data && fly.isCompare
                          ? "text-black"
                          : fly.data && !isCompare
                          ? "text-black"
                          : "text-gray-200"
                      }`}
                    >
                      {fly.date}
                    </div>
                    {!isCompare && (
                      <div>
                        <button
                          className={`text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] ${
                            fly.data ? "" : "opacity-50 cursor-no-drop"
                          }`}
                          onClick={
                            fly.data
                              ? () => {
                                  handleFly(fly.id);
                                }
                              : () => {}
                          }
                        >
                          Go
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="h-[100px] mt-5">No Data Available</div>
          )}
         */}
        </div>
      </Modal>
    </>
  );
};

export default TimelineModal;
