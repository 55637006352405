import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ReportModal from "./ReportModal";
import {
  icon,
  deleteIco,
  chevronIcoDropdown,
  donwloadIco,
} from "../../Assets/icons/gisIcons/icons";
import "../../Assets/styles/GISStyle.css";
import { dataJson } from "../../../src/Data/Grp_table_data";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Tooltip, Popper } from "@mui/material";
import { gisHomeActions } from "../../redux/slices/GIS/gis-home-slice";
import { getDate } from "../../Utils/OtherUtils";
import CesiumAsset from "../../Pages/GIS/CesiumAsset";
import toast from "react-hot-toast";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TilingStatus from "./TilingStatus";

const PILDates = [{ name: "PIL_22_23_APRIL" }, { name: "PIL_22_23_JUNE" }];
const skyhighDates = [
  { name: "SKY_HIGH_GOA_ORI" },
  { name: "SKY_HIGH_GOA_DSM" },
  { name: "SKY_HIGH_GOA_DTM" },
];

export const SegregateAgpAssets = (objectsArray, keyword) => {
  const result = {
    Assets: [],
  };

  // console.log("objectsArray", objectsArray);

  if (keyword === "AGP") {
    const assetsByDate = {};

    objectsArray.forEach((obj) => {
      if (obj.name.includes(keyword)) {
        const dateMatch = obj.name.match(/\d{2}_\d{2}_\d{2}/);
        if (dateMatch) {
          const date = dateMatch[0];

          // Check if the date is already in the mapping
          if (!assetsByDate[date]) {
            assetsByDate[date] = [];
          }

          const agpObject = { date, ...obj };
          assetsByDate[date].push(agpObject);
        } else {
          result.Assets.push(obj);
        }
      }
    });

    // //console.log(assetsByDate, "assetsByDate");
    result.Assets.push(...Object.values(assetsByDate).flat());

    const latestAssetsArray = Object.values(assetsByDate);
    const latestAsset = latestAssetsArray[latestAssetsArray.length - 1];

    // //console.log(latestAsset[0]?.type, "latestAsset");
    return {
      result,
      latestAssetId: latestAsset[0]?.id,
      latestAssetType: latestAsset[0]?.type,
    };
  } else {
    objectsArray.forEach((obj) => {
      if (obj.name.includes(keyword)) {
        const dateMatch = obj.name.match(/\d{2}_\d{2}_\d{2}/);
        if (dateMatch) {
          const date = dateMatch[0];
          const agpObject = { date, ...obj };
          result.Assets.push(agpObject);
        } else {
          result.Assets.push(obj);
        }
      }
    });
    // //console.log(result);
    return { result };
  }
};

const GisSidebar = (props) => {
  const [assetData, setAssetData] = React.useState({});
  const [selectedAnnotation, setSelectedAnnotation] = React.useState(null);
  const [isReportModalOpen, setIsReportModalOpen] = React.useState(false);
  const [isProgressReportModalOpen, setIsProgressReportModalOpen] =
    React.useState(false);
  const myElementRef = useRef(null);
  const [elementWidth, setElementWidth] = useState(null);
  const [openDropdownMap, setOpenDropdownMap] = useState({});
  const [defDropDown, setDefDropDown] = useState(false);

  const dispatch = useDispatch();
  const showComparer = useSelector((state) => state.gisTools.showComparer);
  const { compareAssets, mode } = useSelector((state) => state.gisHome);
  const universalAsset = useSelector((state) => state.gisHome.asset);
  const { refCounter, latestAssetCounter } = useSelector((state) => state.gis);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loadingId, setLoadingId] = React.useState(null);
  const [selectedAssetDate, setSelectedAssetDate] = useState("");

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [statusAnchorEl, setSAnchorEl] = useState(null);
  const statusOpen = Boolean(anchorEl);
  const statusId = statusOpen ? "simple-popper" : undefined;

  const handleStatusClick = (id) => {
    setLoadingId(id);
    // setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleOpenModalProgress = () => {
    setIsProgressReportModalOpen(true);
    setIsReportModalOpen(false);
  };

  // const handleOpenModalAsset = () => {
  //   setIsProgressReportModalOpen(false);
  //   setIsReportModalOpen(false);
  //   // setIsAssetModalOpen(true);
  // };

  const handleOpenModal = (annotation) => {
    setSelectedAnnotation(annotation);
    setIsReportModalOpen(true);
    setIsProgressReportModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsReportModalOpen(false);
  };

  const handleCloseModalProgress = () => {
    setIsProgressReportModalOpen(false);
  };

  React.useEffect(() => {
    const getAssets = async () => {
      try {
        const response = await axios.get(`https://api.cesium.com/v1/assets`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.token}`,
          },
        });
        // console.log(response);

        if (props.grp === "AGP" && latestAssetCounter === 0) {
          const { result, latestAssetId, latestAssetType } = SegregateAgpAssets(
            response.data.items,
            props.grp
          );
          dispatch(
            gisHomeActions.setAsset({
              asset: latestAssetId,
              assetType: latestAssetType,
            })
          );

          dispatch(gisHomeActions.setGrpData(result.Assets));

          setAssetData(result);
          dispatch(gisActions.latestAssetCounter());
        } else {
          const { result } = SegregateAgpAssets(response.data.items, props.grp);

          dispatch(gisHomeActions.setGrpData(result.Assets));
          setAssetData(result);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getAssets();
  }, [props.token, refCounter]);

  useEffect(() => {
    const element = myElementRef.current;

    if (!element) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === element) {
          setElementWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, []);

  const toggleDropdown = (assetId) => {
    setOpenDropdownMap((prevState) => ({
      ...prevState,
      [assetId]: !prevState[assetId], // Step 2
    }));
  };

  const deleteTimeline = async (id) => {
    try {
      const response = await axios.delete(
        `https://api.cesium.com/v1/assets/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      );
      // //console.log(response.data, "Deleted");
      toast.success("Timeline Deleted!!");

      dispatch(gisActions.refCounterUpdate());
    } catch (error) {
      console.error(error);
    }
  };
  const downloadAsset = async (id) => {
    try {
      toast.success("Download will start shortly!");
      const response = await axios.get(
        `https://api.cesium.com/v1/assets/${id}/archives`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      );

      if (response.status === 200) {
        //console.log("Asset archived successfully");
        //console.log(response);
        const archiveId = response.data.items[0].id;
        //console.log(response.data.items[0].id, "response");
        //console.log("responseDownload started");

        const responseDownload = await axios.get(
          `https://api.cesium.com/v1/assets/${id}/archives/${archiveId}/download`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
              Accept: "application/octet-stream",
            },
            responseType: "blob",
          }
        );
        toast.success("Asset Download authenticated Successfully!");
        //console.log("Asset Download authenticated Successfully!");
        //console.log(responseDownload, "responseDownload");
        if (responseDownload.status === 200) {
          const blobURL = responseDownload.request.responseURL;

          const link = document.createElement("a");
          link.href = blobURL;
          link.click(); // Initiate the file download

          toast.success("Asset Download started Successfully!");
        } else {
          console.error(`Error downloading asset: ${responseDownload.status}`);
          toast.error("Error Downloading Asset!");
        }
      } else {
        console.error(`Error archiving asset: ${response.status}`);
        toast.error("Error Archiving Asset!");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error Archiving Asset!");
    }
  };

  useEffect(() => {
    dispatch(
      gisHomeActions.setAsset({
        asset: "",
        assetType: "",
      })
    );
    if (props.grp == "ESSEL") {
      setDefDropDown(true);
    }

    // return () => {
    //   second;
    // };
  }, []);

  // const handleClickForDropdown = () => {
  //   toggleDropdown(asset.id);
  //   handleStatusClick(asset.id);
  //   setSelectedAssetDate(getDate(asset.date));
  // };
  // //console.log(props.grp, "grp");
  function getLastWord(inputString) {
    const words = inputString.split("_");

    const lastWord = words[words.length - 1];

    return lastWord;
  }
  return (
    <div
      ref={myElementRef}
      className={`${
        props.openSidebar ? "block" : "hidden"
      } sideanimation relative  !grow resize-able  border-solid border-[#d7d8d8] border  bg-[#fff] m-2 rounded-md `}
    >
      {/* {props.grp !== "ESSEL" && (
        <div
          className="absolute bottom-1 right-1 z-50 "
          onClick={handleOpenModalAsset}
        >
          <a
            href="#_"
            className="relative inline-flex items-center justify-center px-3 py-1 overflow-hidden text-white bg-gray-800 rounded group"
          >
            <span className="absolute w-0 h-0 transition-all duration-500 ease-out bg-green-500 rounded-full group-hover:w-56 group-hover:h-56"></span>
            <span className="absolute inset-0 w-full h-full -mt-1 rounded-lg opacity-30 bg-gradient-to-b from-transparent via-transparent to-gray-700"></span>
            <span className="relative">+ Upload</span>
          </a>
        </div>
      )} */}

      <div className="w-full  flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500] rounded-tr-md rounded-tl-md">
        <div className="grow flex items-center justify-center">Timelines</div>
      </div>
      {props.grp !== "PIL" ? (
        Object.keys(assetData).map((category) => (
          <div className="category" key={category}>
            {assetData[category].map((asset, index) => (
              <div className="flex grow " key={index}>
                <div
                  className={`box-showl flex grow flex-col   ${
                    universalAsset === asset.id
                      ? "bg-[#f0f8ff]"
                      : showComparer === true
                      ? "bg-white"
                      : ""
                  }  `}
                >
                  {asset.type === "IMAGERY" &&
                    asset.attribution !== "DSM" &&
                    asset.attribution !== "DTM" && (
                      <>
                        {props.grp !== "ESSEL" && (
                          <div className="flex relative">
                            {showComparer && (
                              <Checkbox
                                color="default"
                                size="small"
                                id={asset.id}
                                checked={compareAssets.includes(asset.id)}
                                disabled={
                                  compareAssets.includes(asset.id) &&
                                  compareAssets.length > 3
                                }
                                onChange={() =>
                                  props.compareAssetsArray(asset.id)
                                }
                              />
                            )}
                            <div
                              className={`w-full m-1 cursor-pointer py-1 px-2 flex justify-between ${
                                (asset.status === "IN_PROGRESS" ||
                                  asset.status === "NOT_STARTED") &&
                                "text-gray-500"
                              }      `}
                              onClick={() => {
                                asset.status === "IN_PROGRESS" ||
                                asset.status === "NOT_STARTED"
                                  ? toast.error("Tiling is in process.")
                                  : dispatch(
                                      gisHomeActions.setAsset({
                                        asset: asset.id,
                                        assetType: asset.type,
                                      })
                                    );
                              }}
                            >
                              {asset.date
                                ? getDate(asset.date)
                                : asset.name.split("_").join(" ")}
                              {props.grp === "ESSEL" && (
                                <span
                                  onClick={() => {
                                    downloadAsset(asset.id);
                                  }}
                                >
                                  <img src={donwloadIco} alt="" srcSet="" />
                                </span>
                              )}
                            </div>
                            <button
                              onClick={() => {
                                toggleDropdown(asset.id);
                                handleStatusClick(asset.id);
                                setSelectedAssetDate(getDate(asset.date));
                              }}
                              className={
                                openDropdownMap[asset.id]
                                  ? "rotated mr-2"
                                  : "mr-2"
                              }
                            >
                              <img
                                src={chevronIcoDropdown}
                                alt=""
                                className="h-8"
                                srcSet=""
                              />
                            </button>
                          </div>
                        )}
                        {props.grp === "ESSEL" && (
                          <div className="flex relative">
                            <div
                              className={`w-full m-1 cursor-pointer py-1 px-2 flex justify-between ${
                                (asset.status === "IN_PROGRESS" ||
                                  asset.status === "NOT_STARTED") &&
                                "text-gray-500"
                              }      `}
                              onClick={() => {
                                toggleDropdown(asset.id);
                                handleStatusClick(asset.id);
                                setSelectedAssetDate(getDate(asset.date));
                              }}
                            >
                              {asset.date
                                ? getDate(asset.date)
                                : asset.name.split("_").join(" ")}{" "}
                            </div>

                            <button
                              onClick={() => {
                                toggleDropdown(asset.id);
                                handleStatusClick(asset.id);
                                setSelectedAssetDate(getDate(asset.date));
                              }}
                              className={
                                openDropdownMap[asset.id]
                                  ? "rotated mr-2"
                                  : "mr-2"
                              }
                            >
                              <img
                                src={chevronIcoDropdown}
                                alt=""
                                className="h-8"
                                srcSet=""
                              />
                            </button>
                          </div>
                        )}
                        {openDropdownMap[asset.id] && // Check if the dropdown is open for the current item
                          (asset.status === "IN_PROGRESS" ||
                          asset.status === "NOT_STARTED" ? (
                            <div className="dropdown-list px-2 flex flex-col gap-2 border border-t-2 rounded-lg rounded-b-none p-2 bg-white">
                              <TilingStatus id={loadingId} />
                            </div>
                          ) : (
                            <>
                              {props.grp === "ESSEL" && (
                                <div className="dsmDtmTimelines">
                                  <ul className="dropdown-list px-2  flex flex-col gap-2 border border-t-2 rounded-lg rounded-b-none p-2 bg-white">
                                    {assetData[category].map((asset, index) =>
                                      selectedAssetDate &&
                                      (asset.attribution == "DSM" ||
                                        asset.attribution == "DTM" ||
                                        asset.attribution == "ORTHO") ? (
                                        <li
                                          className={`flex justify-between items-center hover:bg-[#f0f8ff] cursor-pointer py-1 px-1 rounded-md ${
                                            universalAsset === asset.id
                                              ? "bg-[#f0f8ff]"
                                              : showComparer === true
                                              ? "bg-white"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            dispatch(
                                              gisHomeActions.setAsset({
                                                asset: asset.id,
                                                assetType: asset.type,
                                              })
                                            );
                                            // console.log(
                                            //   "asset set from the multi drop down"
                                            // );
                                          }}
                                        >
                                          {asset.attribution}
                                          {/* <p className=" text-xs font-semibold border text-green-500 bg-gray-100 py-[0.5px] px-1 border-grey-500 rounded">
                                          {asset.attribution}
                                        </p> */}
                                          {/* <span
                                          className="inline-block cursor-pointer transform transition-transform duration-300 hover:scale-110"
                                          onClick={() => {
                                            downloadAsset(asset.id);
                                          }}
                                        >
                                          <img
                                            src={donwloadIco}
                                            alt="Download"
                                          />
                                        </span> */}

                                          <button
                                            type="button"
                                            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-xs px-2 py-1 text-center inline-flex items-center justify-center  gap-1 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 "
                                            onClick={() => {
                                              downloadAsset(asset.id);
                                            }}
                                          >
                                            <span>
                                              <img
                                                src={donwloadIco}
                                                alt="Download"
                                                className="h-4"
                                              />
                                            </span>
                                            <p> Download</p>
                                          </button>
                                        </li>
                                      ) : null
                                    )}
                                  </ul>
                                </div>
                              )}
                              {props.grp !== "ESSEL" && (
                                <ul className="dropdown-list px-2  flex flex-col gap-2 border border-t-2 rounded-lg rounded-b-none p-2 bg-white">
                                  {/* <li
                                    className="dropdown-item flex gap-2 hover:bg-blue-200  rounded-lg  items-center cursor-pointer"
                                    onClick={() => handleOpenModal(asset.name)}
                                  >
                                    <div className="logo cursor-pointer bg-blue-200 rounded p-[4px] w-max ">
                                      <Tooltip
                                        title="Annotation Report"
                                        placement="right"
                                        arrow
                                      >
                                        <img
                                          className="h-5"
                                          src={icon}
                                          alt=""
                                        />
                                      </Tooltip>
                                    </div>
                                    <span className="label">
                                      Annotation Report
                                    </span>
                                  </li> */}
                                  {/* <li
                                    className="dropdown-item flex gap-2 cursor-pointer hover:bg-green-200 rounded-lg items-center"
                                    onClick={() => handleOpenModalProgress()}
                                  >
                                    <div className="logo cursor-pointer bg-green-200 rounded p-[4px] w-max">
                                      <Tooltip
                                        title="Progress Report"
                                        placement="right"
                                        arrow
                                      >
                                        <img
                                          className="h-5"
                                          src={icon}
                                          alt=""
                                        />
                                      </Tooltip>
                                    </div>
                                    <span className="label">
                                      Progress Report
                                    </span>
                                  </li> */}
                                  <li
                                    className="dropdown-item flex gap-2 hover:bg-red-200 rounded-lg items-center cursor-pointer"
                                    aria-describedby={id}
                                    // type="button"
                                    onClick={handleClick}
                                  >
                                    <div className="logo cursor-pointer bg-red-200  p-[2px] w-max rounded">
                                      <Tooltip
                                        title="Delete Timeline"
                                        placement="right"
                                        arrow
                                      >
                                        <img
                                          className=" h-6"
                                          src={deleteIco}
                                          alt=""
                                        />
                                      </Tooltip>
                                    </div>
                                    <Popper
                                      id={id}
                                      open={open}
                                      anchorEl={anchorEl}
                                      placement={
                                        open === true ? "bottom" : "right-start"
                                      }
                                      sx={{
                                        zIndex: 999,
                                      }}
                                    >
                                      <div className=" shadow-md border rounded-lg bg-white flex flex-col gap-4 w-300 mt-2">
                                        <div className="w-full rounded-lg flex flex-col items-center p-4 ">
                                          <p className="mb-4">
                                            Are you sure you want to delete this
                                            item?
                                          </p>
                                          <div className="flex gap-4">
                                            <button
                                              className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none"
                                              onClick={() => {
                                                deleteTimeline(asset.id);
                                              }}
                                            >
                                              <p className="text-sm">Confirm</p>
                                            </button>
                                            <button className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none">
                                              <p className="text-sm">Cancel</p>
                                            </button>

                                            {/* <Button
                                      variant="contained"
                                      color="success"
                                      size="small"
                                      // onClick={props.deleteItem} // Handle the delete action
                                    >
                                      Confirm
                                    </Button> */}
                                            {/* <Button
                                      variant="contained"
                                      // onClick={handleClose} // Close the Popper
                                    >
                                      Cancel
                                    </Button> */}
                                          </div>
                                        </div>
                                      </div>
                                    </Popper>
                                    <span className="label">
                                      Delete Timeline
                                    </span>
                                  </li>
                                </ul>
                              )}
                            </>
                          ))}
                        {/* {openTimelineStatus && (
                      <div>
                        <TilingStatus id={loadingId} />
                      </div>
                    )} */}
                      </>
                    )}
                  {asset.type === "3DTILES" && (
                    <>
                      <div className="flex relative justify-between ">
                        {showComparer && (
                          <Checkbox
                            color="default"
                            size="small"
                            id={asset.id}
                            checked={compareAssets.includes(asset.id)}
                            disabled={
                              compareAssets.includes(asset.id) &&
                              compareAssets.length > 3
                            }
                            onChange={() => props.compareAssetsArray(asset.id)}
                          />
                        )}
                        <div
                          className={`w-full m-1 cursor-pointer py-1 px-2`}
                          onClick={() =>
                            dispatch(
                              gisHomeActions.setAsset({
                                asset: asset.id,
                                assetType: asset.type,
                              })
                            )
                          }
                        >
                          {asset.date
                            ? getDate(asset.date)
                            : asset.name.split("_").join(" ")}
                        </div>
                        {(asset.status === "IN_PROGRESS" ||
                          asset.status === "NOT_STARTED") && (
                          <div className="flex items-center absolute left-[40%] top-[18%]">
                            <InfoOutlinedIcon
                              onClick={(event) => {
                                handleStatusClick(event, asset.id);
                                // setOpenTimelineStatus(!openTimelineStatus);
                              }}
                            />
                          </div>
                        )}
                        <button
                          onClick={() => toggleDropdown(asset.id)}
                          className={
                            openDropdownMap[asset.id] ? "rotated mr-2" : "mr-2"
                          }
                        >
                          <img
                            src={chevronIcoDropdown}
                            alt=""
                            className="h-8"
                            srcSet=""
                          />
                        </button>
                      </div>

                      {openDropdownMap[asset.id] && ( // Check if the dropdown is open for the current item
                        <ul className="dropdown-list px-2  flex flex-col gap-2 border border-t-2 rounded-lg rounded-b-none p-2 bg-white">
                          {/* <li
                            className="dropdown-item flex gap-2 hover:bg-blue-200  rounded-lg  items-center cursor-pointer"
                            onClick={() => handleOpenModal(asset.name)}
                          >
                            <div className="logo cursor-pointer bg-blue-200 rounded p-[4px] w-max ">
                              <Tooltip
                                title="Annotation Report"
                                placement="right"
                                arrow
                              >
                                <img className="h-5" src={icon} alt="" />
                              </Tooltip>
                            </div>
                            <span className="label">Annotation Report</span>
                          </li> */}
                          {/* <li
                            className="dropdown-item flex gap-2 cursor-pointer hover:bg-green-200 rounded-lg items-center"
                            onClick={() => handleOpenModalProgress()}
                          >
                            <div className="logo cursor-pointer bg-green-200 rounded p-[4px] w-max">
                              <Tooltip
                                title="Progress Report"
                                placement="right"
                                arrow
                              >
                                <img className="h-5" src={icon} alt="" />
                              </Tooltip>
                            </div>
                            <span className="label">Progress Report</span>
                          </li> */}
                          <li
                            className="dropdown-item flex gap-2 hover:bg-red-200 rounded-lg items-center cursor-pointer"
                            aria-describedby={id}
                            // type="button"
                            onClick={handleClick}
                          >
                            <div className="logo cursor-pointer bg-red-200  p-[2px] w-max rounded">
                              <Tooltip
                                title="Delete Timeline"
                                placement="right"
                                arrow
                              >
                                <img className=" h-6" src={deleteIco} alt="" />
                              </Tooltip>
                            </div>
                            <Popper
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              placement={
                                open === true ? "bottom" : "right-start"
                              }
                              sx={{
                                zIndex: 999,
                              }}
                            >
                              <div className=" shadow-md border rounded-lg bg-white flex flex-col gap-4 w-300 mt-2">
                                <div className="w-full rounded-lg flex flex-col items-center p-4 ">
                                  <p className="mb-4">
                                    Are you sure you want to delete this item?
                                  </p>
                                  <div className="flex gap-4">
                                    <button
                                      className="px-2 py-1 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none"
                                      onClick={() => {
                                        deleteTimeline(asset.id);
                                      }}
                                    >
                                      <p className="text-sm">Confirm</p>
                                    </button>
                                    <button className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none">
                                      <p className="text-sm">Cancel</p>
                                    </button>

                                    {/* <Button
                                    variant="contained"
                                    color="success"
                                    size="small"
                                    // onClick={props.deleteItem} // Handle the delete action
                                  >
                                    Confirm
                                  </Button> */}
                                    {/* <Button
                                    variant="contained"
                                    // onClick={handleClose} // Close the Popper
                                  >
                                    Cancel
                                  </Button> */}
                                  </div>
                                </div>
                              </div>
                            </Popper>
                            <span className="label">Delete Timeline</span>
                          </li>
                        </ul>
                      )}
                      {/* {openTimelineStatus && (
                      <div>
                        <TilingStatus id={loadingId} />
                      </div>
                    )} */}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <>
          {PILDates?.map((asset, index) => (
            <div
              className={`box-showl flex grow    ${
                universalAsset === asset.name
                  ? "bg-[#f0f8ff]"
                  : showComparer === true
                  ? "bg-white"
                  : ""
              }`}
              key={index}
            >
              {showComparer && (
                <Checkbox
                  color="default"
                  size="small"
                  id={index}
                  checked={compareAssets.includes(asset.name)}
                  disabled={
                    compareAssets.includes(asset.name) &&
                    compareAssets.length > 3
                  }
                  onChange={() => props.compareAssetsArray(asset.name)}
                />
              )}
              <div
                className={`w-full border-b cursor-pointer py-2 px-2 flex justify-between   `}
                onClick={() =>
                  dispatch(
                    gisHomeActions.setAsset({
                      asset: asset.name,
                      assetType: "GEOSERVER",
                    })
                  )
                }
              >
                {getLastWord(asset.name)} 2023
              </div>
            </div>
          ))}
        </>
      )}

      {props.grp === "skyhigh" ? (
        mode === "2D" ? (
          skyhighDates?.map((asset, index) => (
            <div
              className={`box-showl flex grow    ${
                universalAsset === asset.name
                  ? "bg-[#f0f8ff]"
                  : showComparer === true
                  ? "bg-white"
                  : ""
              }`}
              key={index}
            >
              {showComparer && (
                <Checkbox
                  color="default"
                  size="small"
                  id={index}
                  checked={compareAssets.includes(asset.name)}
                  disabled={
                    compareAssets.includes(asset.name) &&
                    compareAssets.length > 3
                  }
                  onChange={() => props.compareAssetsArray(asset.name)}
                />
              )}
              <div
                className={`w-full border-b cursor-pointer py-2 px-2 flex justify-between   `}
                onClick={() =>
                  dispatch(
                    gisHomeActions.setAsset({
                      asset: asset.name,
                      assetType: "GEOSERVER",
                    })
                  )
                }
              >
                {asset.name.split("_")[3]}
              </div>
            </div>
          ))
        ) : (
          <div
            className={`w-full border-b cursor-pointer py-2 px-2 flex justify-between  bg-[#f0f8ff] `}
          >
            SKY HIGH GOA 3D
          </div>
        )
      ) : null}

      {isReportModalOpen && (
        <ReportModal
          annotation={selectedAnnotation}
          onClose={handleCloseModal}
          dataJson={dataJson}
          elementWidth={elementWidth}
          type="Annotation"
        />
      )}
      {isProgressReportModalOpen && (
        <ReportModal
          annotation={selectedAnnotation}
          onClose={handleCloseModalProgress}
          dataJson={dataJson}
          elementWidth={elementWidth}
          type="Progress"
        />
      )}
    </div>
  );
};

export default GisSidebar;
