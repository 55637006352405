import React from "react";
import { MapContainer, TileLayer, Polyline, Marker } from "react-leaflet";
import L from "leaflet";
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";

const createPath = (lat_lon) => lat_lon.map(({ lat, lon }) => [lat, lon]);

const tileLayers = [
  {
    name: "Street",
    tile: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    name: "Satellite",
    tile: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
  },
];

const MapLine = (props) => {
  const mapRef = React.useRef(null);
  const [currentTileLayer, setCurrentTileLayer] = React.useState(
    tileLayers[1].tile
  );
  const [buttonName, setButtonName] = React.useState(tileLayers[1].name);

  const handleTileChange = () => {
    const newTileLayer =
      currentTileLayer === tileLayers[0].tile
        ? tileLayers[1].tile
        : tileLayers[0].tile;
    setCurrentTileLayer(newTileLayer);
    const newButtonName =
      buttonName === tileLayers[0].name
        ? tileLayers[1].name
        : tileLayers[0].name;
    setButtonName(newButtonName);
  };


  React.useEffect(() => {
    setTimeout(() => {
      if (mapRef.current) {
        const map = mapRef.current;
        const bounds = L.polyline(props.location).getBounds();
        map.fitBounds(bounds);
      }
    }, 0);
  }, []);

  return (
    <div
      className={`h-full w-full mab_project relative ${props.modalView && "rounded-b-[12px]"
        }`}
    >
      <MapContainer
        ref={mapRef}
        zoom={15}
        scrollWheelZoom={false}
        dragging={props.modalView}
        zoomControl={props.modalView}
        tap={props.modalView}
        doubleClickZoom={props.modalView}
        className={`h-full border ${props.modalView && "rounded-b-[12px]"}`}
      >
        <TileLayer
          className={`h-full border ${props.modalView && "rounded-b-[12px]"}`}
          url={currentTileLayer}
        />

        <Polyline
          className="h-full"
          positions={createPath(props.location)}
          color={"blue"}
          weight={3}
        />
        {/* <Marker position={start_location} /> */}
      </MapContainer>
      {props.modalView && (
        <button
          className="absolute top-2 bg-white p-2 border-2 border-black/[.3] rounded-lg right-5 z-[999]"
          onClick={handleTileChange}
        >
          {buttonName}
        </button>
      )}
    </div>
  );
};

export default MapLine;
