import { createSlice } from "@reduxjs/toolkit";

const filterToggleSlice = createSlice({
  name: "map-toggle",
  initialState: { filterList: [] },
  reducers: {
    setFilter(state, action) {
      state.filterList = action.payload;
    },
  },
});

export const filterToggleActions = filterToggleSlice.actions;

export default filterToggleSlice.reducer;
