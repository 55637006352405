import { createSlice } from "@reduxjs/toolkit";

const gisHomeSlice = createSlice({
  name: "gis-home",
  initialState: {
    // viewerRef: null,
    grpData: [],
    asset: null,
    assetType: "TERRAIN", // for imagery and 3d data
    compareAssets: [], // show compare tool toggle
    shapesIds: [], //selectedShapesIds
    bottomReportToggle: false,
    selectedTimeline: "",
    mode: "2D",
  },

  reducers: {
    setGrpData(state, action) {
      state.grpData = action.payload;
    },
    setAsset: (state, action) => {
      state.asset = action.payload.asset;
      state.assetType = action.payload.assetType;
    },
    setCompareAssets: (state, action) => {
      state.compareAssets = action.payload;
    },
    // shape files part (geojson)
    setShapesIds: (state, action) => {
      state.shapesIds = action.payload;
    },
    addShapeId: (state, action) => {
      state.shapesIds = [...state.shapesIds, action.payload];
    },
    removeShapeId: (state, action) => {
      const assetId = action.payload;
      state.shapesIds = state.shapesIds.filter((id) => id !== assetId);
    },
    bottomReportToggle: (state) => {
      state.bottomReportToggle = !state.bottomReportToggle;
    },
    setSelectedTimeline: (state, action) => {
      state.selectedTimeline = action.payload;
    },
    setMode: (state, action) => {
      let modeState;
      if (state.mode === "2D") {
        state.mode = "3D";
        modeState = "3D";
      } else {
        state.mode = "2D";
        modeState = "2D";
      }
      // action.payload ? (state.mode = action.payload) : modeState;
    },
  },
});

export const gisHomeActions = gisHomeSlice.actions;

export default gisHomeSlice.reducer;
