import axios from "axios";

export class HeartbeatApis {
  static getAssets = async (clientName) => {
    try {
      const response = await axios.get(
        `https://inspecthb.indrones.com/get_all_entries/${clientName}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // //console.log(response.data);
      return { data: response.data[0], success: true };
    } catch (error) {
      console.error(error);
      return { data: error, success: false };
    }
  };

  static clipVideo = async (params) => {
    try {
      const response = await axios.post(
        "https://inspecthb.indrones.com/trim_video",
        params,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // //console.log(response.data);
      return response;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  static createSnapshot = async (params) => {
    try {
      const response = await axios.get(
        "https://inspecthb.indrones.com/screenshot_video",
        {
          params: params,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // //console.log(response.data);
      return { data: response.data.ss_s3_link, success: true };
    } catch (error) {
      console.error(error);
      return { data: error, success: false };
    }
  };
}
