import testData_Virar_Dahanu from "../../src/Data/Jsons/MRVC_VR_DRD_JUN_2023_R6_vid_time_wise_ann.json";

function convertTimeToSeconds(timeString) {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0]);
  const minutes = parseInt(timeParts[1]);
  const seconds = parseInt(timeParts[2]);
  const totalTimeInSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalTimeInSeconds;
}

export var latLngs_virar_dhu = [];
for (let i = 0; i < testData_Virar_Dahanu.length; i++) {
  var obj = {
    lat: testData_Virar_Dahanu[i].lat,
    lon: testData_Virar_Dahanu[i].lon,
    time: convertTimeToSeconds(testData_Virar_Dahanu[i].video_time),
    chainage: testData_Virar_Dahanu[i].chainage,
  };
  latLngs_virar_dhu.push(obj);
}
