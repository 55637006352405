import React from "react";
import Logo from "../../Assets/images/ID_LOGO_BLACK-04.png";
import indianOILLogo from "../../Assets/images/iocl.png";
import mvrcLogo from "../../Assets/images/mrvc.png";
import AGPLogo from "../../Assets/images/agp_logo.png";
import Cookies from "js-cookie";
import menubar from "../../Assets/images/menubaricon.svg";
import inspectlogo from "../../Assets/images/inspect-logo-transparent-png.png";
import { nhaiLogo } from "../../Assets/images/servicesImg/services";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();
  const [imgicon, setImgicon] = React.useState(null);
  const [isMobile, setIsMobile] = React.useState(false);
  const [matches, setMatches] = React.useState(
    window.matchMedia("(max-width: 767px)").matches
  );
  const cookieValue = Cookies.get("clientName");
  var clientLogo;
  if (cookieValue === "oil") {
    clientLogo = indianOILLogo;
  } else if (cookieValue === "infra") {
    clientLogo = AGPLogo;
  } else if (cookieValue === "hway") {
    clientLogo = nhaiLogo;
  } else {
    clientLogo = mvrcLogo;
  }

  React.useEffect(() => {
    window
      .matchMedia("(max-width: 767px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  React.useEffect(() => {
    if (matches) {
      setImgicon(menubar);
      setIsMobile(true);
    } else {
      setImgicon(Logo);
      setIsMobile(false);
    }
  }, [matches]);
  return (
    <>
      <nav className="nav-top bg-[#fff] h-[60px]">
        {isMobile && (
          <img
            className={
              isMobile
                ? "text-[#4a4844] w-[2em] ml-2 cursor-pointer"
                : "h-full cursor-pointer ml-2"
            }
            src={imgicon}
            alt="ERROR 404!"
            onClick={() => {
              navigate("/projects");
              if (isMobile) {
                props.drawerOpen();
              }
            }}
          />
        )}

        <img
          src={inspectlogo}
          alt="logo"
          className="w-[40%] h-auto md:h-full md:w-auto "
        />

        {Cookies.get("clientName") === "skyhigh" ? null : (
          <img
            src={clientLogo}
            alt="logo"
            className={`w-[40%] h-auto md:h-full md:w-auto mr-2 pt-[5px] pb-[5px] pl-[5px]   ${
              cookieValue !== "mine" ? "block" : "invisible"
            }`}
          />
        )}
      </nav>
    </>
  );
};

export default Header;
