import React from "react";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ExpressApis } from "../../../Services/SecondPartyApi/Express";
import toast from "react-hot-toast";

var railwayCategory = [
  {
    name: "cl",
    label: "CL",
  },
  {
    name: "prop",
    label: "PROP",
  },
  {
    name: "crossing",
    label: "CROSSINGS",
  },
  {
    name: "rob",
    label: "ROB",
  },
  {
    name: "proposed",
    label: "PROPOSED",
  },
  {
    name: "fob",
    label: "FOB",
  },
  {
    name: "rub",
    label: "RUB",
  },
  {
    name: "station",
    label: "RAILWAY STATION",
  },
  {
    name: "others",
    label: "OTHER",
  },
];
var indianOilCategory = [
  {
    name: "encrochment",
    label: "ENCROACHMENT",
  },
  {
    name: "soil_erosion",
    label: "SOIL EROSION",
  },
  {
    name: "external_damage",
    label: "EXTERNAL DAMAGE",
  },
  {
    name: "leakage",
    label: "LEAKAGE",
  },
  {
    name: "fire",
    label: "FIRE",
  },
  {
    name: "miscreant_activity",
    label: "MISCREANT ACTIVITY",
  },
  {
    name: "others",
    label: "OTHER",
  },
  {
    name: "crossing",
    label: "CROSSING",
  },
];

const EditAnnotations = (props) => {
  var data = props.data;
  const [severity, setSeverity] = React.useState(data.severity);
  const [description, setDescription] = React.useState(data.description);

  const handleSeverity = (e) => {
    setSeverity(e.target.value);
  };

  var annoParams = {};
  const handleEditAnnotations = async (event) => {
    event.preventDefault();
    annoParams.metadata_id = data.id;
    annoParams.description = event.target.description.value;
    annoParams.severity = event.target.severity.value;
    annoParams.start_time = data.time;

    try {
      const response = await ExpressApis.editAnnotation(annoParams);
      // console.log("edit resp", response);
      props.handleAnnoRefresh();
      toast.success("Annotation Editted Sucessfully");
    } catch (error) {
      toast.error("Couldn't Edit Annotation");
    }
    props.handleShowEdit(props.index);
  };

  //   let categories;
  //   if (props.isIOCL) {
  //     categories = indianOilCategory;
  //   } else {
  //     categories = railwayCategory;
  //   }

  return (
    <form
      className="flex flex-col gap-4 mt-5"
      onSubmit={(event) => handleEditAnnotations(event)}
    >
      <div className="flex items-center mx-[5px] ">
        <TextField
          id="vtime"
          label="Video Time"
          variant="outlined"
          className="w-full"
          size="small"
          name="video_time"
          InputProps={{
            readOnly: true,
          }}
          value={data.time}
        />
      </div>
      <div className="flex items-center mx-[5px]">
        <TextField
          id="chainage"
          label="Chainage"
          variant="outlined"
          className="w-full"
          name="chainage"
          size="small"
          value={data.chainage}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <div className="flex flex-col gap-2 items-center mx-[5px]">
        <TextField
          id={`label${1}`}
          label="Label"
          variant="outlined"
          size="small"
          name="description"
          value={description}
          className="w-full"
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      {/* <div className="flex items-center mx-[5px]">
        <FormControl fullWidth size="small">
          <InputLabel id="demo-simple-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="category"
            value={data.category}
            label="Category"
            name="category"
            className="w-full z-[99] h-[40px]"
            // onChange={(e) => handleCategory(index, e.target.value)}
          >
            {categories.map((category, index) => {
              return (
                <MenuItem key={index} value={category.name}>
                  {category.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div> */}
      <div className="flex items-center mx-[5px]">
        <FormControl fullWidth size="small">
          <InputLabel id="severity">Severity Level</InputLabel>
          <Select
            id="slevel"
            name="severity"
            value={severity}
            onChange={handleSeverity}
            label="Severity Level"
            className="w-full z-[9] h-[40px]"
          >
            <MenuItem value="low">
              <span className="text-green-500">Low</span>
            </MenuItem>
            <MenuItem value="medium">
              <span className="text-orange-500">Medium</span>
            </MenuItem>
            <MenuItem value="high">
              <span className="text-red-500">High</span>
            </MenuItem>
            <MenuItem value="RFI">
              <span className="text-yellow-500">
                Require Further Investigation
              </span>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <Divider />
      <div className="mb-[9px] flex  justify-center">
        <button
          aria-label="add"
          className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
          type="submit"
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default EditAnnotations;
