import React from "react";
import axios from "axios";
import * as AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { gisActions } from "../../redux/slices/GIS/gis-slice";
import {
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  Modal,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useParams } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import CheckIcon from "@mui/icons-material/Check";
import dayjs from "dayjs";
import toast from "react-hot-toast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const dropzoneStyle = {
  borderRadius: "4px",
  cursor: "pointer",
  textAlign: "center",
  border: "2px dashed #cccccc",
};

const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNzhkZjc0ZS02YzQzLTRjMzAtODg3ZC0zOTk4MzFlMWRmNzgiLCJpZCI6MTU5MTU1LCJpYXQiOjE2OTIyODIwODV9._OhEfJPn8nLJ6LwuOSd7-Z3MB8MpQT_FwyxIOnWL2cI";

const CesiumAsset = (props) => {
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [uploadStatus, setUploadStatus] = React.useState(false);
  const [tilingStatus, setTilingStatus] = React.useState(false);
  const [date, setDate] = React.useState("");

  const dispatch = useDispatch();

  const onDrop = (acceptedFiles) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const { grp } = useParams();

  const postBody = {
    name: `${grp}_${date}`,
    description: "Uploaded from Inspect",
    type: "IMAGERY",
    options: {
      sourceType: "RASTER_IMAGERY",
      clampToTerrain: true,
      baseTerrainId: 1,
    },
  };

  const onFileUpload = async () => {
    if (uploadedFile) {
      try {
        const response = await axios.post(
          `https://api.cesium.com/v1/assets`,
          postBody,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(response.data);
        // const assetMetaData = response.data.assetMetaData;
        setUploadStatus(true);
        const onComplete = response.data.onComplete;
        const uploadLocation = response.data.uploadLocation;

        const s3 = new AWS.S3({
          apiVersion: "2006-03-01",
          region: "us-east-1",
          signatureVersion: "v4",
          endpoint: uploadLocation.endpoint,
          credentials: new AWS.Credentials(
            uploadLocation.accessKey,
            uploadLocation.secretAccessKey,
            uploadLocation.sessionToken
          ),
        });

        const params = {
          Bucket: uploadLocation.bucket,
          Key: `${uploadLocation.prefix}${uploadedFile.name}`,
          Body: uploadedFile,
        };

        try {
          // console.log("Upload to S3 Started");
          const data = await s3.upload(params).promise();
          // console.log("File uploaded successfully. S3 Object details:", data);
          const completed = await axios.post(
            `${onComplete.url}`,
            onComplete.fields,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setUploadStatus(false);
          setTilingStatus(true);
          // console.log("Final", completed);
          // uploadStatus(assetMetaData.id);
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      toast.error("No File Found!");
    }
  };

  const { getRootProps } = useDropzone({
    onDrop,
  });

  return (
    <>
      <Modal open={props.isAssetModalOpen} onClose={props.onClose}>
        <div
          style={style}
          className="flex flex-col items-center justify-between bg-white w-[40%] h-auto rounded-[12px]"
        >
          <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px] w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
            <h3 className="!text-[1.20rem]  text-[#211f1c] grow flex items-center justify-center">
              Add Ori
            </h3>
            <div
              className="mr-[10px]"
              onClick={() => dispatch(gisActions.refCounterUpdate())}
            >
              <IconButton
                onClick={props.onClose}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="flex flex-col h-full w-full gap-3 p-5 mx-auto items-start">
            <div className="w-[100%]">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <FormControl fullWidth>
                    <DatePicker
                      label="Timeline"
                      slotProps={{ textField: { size: "small" } }}
                      onChange={(selectedDate) => {
                        setDate(dayjs(selectedDate).format("DD_MM_YY"));
                      }}
                    />
                  </FormControl>
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <div
              className="flex flex-col h-[250px] w-[100%]"
              {...getRootProps()}
              style={dropzoneStyle}
            >
              {/* <input {...getInputProps()} /> */}
              <p className="m-auto">
                Drag & drop a file here or click to select a file
              </p>
            </div>
            {uploadedFile && !uploadStatus && !tilingStatus && (
              <div>{`File to Upload : ${uploadedFile.name}`}</div>
            )}

            {uploadStatus && (
              <div className="flex items-center justify-center gap-3 mx-auto">
                {`Uploading File ${uploadedFile?.name} to Database.`}
                <CircularProgress size={30} />
              </div>
            )}

            {tilingStatus && (
              <>
                <div className="flex flex-col item-center mx-auto gap-2">
                  <div className="flex items-center justify-center gap-3 mx-auto">
                    {`File ${uploadedFile?.name} Uploaded.`}
                    {/* <Fab color="success" aria-label="add" size="small">
                    <CheckIcon />
                  </Fab> */}
                  </div>
                  <div>Tiling has Started</div>
                </div>
                <div className="flex w-[100%] gap-3 items-end">
                  <button
                    onClick={() => {
                      dispatch(gisActions.refCounterUpdate());
                      props.onClose();
                    }}
                    className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] mx-auto rounded hover:bg-[#F6F1E9] cursor-pointer my-2"
                  >
                    Close
                  </button>
                </div>
              </>
            )}

            {!tilingStatus && !uploadStatus && (
              <div className="flex w-[100%] gap-3 items-end">
                <button
                  onClick={onFileUpload}
                  className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] mx-auto rounded hover:bg-[#F6F1E9] cursor-pointer my-2"
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CesiumAsset;
