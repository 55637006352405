import {
  Cartesian2,
  Cartographic,
  Math as MathOfCesium,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
} from "cesium";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export function usePointMarkerAnnotation(
  viewerRef,
  setSelectedPosition,
  handleOpen
) {
  const { annotationTool } = useSelector((state) => state.gisTools);

  useEffect(() => {
    const viewer = viewerRef.current;

    const positionMarker = (event) => {
      if (annotationTool) {
        const mousePosition = new Cartesian2(
          event.position.x,
          event.position.y
        );
        const cartesian = viewer.camera.pickEllipsoid(
          mousePosition,
          viewer.scene.globe.ellipsoid
        );

        if (cartesian) {
          const cartographic = Cartographic.fromCartesian(cartesian);
          const newLongitude = MathOfCesium.toDegrees(cartographic.longitude);
          const newLatitude = MathOfCesium.toDegrees(cartographic.latitude);

          setSelectedPosition({
            longitude: newLongitude,
            latitude: newLatitude,
          });
          handleOpen();
        }
      } else {
        // //console.log("disabled");
      }
    };
    if (viewer) {
      const mouseHandler = new ScreenSpaceEventHandler(viewer.canvas);
      const event = ScreenSpaceEventType.RIGHT_CLICK;

      // Attach or detach the event handler based on the enable state
      if (annotationTool) {
        mouseHandler.setInputAction(positionMarker, event);
      } else {
        mouseHandler.removeInputAction(event);
      }

      return () => {
        mouseHandler.removeInputAction(event);
      };
    }
  }, [annotationTool]);
}
