import React from "react";
import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { getRouteID } from "../../../Utils/OtherUtils";
import { formatTime } from "../../../Utils/OtherUtils";
import { ExpressApis } from "../../../Services/SecondPartyApi/Express";
import toast from "react-hot-toast";

const findChainage = (time, dataBase) => {
  const getData = dataBase.find((item) => item.start_time === time);
  if (getData) {
    const chainage = getData.chainage;
    return chainage.toFixed(1);
  }
};

var railwayCategory = [
  {
    name: "cl",
    label: "CL",
  },
  {
    name: "prop",
    label: "PROP",
  },
  {
    name: "crossing",
    label: "CROSSINGS",
  },
  {
    name: "rob",
    label: "ROB",
  },
  {
    name: "proposed",
    label: "PROPOSED",
  },
  {
    name: "fob",
    label: "FOB",
  },
  {
    name: "rub",
    label: "RUB",
  },
  {
    name: "station",
    label: "RAILWAY STATION",
  },
  {
    name: "others",
    label: "OTHER",
  },
];

var indianOilCategory = [
  "ENCROACHMENT",
  "SOIL EROSION",
  "EXTERNAL DAMAGE",
  "LEAKAGE",
  "FIRE",
  "MISCREANT ACTIVITY",
];

const AddAnnotations = (props) => {
  const location = useLocation();
  const routeID = getRouteID(location);
  const currentTime = props.currentTime;
  const currentTime_R4 = props.currentTime_R4;
  const [category, setCategory] = React.useState("");
  const [vDate, setVDate] = React.useState(routeID[0]);
  const [severity, setSeverity] = React.useState("");
  const [time, setTime] = React.useState(formatTime(currentTime));
  const [chainage, setChainage] = React.useState("");
  const dataToUse = useSelector((state) => state.project.data);
  const dataToUse2 = useSelector((state) => state.project.data2);
  const projectId = useSelector((state) => state.project.projectId);
  const projectId2 = useSelector((state) => state.project.projectId2);
  const isCompare = useSelector((state) => state.project.isCompare);
  const [id, setId] = React.useState(projectId);

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleSeverity = (e) => {
    setSeverity(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTime(e.target.value);
  };

  const handleChainageChange = (e) => {
    setChainage(e.target.value);
  };

  const handleDateChange = (e) => {
    setVDate(e.target.value);
  };

  var dataToPush = {};
  const addAnnotation = async (event) => {
    event.preventDefault();
    dataToPush.timeline_id = id;
    dataToPush.start_time = event.target.start_time.value;
    dataToPush.end_time = event.target.end_time.value;
    dataToPush.description = event.target.description.value;
    dataToPush.category = event.target.category.value;
    dataToPush.severity = event.target.severity.value;
    try {
      const response = await ExpressApis.addAnnotation(dataToPush);
      props.openPopper();
      props.handleAnnoRefresh();
      toast.success("Annotation Added Sucessfully");
    } catch (error) {
      toast.error("Couldn't Add Annotation");
    }
  };

  React.useEffect(() => {
    const currentTimeFormatted = formatTime(currentTime);

    if (!isCompare) {
      setChainage(findChainage(currentTimeFormatted, dataToUse));
      setId(projectId);
    } else {
      if (vDate) {
        setChainage(findChainage(currentTimeFormatted, dataToUse));
        setTime(currentTimeFormatted);
        setId(projectId);
      } else {
        const currentTimeFormattedR4 = formatTime(currentTime_R4);
        setChainage(findChainage(currentTimeFormattedR4, dataToUse2));
        setTime(currentTimeFormattedR4);
        setId(projectId2);
      }
    }
  }, [
    currentTime,
    currentTime_R4,
    vDate,
    routeID,
    isCompare,
    dataToUse,
    dataToUse2,
    projectId,
    projectId2,
  ]);

  let categories;
  if (projectId === "5" || projectId === "6") {
    categories = indianOilCategory;
  } else {
    categories = railwayCategory;
  }

  return (
    <div className="bg-box-model rounded-[12px] bg-white  flex flex-col gap-4 w-[300px] ml-[10px]">
      <div className="w-full rounded-tl-[12px] rounded-tr-[12px]  flex items-center bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
        <div className="grow flex items-center justify-center">
          Add Annotations
        </div>
        <div className="mr-[10px]" onClick={props.openPopper}>
          <IconButton aria-label="delete" size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </div>

      <form
        className="p-[12px] flex flex-col gap-[15px]"
        onSubmit={addAnnotation}
      >
        {isCompare && (
          <FormControl fullWidth size="small">
            <InputLabel id="test-select-label">Video Choice</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="vDate"
              value={vDate}
              label="Video Choice"
              name="vDate"
              className="w-full h-[40px] z-[999]"
              onChange={handleDateChange}
            >
              <MenuItem value={1}>Video 1 (left)</MenuItem>
              <MenuItem value={0}>Video 2 (right)</MenuItem>
            </Select>
          </FormControl>
        )}
        <TextField
          id="vtime"
          className="vtime w-full h-[40px] custom-text"
          label="Video Time"
          variant="outlined"
          onChange={handleTimeChange}
          name="start_time"
          value={time}
          required
        />
        <TextField
          id="chainage"
          label="Chainage"
          variant="outlined"
          className="w-full"
          value={chainage}
          name="chainage"
          size="small"
          required
          onChange={handleChainageChange}
        />
        <TextField
          id="endtime"
          label="End Time"
          variant="outlined"
          name="end_time"
          className="w-full"
          size="small"
          required
        />
        <TextField
          id="label"
          label="Label"
          variant="outlined"
          name="description"
          className="w-full"
          size="small"
          required
        />
        <FormControl fullWidth size="small">
          <InputLabel id="test-select-label">Category</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="category"
            value={category}
            label="Category"
            name="category"
            className="w-full z-[999]"
            onChange={handleCategory}
          >
            {categories.map((category, index) => {
              return (
                <MenuItem key={index} value={category.name}>
                  {category.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl fullWidth size="small">
          <InputLabel id="test-select-label">Severity Level</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="slevel"
            name="severity"
            value={severity}
            onChange={handleSeverity}
            label="Severity Level"
            className="w-full h-[40px] z-[999]"
          >
            <MenuItem value="low">
              <span className="text-green-500">Low</span>
            </MenuItem>
            <MenuItem value="medium">
              <span className="text-orange-500">Medium</span>
            </MenuItem>
            <MenuItem value="high">
              <span className="text-red-500">High</span>
            </MenuItem>
            <MenuItem value="RFI">
              <span className="text-yellow-500">
                Require Further Investigation
              </span>
            </MenuItem>
          </Select>
        </FormControl>
        <Divider />
        <div className="flex  justify-end">
          <button
            onClick={props.openPopper}
            type="button"
            className="!rounded-[5px] bg-[#a9a7a4] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#d7d5d2] mr-[7px]"
          >
            Close
          </button>
          <button
            aria-label="add"
            className="!rounded-[5px] bg-[#4a4844] text-white rounded px-[20px] text-[14px] py-[5px] h-[32px] hover:bg-[#716e69]"
            type="submit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddAnnotations;
