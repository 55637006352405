export const dataJson = {
  ReportName: "AGP Group",
  Reportee: "Aman Mishra",
  Supervisor: "Anand Mehta",
  Contractor: "Mohit Shrivastav",
  DurationOfContract: "2021-2023",
  OutputType: "Raster Imagery",
  UnitOfMeasurement: "Tonne",
  Data: [
    {
      Date: "07-02-2022",
      WasteGenerated: 1240,
      WasteCollected: 760,
      GapInCleaningPercentage: 63.16,
      TimeToCleanHours: 36,
      RecyclablePercentage: 7.45,
      ReusablePercentage: 2.76,
      WasteGenerationTrend: 0,

    },
    {
      Date: "07-03-2022",
      WasteGenerated: 1150,
      WasteCollected: 1093,
      GapInCleaningPercentage: 5.22,
      TimeToCleanHours: 23,
      RecyclablePercentage: 2.34,
      ReusablePercentage: 6.89,
      WasteGenerationTrend: -7.25,

    },
    {
      Date: "07-04-2022",
      WasteGenerated: 2500,
      WasteCollected: 2245,
      GapInCleaningPercentage: 11.36,
      TimeToCleanHours: 77,
      RecyclablePercentage: 5.4,
      ReusablePercentage: 2.1,
      WasteGenerationTrend: 117.39,

    },
    {
      Date: "07-05-2022",
      WasteGenerated: 875,
      WasteCollected: 540,
      GapInCleaningPercentage: 62.04,
      TimeToCleanHours: 12,
      RecyclablePercentage: 12.6,
      ReusablePercentage: 1.4,
      WasteGenerationTrend: -65,

    },
    {
      Date: "07-06-2022",
      WasteGenerated: 980,
      WasteCollected: 890,
      GapInCleaningPercentage: 9.18,
      TimeToCleanHours: 28,
      RecyclablePercentage: 3.2,
      ReusablePercentage: 4.6,
      WasteGenerationTrend: 12.34,

    },
    {
      Date: "07-07-2022",
      WasteGenerated: 750,
      WasteCollected: 680,
      GapInCleaningPercentage: 9.33,
      TimeToCleanHours: 32,
      RecyclablePercentage: 6.8,
      ReusablePercentage: 2.1,
      WasteGenerationTrend: -15.78,

    },
    {
      Date: "07-08-2022",
      WasteGenerated: 1420,
      WasteCollected: 1300,
      GapInCleaningPercentage: 8.45,
      TimeToCleanHours: 41,
      RecyclablePercentage: 4.5,
      ReusablePercentage: 3.7,
      WasteGenerationTrend: 25,

    },
    {
      Date: "07-09-2022",
      WasteGenerated: 630,
      WasteCollected: 620,
      GapInCleaningPercentage: 1.59,
      TimeToCleanHours: 19,
      RecyclablePercentage: 2.1,
      ReusablePercentage: 1.8,
      WasteGenerationTrend: -5,

    },
  ],
};
