import {
  CircularProgress,
  IconButton,
  Modal,
  Tabs,
  TextField,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  createStream,
  deleteChannel,
} from "../../Features/GIS/Utils/LiveStreamUtils";

import {
  DynamoDBApis,
  AnnotationManager,
} from "../../Services/ThirdPartyApi/AWS/DynamoDB";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className="h-full w-full">{children}</div>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const VodBox = (props) => {
  const [value, setValue] = React.useState(0);
  const [streamName, setStreamName] = React.useState("");
  const [rtmpLink, setRtmpLink] = React.useState(null);
  const [streamUrl, setStreamUrl] = React.useState(null);
  const [channelArn, setChannelArn] = React.useState(null);
  const [dbData, setDbData] = React.useState(null);
  const [loading, setloading] = React.useState(false);
  const getData = props.selectedMarker;

  const { userPermissions } = useSelector((state) => state.gis);

  // const oriDynamoDBApis = new DynamoDBApis("ori_annotations");
  // const oriAnnotationManager = new AnnotationManager("ori_annotations");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  React.useEffect(() => {
    const retriveLinks = async () => {
      setRtmpLink(null);
      setStreamUrl(null);
      const links = await DynamoDBApis.getDataBygrpID(getData.grpID);
      if (links.length > 0) {
        setRtmpLink(links[0].rtmp_link);
        setStreamUrl(links[0].playback_url);
        setChannelArn(links[0].channel_arn);
        setDbData(links[0]);
      }
    };
    retriveLinks();
  }, [getData]);

  const deleteStream = (channel_arn) => {
    if (dbData.id !== null) {
      deleteChannel(channel_arn);
      AnnotationManager.deleteAnnotations(dbData.id, dbData.timestamp);
      props.handleVodClose();
    }
  };

  const handleCreateStream = async (event) => {
    event.preventDefault();
    const stream_name = event.target.streamName.value;
    setloading(true);
    const data = await createStream(stream_name);
    setloading(false);
    DynamoDBApis.addStreamLinks({
      id: uuidv4(),
      timestamp: Date.now(),
      rtmp_link: data.rtmp_link,
      playback_url: data.playback_url,
      channel_arn: data.channel_arn,
      grpID: getData.grpID,
    });
    setRtmpLink(data.rtmp_link);
    setStreamUrl(data.playback_url);
  };
  const AgpVodData = [
    {
      name: "Entry View",
      url: "https://dgi6n13b4acc9.cloudfront.net/7ba33f6f-a0cd-4956-8978-9e35ca7a932a/AppleHLS1/entry_view_processed.m3u8",
    },
    {
      name: "North Boundary",
      url: "https://dgi6n13b4acc9.cloudfront.net/3d47b4f8-152d-4931-9dfa-6dec7452ec73/AppleHLS1/boundary_north_processed.m3u8",
    },
    {
      name: "East Boundary",
      url: "https://dgi6n13b4acc9.cloudfront.net/bdd7b694-3265-43ad-8536-9cdbcea862b3/AppleHLS1/boundary_east_processed.m3u8",
    },
    {
      name: "South Boundary",
      url: "https://dgi6n13b4acc9.cloudfront.net/262c99a4-8936-4781-8321-0d46061293fe/AppleHLS1/boundary_south_processed.m3u8",
    },
    {
      name: "West Boundary",
      url: "https://dgi6n13b4acc9.cloudfront.net/86324a91-1607-4cf0-acfc-5647c68579ed/AppleHLS1/boundary_west_processed.m3u8",
    },
    {
      name: "Main Road",
      url: "https://dgi6n13b4acc9.cloudfront.net/b6f6a26c-8828-4d0e-a40e-7766fb81e712/AppleHLS1/main_road_processed.m3u8",
    },
    {
      name: "Campus",
      url: "https://dgi6n13b4acc9.cloudfront.net/fe895861-aef7-4ee8-b2da-27e09262f048/AppleHLS1/campus_processed.m3u8",
    },
  ];

  if (
    props.selectedMarker?.name === "Boundary" ||
    props.selectedMarker?.name === "Main Road"
  ) {
    return (
      <>
        <>
          <Modal open={props.vodOpen} onClose={props.handleVodClose}>
            <div
              style={style}
              className="flex flex-col items-center justify-between bg-white w-full !h-auto rounded-[12px]"
            >
              <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px]  w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
                <h3 className="!text-[1.20rem]  text-[#211f1c] grow flex items-center justify-center">
                  {getData?.name}
                </h3>
                <div className="mr-[10px]">
                  <IconButton
                    onClick={props.handleVodClose}
                    aria-label="Close"
                    size="small"
                    className="text-[#211f1c]"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              <div className="grow h-full w-full p-3 custom-scrollbar">
                <div className="w-full">
                  {props.selectedMarker?.name === "Boundary"
                    ? AgpVodData?.filter((vdo) => vdo.name !== "Main Road").map(
                        (description, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="flex w-[100%] items-center justify-between my-[10px] p-2 bg-white mx-auto border rounded-lg"
                              >
                                <div className="text-black">
                                  {description?.name}
                                </div>
                                <div
                                  onClick={() =>
                                    props.handleHlsOpen(description?.url)
                                  }
                                  className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer"
                                >
                                  Go
                                </div>
                              </div>
                            </>
                          );
                        }
                      )
                    : AgpVodData?.filter(
                        (vdo) => vdo?.name === "Main Road"
                      ).map((description, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className="flex w-[100%] items-center justify-between my-[10px] p-2 bg-white mx-auto border rounded-lg"
                            >
                              <div className="text-black">
                                {description?.name}
                              </div>
                              <div
                                onClick={() =>
                                  props.handleHlsOpen(description?.url)
                                }
                                className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer"
                              >
                                Go
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>
              </div>
            </div>
          </Modal>
        </>
      </>
    );
  }

  return (
    <>
      <Modal open={props.vodOpen} onClose={props.handleVodClose}>
        <div
          style={style}
          className="flex flex-col items-center justify-between bg-white w-full !h-auto rounded-[12px]"
        >
          <div className="flex items-center justify-between rounded-tl-[12px] rounded-tr-[12px]  w-full p-5 border-b bg-[#ffca0094] pt-[10px] pb-[10px] font-[500]">
            <h3 className="!text-[1.20rem]  text-[#211f1c] grow flex items-center justify-center">
              {getData?.name}
            </h3>
            <div className="mr-[10px]">
              <IconButton
                onClick={props.handleVodClose}
                aria-label="Close"
                size="small"
                className="text-[#211f1c]"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div className="grow h-full w-full p-3 custom-scrollbar ">
            <Tabs value={value} onChange={handleChange} className="w-full">
              {userPermissions.includes("generate_live_stream") && (
                <Tab
                  label="Live Stream"
                  {...a11yProps(0)}
                  className={`w-[50%] ${
                    userPermissions.includes("generate_live_stream")
                      ? ""
                      : "hidden"
                  }`}
                />
              )}

              <Tab label="Vods" {...a11yProps(1)} className="w-[50%]" />
            </Tabs>
            {userPermissions.includes("generate_live_stream") && (
              <CustomTabPanel value={value} index={0} className="h-full">
                <div className="w-full h-full">
                  {!rtmpLink ? (
                    <form
                      className="flex items-center justify-center w-full gap-2 py-5"
                      onSubmit={handleCreateStream}
                    >
                      <label className="w-[20%]">Stream Name:</label>
                      <TextField
                        name="streamName"
                        size="small"
                        value={streamName}
                        onChange={(e) => setStreamName(e.target.value)}
                        className="grow"
                        required
                      />

                      <div className="relative">
                        <button
                          type="submit"
                          disabled={loading}
                          className="mx-auto w-max text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer"
                        >
                          Create Stream
                        </button>
                        {loading && (
                          <CircularProgress
                            size={30}
                            sx={{
                              color: "red",
                              position: "absolute",
                              top: "40%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </div>
                    </form>
                  ) : (
                    <>
                      <div className="flex items-center justify-center w-full gap-2 py-5">
                        <label className="w-[20%]">RTMP Link:</label>
                        <TextField
                          id="standard-read-only-input"
                          value={rtmpLink}
                          InputProps={{
                            readOnly: true,
                          }}
                          variant="standard"
                          className="w-[90%]"
                        />
                        <div
                          onClick={() => {
                            navigator.clipboard.writeText(rtmpLink);
                          }}
                          className="ml-2  h-full grow text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer"
                        >
                          Copy
                        </div>
                      </div>
                      <div className="flex w-full gap-2 items-center justify-center">
                        <div
                          onClick={() => props.handleGoLive(streamUrl)}
                          className="w-max text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer"
                        >
                          Go Live
                        </div>
                        <div
                          onClick={() => deleteStream(channelArn)}
                          className="w-max text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer"
                        >
                          Delete Channel
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </CustomTabPanel>
            )}
            <CustomTabPanel
              value={value}
              index={userPermissions.includes("generate_live_stream") ? 1 : 0}
            >
              <div className="w-full">
                {typeof getData?.description === "object" &&
                  getData?.description.map((description, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="flex w-[100%] items-center justify-between my-[10px] p-2 bg-white mx-auto border rounded-lg"
                        >
                          <div className="text-black">{description?.date}</div>
                          <div
                            onClick={() =>
                              props.handleHlsOpen(description?.link)
                            }
                            className="text-[#8E887A] bg-[#F8F6F2] py-[5px] px-[18px] rounded hover:bg-[#F6F1E9] cursor-pointer"
                          >
                            Go
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            </CustomTabPanel>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VodBox;
